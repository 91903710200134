<template>
  <div>
    <v-container>
      <v-card class="text-center pa-1">
        <v-card-title class="justify-center title mb-2">Welcome</v-card-title>
        <v-card-subtitle>{{ welcomeText }}</v-card-subtitle>
        <v-tabs grow v-model="tab">
          <v-tab href="#sign">Sign In</v-tab>
          <v-tab href="#register">Register</v-tab>
        </v-tabs>
        <v-card-text>
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-btn
              @click="button.fn({ email, password })"
              :loading="loginLoading || registerLoading"
              block
              x-large
              color="primary"
              class="my-2 white--text"
              disabled
              >{{ button.text }}</v-btn
            >
            <div class="font-weight-bold text-uppercase my-3">
              Or sign in with
            </div>
            <v-btn
              href="http://localhost:4000/auth/discord/login/"
              dark
              block
              x-large
              color="#7289da"
              ><v-icon color="white" left>mdi-discord</v-icon>Discord</v-btn
            >
            <!-- <div class="mt-5">
              <router-link to="/auth/forgot-password"> forgot </router-link>
            </div> -->
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import { defineComponent, ref } from "@vue/composition-api";
import { useRegisterMutation, useLoginMutation } from "../graphql";
export default defineComponent({
  setup(props, context) {
    const store = context.root.$store;
    const router = context.root._router;
    const token = context.root._route.params.token;

    if (token) {
      store.commit("session/setToken", token);
      router.push({ name: "Home" });
    }

    const tab = ref("sign");
    const email = ref("");
    const password = ref("");

    const {
      mutate: register,
      loading: registerLoading,
      onError: onRegisterError,
      onDone: onRegister
    } = useRegisterMutation();
    const {
      mutate: login,
      loading: loginLoading,
      onError: onLoginError,
      onDone: onLogin
    } = useLoginMutation();

    onRegister(() => {
      login({ email: email.value, password: password.value });
    });
    onLogin(success => {
      store.commit("session/setToken", success.data.login.accessToken);
      router.push({ name: "Home" });
    });

    return {
      tab,
      email,
      password,
      register,
      registerLoading,
      onRegister,
      onRegisterError,
      login,
      loginLoading,
      onLogin,
      onLoginError
    };
  },
  created() {},
  data: () => ({
    isFormValid: true,
    rules: {
      required: value => (value && Boolean(value)) || "Required"
    },
    error: false,
    errorMessages: "",
    showPassword: false
  }),
  computed: {
    welcomeText() {
      return this.tab === "register"
        ? "Register a new account"
        : "Sign in to your account";
    },
    button() {
      const registering = this.tab === "register";
      return {
        text: registering ? "Register" : "Sign In",
        fn: registering ? this.register : this.login
      };
    }
  },
  methods: {
    resetErrors() {
      this.error = false;
      this.errorMessages = "";
    }
  }
});
</script>
