<template>
  <div>
    <v-data-table
      dense
      single-expand
      show-expand
      :headers="headers"
      :items="snapshot.tickers"
      :items-per-page="15"
      sort-by="todaysChangePerc"
      sort-desc
    ></v-data-table>
  </div>
</template>
<script>
import { useResult } from "@vue/apollo-composable";
import { useForexSnapshotQuery } from "@/graphql";
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  setup() {
    const headers = [
      { text: "Ticker", value: "ticker" },
      { text: "Today's Change", value: "todaysChange" },
      { text: "Today's Change %", value: "todaysChangePerc" },
      { text: "Minute Close", value: "min.c" },
      { text: "Minute High", value: "min.h" },
      { text: "Minute Low", value: "min.l" },
      { text: "Minute Open", value: "min.o" },
      { text: "Minute Volume", value: "min.v" }
    ];

    const { result } = useForexSnapshotQuery({ pollInterval: 10000 });
    const snapshot = useResult(result, {}, data => data.ForexSnapshot);
    return { snapshot, headers };
  }
});
</script>
