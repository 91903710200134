<template>
  <div>
    <t-card title="Search">
      <v-card-title>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          dense
          flat
          hide-details
          solo-inverted
        ></v-text-field>

        <v-select
          v-model="market"
          :items="markets"
          class="mx-1"
          label="Market"
          solo-inverted
          flat
          dense
          hide-details
        ></v-select>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :items="tickers"
        :headers="headers"
        :items-per-page="15"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn @click="openDetail(item)" color="secondary" icon text
            ><v-icon>mdi-view-list</v-icon></v-btn
          >
          <v-btn
            @click="openSubscribe(item.ticker)"
            disabled
            color="secondary"
            icon
            text
            ><v-icon>mdi-pulse</v-icon></v-btn
          >

          <v-icon class="ml-1" v-if="item.active" small color="success">
            mdi-check-circle
          </v-icon>
          <v-icon class="ml-1" v-else small>
            mdi-circle-outline
          </v-icon>
        </template>

        <template v-slot:item.type="{ item }">
          <v-chip label color="accent" v-if="item.type">{{ item.type }}</v-chip>
        </template>
      </v-data-table>
    </t-card>

    <v-dialog v-model="subscribe.open" width="85%">
      <add-subscription :symbol="subscribe.symbol" />
    </v-dialog>

    <v-dialog v-model="detail.open" width="85%">
      <symbol-detail :symbol="detail.symbol" />
    </v-dialog>
  </div>
</template>
<script>
import { defineComponent, ref } from "@vue/composition-api";
import { useTickerQuery } from "@/graphql";
import { useResult } from "@vue/apollo-composable";
import AddSubscription from "./add-subscription.vue";
import SymbolDetail from "@/components/symbol-detail";

export default defineComponent({
  components: { AddSubscription, SymbolDetail },
  setup() {
    const detail = ref({
      open: false,
      symbol: {}
    });

    const subscribe = ref({
      open: false,
      symbol: ""
    });

    function openSubscribe(symbol) {
      subscribe.value.open = true;
      subscribe.value.symbol = symbol;
    }

    function openDetail(symbol) {
      detail.value.open = true;
      detail.value.symbol = symbol;
    }

    const market = ref("");
    const markets = ref(["STOCKS", "CRYPTO", "FX"]);
    const search = ref("");
    const { loading, result, refetch } = useTickerQuery({ search, market }, {});
    const tickers = useResult(result, [], data => data.Ticker);
    return {
      detail,
      subscribe,
      openDetail,
      openSubscribe,
      market,
      markets,
      search,
      loading,
      tickers,
      refetch
    };
  },
  created() {
    this.headers = [
      { text: "", value: "actions", align: "start" },
      { text: "Symbol", value: "ticker", align: "start" },
      { text: "Name", value: "name" }
      // { text: "Type", value: "type" }
    ];
  }
});
</script>
