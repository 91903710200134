import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};










export type TickerDetail = {
  __typename?: 'TickerDetail';
  logo: Scalars['String'];
  listdate: Scalars['String'];
  cik: Scalars['Float'];
  bloomberg: Scalars['String'];
  lei: Scalars['String'];
  sic: Scalars['Float'];
  country: Scalars['String'];
  industry: Scalars['String'];
  sector: Scalars['String'];
  marketcap: Scalars['Float'];
  employees: Scalars['Float'];
  phone: Scalars['String'];
  ceo: Scalars['String'];
  url: Scalars['String'];
  description: Scalars['String'];
  exchange: Scalars['String'];
  name: Scalars['String'];
  symbol: Scalars['String'];
  exchangeSymbol: Scalars['String'];
  hq_address: Scalars['String'];
  hq_state: Scalars['String'];
  hq_country: Scalars['String'];
  type: Scalars['String'];
  updated: Scalars['String'];
  tags: Array<Scalars['String']>;
  similar: Array<Scalars['String']>;
  active: Scalars['Boolean'];
};

export type _UserInput = {
  email: Scalars['String'];
};

export enum _UserOrdering {
  email_asc = 'email_asc',
  email_desc = 'email_desc',
  _id_asc = '_id_asc',
  _id_desc = '_id_desc'
}

export type _UserFilter = {
  AND?: Maybe<Array<_UserFilter>>;
  OR?: Maybe<Array<_UserFilter>>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_regexp?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  /** Generated field for querying the Neo4j [system id](https://neo4j.com/docs/cypher-manual/current/functions/scalar/#functions-id) of this node. */
  _id?: Maybe<Scalars['String']>;
};

export enum StrategyAction {
  BUY = 'BUY',
  SELL = 'SELL'
}

export enum PolygonMarket {
  stocks = 'stocks',
  crypto = 'crypto',
  forex = 'forex'
}

export enum PolygonEventChannel {
  /** STOCK_TRADE */
  T = 'T',
  /** STOCK_QUOTE */
  Q = 'Q',
  /** STOCK_SECOND_AGGREGATION */
  A = 'A',
  /** STOCK_MINUTE_AGGREGATION */
  AM = 'AM',
  /** STOCK_LIMIT_UP_LIMIT_DOWN */
  LULD = 'LULD',
  /** STOCK_IMBALANCES */
  NOI = 'NOI',
  /** FOREX_CURRENCY */
  C = 'C',
  /** FOREX_AGGREGATION */
  CA = 'CA',
  /** FOREX_BONDS */
  BONDS = 'BONDS',
  /** FOREX_COMMODITIES */
  COMMODITIES = 'COMMODITIES',
  /** FOREX_METALS */
  METALS = 'METALS',
  /** CRYPTO_QUOTE */
  XQ = 'XQ',
  /** CRYPTO_TRADE */
  XT = 'XT',
  /** CRYPTO_AGGREGATION */
  XA = 'XA',
  /** CRYPTO_SIP */
  XS = 'XS',
  /** CRYPTO_LEVEL2 */
  XL2 = 'XL2'
}

export enum MATypes {
  SMA = 'SMA',
  EMA = 'EMA',
  WMA = 'WMA',
  DEMA = 'DEMA',
  TEMA = 'TEMA',
  TRIMA = 'TRIMA',
  KAMA = 'KAMA',
  MAMA = 'MAMA',
  T3 = 'T3'
}

export enum IndicatorType {
  ACCBANDS = 'ACCBANDS',
  ACOS = 'ACOS',
  AD = 'AD',
  ADD = 'ADD',
  ADOSC = 'ADOSC',
  ADX = 'ADX',
  ADXR = 'ADXR',
  APO = 'APO',
  AROON = 'AROON',
  AROONOSC = 'AROONOSC',
  ASIN = 'ASIN',
  ATAN = 'ATAN',
  ATR = 'ATR',
  AVGPRICE = 'AVGPRICE',
  AVGDEV = 'AVGDEV',
  BBANDS = 'BBANDS',
  BETA = 'BETA',
  BOP = 'BOP',
  CCI = 'CCI',
  CDL2CROWS = 'CDL2CROWS',
  CDL3BLACKCROWS = 'CDL3BLACKCROWS',
  CDL3INSIDE = 'CDL3INSIDE',
  CDL3LINESTRIKE = 'CDL3LINESTRIKE',
  CDL3OUTSIDE = 'CDL3OUTSIDE',
  CDL3STARSINSOUTH = 'CDL3STARSINSOUTH',
  CDL3WHITESOLDIERS = 'CDL3WHITESOLDIERS',
  CDLABANDONEDBABY = 'CDLABANDONEDBABY',
  CDLADVANCEBLOCK = 'CDLADVANCEBLOCK',
  CDLBELTHOLD = 'CDLBELTHOLD',
  CDLBREAKAWAY = 'CDLBREAKAWAY',
  CDLCLOSINGMARUBOZU = 'CDLCLOSINGMARUBOZU',
  CDLCONCEALBABYSWALL = 'CDLCONCEALBABYSWALL',
  CDLCOUNTERATTACK = 'CDLCOUNTERATTACK',
  CDLDARKCLOUDCOVER = 'CDLDARKCLOUDCOVER',
  CDLDOJI = 'CDLDOJI',
  CDLDOJISTAR = 'CDLDOJISTAR',
  CDLDRAGONFLYDOJI = 'CDLDRAGONFLYDOJI',
  CDLENGULFING = 'CDLENGULFING',
  CDLEVENINGDOJISTAR = 'CDLEVENINGDOJISTAR',
  CDLEVENINGSTAR = 'CDLEVENINGSTAR',
  CDLGAPSIDESIDEWHITE = 'CDLGAPSIDESIDEWHITE',
  CDLGRAVESTONEDOJI = 'CDLGRAVESTONEDOJI',
  CDLHAMMER = 'CDLHAMMER',
  CDLHANGINGMAN = 'CDLHANGINGMAN',
  CDLHARAMI = 'CDLHARAMI',
  CDLHARAMICROSS = 'CDLHARAMICROSS',
  CDLHIGHWAVE = 'CDLHIGHWAVE',
  CDLHIKKAKE = 'CDLHIKKAKE',
  CDLHIKKAKEMOD = 'CDLHIKKAKEMOD',
  CDLHOMINGPIGEON = 'CDLHOMINGPIGEON',
  CDLIDENTICAL3CROWS = 'CDLIDENTICAL3CROWS',
  CDLINNECK = 'CDLINNECK',
  CDLINVERTEDHAMMER = 'CDLINVERTEDHAMMER',
  CDLKICKING = 'CDLKICKING',
  CDLKICKINGBYLENGTH = 'CDLKICKINGBYLENGTH',
  CDLLADDERBOTTOM = 'CDLLADDERBOTTOM',
  CDLLONGLEGGEDDOJI = 'CDLLONGLEGGEDDOJI',
  CDLLONGLINE = 'CDLLONGLINE',
  CDLMARUBOZU = 'CDLMARUBOZU',
  CDLMATCHINGLOW = 'CDLMATCHINGLOW',
  CDLMATHOLD = 'CDLMATHOLD',
  CDLMORNINGDOJISTAR = 'CDLMORNINGDOJISTAR',
  CDLMORNINGSTAR = 'CDLMORNINGSTAR',
  CDLONNECK = 'CDLONNECK',
  CDLPIERCING = 'CDLPIERCING',
  CDLRICKSHAWMAN = 'CDLRICKSHAWMAN',
  CDLRISEFALL3METHODS = 'CDLRISEFALL3METHODS',
  CDLSEPARATINGLINES = 'CDLSEPARATINGLINES',
  CDLSHOOTINGSTAR = 'CDLSHOOTINGSTAR',
  CDLSHORTLINE = 'CDLSHORTLINE',
  CDLSPINNINGTOP = 'CDLSPINNINGTOP',
  CDLSTALLEDPATTERN = 'CDLSTALLEDPATTERN',
  CDLSTICKSANDWICH = 'CDLSTICKSANDWICH',
  CDLTAKURI = 'CDLTAKURI',
  CDLTASUKIGAP = 'CDLTASUKIGAP',
  CDLTHRUSTING = 'CDLTHRUSTING',
  CDLTRISTAR = 'CDLTRISTAR',
  CDLUNIQUE3RIVER = 'CDLUNIQUE3RIVER',
  CDLUPSIDEGAP2CROWS = 'CDLUPSIDEGAP2CROWS',
  CDLXSIDEGAP3METHODS = 'CDLXSIDEGAP3METHODS',
  CEIL = 'CEIL',
  CMO = 'CMO',
  CORREL = 'CORREL',
  COS = 'COS',
  COSH = 'COSH',
  DEMA = 'DEMA',
  DIV = 'DIV',
  DX = 'DX',
  EMA = 'EMA',
  EXP = 'EXP',
  FLOOR = 'FLOOR',
  HT_DCPERIOD = 'HT_DCPERIOD',
  HT_DCPHASE = 'HT_DCPHASE',
  HT_PHASOR = 'HT_PHASOR',
  HT_SINE = 'HT_SINE',
  HT_TRENDLINE = 'HT_TRENDLINE',
  HT_TRENDMODE = 'HT_TRENDMODE',
  IMI = 'IMI',
  KAMA = 'KAMA',
  LINEARREG = 'LINEARREG',
  LINEARREG_ANGLE = 'LINEARREG_ANGLE',
  LINEARREG_INTERCEPT = 'LINEARREG_INTERCEPT',
  LINEARREG_SLOPE = 'LINEARREG_SLOPE',
  LN = 'LN',
  LOG10 = 'LOG10',
  MA = 'MA',
  MACD = 'MACD',
  MACDEXT = 'MACDEXT',
  MACDFIX = 'MACDFIX',
  MAMA = 'MAMA',
  MAVP = 'MAVP',
  MAX = 'MAX',
  MAXINDEX = 'MAXINDEX',
  MEDPRICE = 'MEDPRICE',
  MFI = 'MFI',
  MIDPOINT = 'MIDPOINT',
  MIDPRICE = 'MIDPRICE',
  MIN = 'MIN',
  MININDEX = 'MININDEX',
  MINMAX = 'MINMAX',
  MINMAXINDEX = 'MINMAXINDEX',
  MINUS_DI = 'MINUS_DI',
  MINUS_DM = 'MINUS_DM',
  MOM = 'MOM',
  MULT = 'MULT',
  NATR = 'NATR',
  OBV = 'OBV',
  PLUS_DI = 'PLUS_DI',
  PLUS_DM = 'PLUS_DM',
  PPO = 'PPO',
  ROC = 'ROC',
  ROCP = 'ROCP',
  ROCR = 'ROCR',
  ROCR100 = 'ROCR100',
  RSI = 'RSI',
  SAR = 'SAR',
  SAREXT = 'SAREXT',
  SIN = 'SIN',
  SINH = 'SINH',
  SMA = 'SMA',
  SQRT = 'SQRT',
  STDDEV = 'STDDEV',
  STOCH = 'STOCH',
  STOCHF = 'STOCHF',
  STOCHRSI = 'STOCHRSI',
  SUB = 'SUB',
  SUM = 'SUM',
  T3 = 'T3',
  TAN = 'TAN',
  TANH = 'TANH',
  TEMA = 'TEMA',
  TRANGE = 'TRANGE',
  TRIMA = 'TRIMA',
  TRIX = 'TRIX',
  TSF = 'TSF',
  TYPPRICE = 'TYPPRICE',
  ULTOSC = 'ULTOSC',
  VAR = 'VAR',
  WCLPRICE = 'WCLPRICE',
  WILLR = 'WILLR',
  WMA = 'WMA'
}

export enum AggregateTimeSpan {
  minute = 'minute',
  hour = 'hour',
  day = 'day',
  week = 'week',
  month = 'month',
  quarter = 'quarter',
  year = 'year'
}

export enum CrossOverDirection {
  UP = 'UP',
  DOWN = 'DOWN',
  NONE = 'NONE'
}

export type _CurrencyPairInput = {
  from: Scalars['String'];
  to: Scalars['String'];
};

export type StockMarkets = {
  __typename?: 'StockMarkets';
  nyse?: Maybe<Scalars['String']>;
  nasdaq?: Maybe<Scalars['String']>;
  otc?: Maybe<Scalars['String']>;
};

export type CurrencyMarkets = {
  __typename?: 'CurrencyMarkets';
  fx?: Maybe<Scalars['String']>;
  crypto?: Maybe<Scalars['String']>;
};

export type MarketStatus = {
  __typename?: 'MarketStatus';
  market?: Maybe<Scalars['String']>;
  serverTime?: Maybe<Scalars['String']>;
  exchanges?: Maybe<StockMarkets>;
  currencies?: Maybe<CurrencyMarkets>;
};

export type ForexSnapshotDay = {
  __typename?: 'ForexSnapshotDay';
  c?: Maybe<Scalars['Float']>;
  h?: Maybe<Scalars['Float']>;
  l?: Maybe<Scalars['Float']>;
  o?: Maybe<Scalars['Float']>;
  v?: Maybe<Scalars['Float']>;
};

export type ForexSnapshotLastQuote = {
  __typename?: 'ForexSnapshotLastQuote';
  a?: Maybe<Scalars['Float']>;
  b?: Maybe<Scalars['Float']>;
  t?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Int']>;
};

export type ForexSnapshotMin = {
  __typename?: 'ForexSnapshotMin';
  c?: Maybe<Scalars['Float']>;
  h?: Maybe<Scalars['Float']>;
  l?: Maybe<Scalars['Float']>;
  o?: Maybe<Scalars['Float']>;
  v?: Maybe<Scalars['Float']>;
};

export type ForexSnapshot = {
  __typename?: 'ForexSnapshot';
  day?: Maybe<ForexSnapshotDay>;
  lastQuote?: Maybe<ForexSnapshotLastQuote>;
  min?: Maybe<ForexSnapshotMin>;
  prev?: Maybe<SnapshotPrevDay>;
  ticker?: Maybe<Scalars['String']>;
  todaysChange?: Maybe<Scalars['Float']>;
  todaysChangePerc?: Maybe<Scalars['Float']>;
  updated?: Maybe<Scalars['Float']>;
};

export type ForexSnapshotResponse = {
  __typename?: 'ForexSnapshotResponse';
  status?: Maybe<Scalars['String']>;
  tickers?: Maybe<Array<Maybe<ForexSnapshot>>>;
};

export type SnapshotDay = {
  __typename?: 'SnapshotDay';
  c?: Maybe<Scalars['Float']>;
  h?: Maybe<Scalars['Float']>;
  l?: Maybe<Scalars['Float']>;
  o?: Maybe<Scalars['Float']>;
  v?: Maybe<Scalars['Float']>;
  vw?: Maybe<Scalars['Float']>;
};

export type StockSnapshotLastQuote = {
  __typename?: 'StockSnapshotLastQuote';
  P?: Maybe<Scalars['Float']>;
  S?: Maybe<Scalars['Int']>;
  p?: Maybe<Scalars['Float']>;
  s?: Maybe<Scalars['Int']>;
  t?: Maybe<Scalars['Float']>;
};

export type SnapshotLastTrade = {
  __typename?: 'SnapshotLastTrade';
  c?: Maybe<Array<Maybe<Scalars['Int']>>>;
  i?: Maybe<Scalars['String']>;
  p?: Maybe<Scalars['Float']>;
  s?: Maybe<Scalars['Float']>;
  t?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Int']>;
};

export type StockSnapshotMin = {
  __typename?: 'StockSnapshotMin';
  av?: Maybe<Scalars['Int']>;
  c?: Maybe<Scalars['Float']>;
  h?: Maybe<Scalars['Float']>;
  l?: Maybe<Scalars['Float']>;
  o?: Maybe<Scalars['Float']>;
  v?: Maybe<Scalars['Float']>;
  vw?: Maybe<Scalars['Float']>;
};

export type SnapshotPrevDay = {
  __typename?: 'SnapshotPrevDay';
  c?: Maybe<Scalars['Float']>;
  h?: Maybe<Scalars['Float']>;
  l?: Maybe<Scalars['Float']>;
  o?: Maybe<Scalars['Float']>;
  v?: Maybe<Scalars['Float']>;
  vw?: Maybe<Scalars['Float']>;
};

export type StockSnapshot = {
  __typename?: 'StockSnapshot';
  day?: Maybe<SnapshotDay>;
  lastQuote?: Maybe<StockSnapshotLastQuote>;
  lastTrade?: Maybe<SnapshotLastTrade>;
  min?: Maybe<StockSnapshotMin>;
  prev?: Maybe<SnapshotPrevDay>;
  ticker?: Maybe<Scalars['String']>;
  todaysChange?: Maybe<Scalars['Float']>;
  todaysChangePerc?: Maybe<Scalars['Float']>;
  updated?: Maybe<Scalars['Float']>;
};

export type StockSnapshotResponse = {
  __typename?: 'StockSnapshotResponse';
  status?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  tickers?: Maybe<Array<Maybe<StockSnapshot>>>;
};

export type CryptoSnapshotMin = {
  __typename?: 'CryptoSnapshotMin';
  c?: Maybe<Scalars['Float']>;
  h?: Maybe<Scalars['Float']>;
  l?: Maybe<Scalars['Float']>;
  o?: Maybe<Scalars['Float']>;
  v?: Maybe<Scalars['Float']>;
  vw?: Maybe<Scalars['Float']>;
};

export type CryptoSnapshot = {
  __typename?: 'CryptoSnapshot';
  day?: Maybe<SnapshotDay>;
  lastTrade?: Maybe<SnapshotLastTrade>;
  min?: Maybe<CryptoSnapshotMin>;
  prevDay?: Maybe<SnapshotPrevDay>;
  ticker?: Maybe<Scalars['String']>;
  todaysChange?: Maybe<Scalars['Float']>;
  todaysChangePerc?: Maybe<Scalars['Float']>;
  updated?: Maybe<Scalars['Float']>;
};

export type CryptoSnapshotResponse = {
  __typename?: 'CryptoSnapshotResponse';
  status?: Maybe<Scalars['String']>;
  tickers?: Maybe<Array<Maybe<CryptoSnapshot>>>;
};

export type Ticker = {
  __typename?: 'Ticker';
  ticker?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  primaryExch?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TickerNews = {
  __typename?: 'TickerNews';
  symbols?: Maybe<Array<Maybe<Scalars['String']>>>;
  timestamp?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ITradeStockHistorical = {
  __typename?: 'ITradeStockHistorical';
  /**
   * The nanosecond accuracy SIP Unix Timestamp.
   * This is the timestamp of when the SIP received this message from the exchange which produced it.
   */
  t: Scalars['Float'];
  /**
   * The nanosecond accuracy Participant/Exchange Unix Timestamp.
   * This is the timestamp of when the quote was actually generated at the exchange.
   */
  y: Scalars['Float'];
  /**
   * The sequence number representing the sequence in which trade events happened.
   * These are increasing and unique per ticker symbol, but will not always be sequential (e.g., 1, 2, 6, 9, 10, 11).
   */
  q: Scalars['Int'];
  /**
   * The Trade ID which uniquely identifies a trade. These are unique per combination of ticker, exchange, and TRF. For example:
   * A trade for AAPL executed on NYSE and a trade for AAPL executed on NASDAQ could potentially have the same Trade ID.
   */
  i: Scalars['String'];
  /** The exchange ID. */
  x: Scalars['Int'];
  /** The size of a trade (also known as volume) as a number of whole shares traded. */
  s: Scalars['Int'];
  /**
   * The price of the trade. This is the actual dollar value per whole share of this trade.
   * A trade of 100 shares with a price of $2.00 would be worth a total dollar value of $200.00.
   */
  p: Scalars['Float'];
  /** The trade conditions. */
  c: Array<Maybe<Scalars['Int']>>;
  /**
   * There are 3 tapes which define which exchange the ticker is listed on.
   * These are integers in our objects which represent the letter of the alphabet.
   * Eg: 1 = A, 2 = B, 3 = C.
   */
  z: Scalars['Int'];
  /** The trade correction indicator. */
  e: Scalars['Int'];
};

export type IQuoteStockHistorical = {
  __typename?: 'IQuoteStockHistorical';
  /** The nanosecond accuracy SIP Unix Timestamp. This is the timestamp of when the SIP received this message from the exchange which produced it. */
  t: Scalars['Float'];
  /** The nanosecond accuracy Participant/Exchange Unix Timestamp. This is the timestamp of when the quote was actually generated at the exchange. */
  y: Scalars['Float'];
  /** The sequence number represents the sequence in which message events happened. These are increasing and unique per ticker symbol, but will not always be sequential (e.g., 1, 2, 6, 9, 10, 11). */
  q: Scalars['Int'];
  /** The bid Exchange ID. */
  x: Scalars['Int'];
  /** The ask Exchange ID. */
  X: Scalars['Int'];
  /** The bid size. This represents the number of round lot orders at the given bid price. The normal round lot size is 100 shares. A bid size of 2 means there are 200 shares for purchase at the given bid price. */
  s: Scalars['Int'];
  /** The ask size. This represents the number of round lot orders at the given ask price. The normal round lot size is 100 shares. An ask size of 2 means there are 200 shares available to purchase at the given ask price. */
  S: Scalars['Int'];
  /** The bid price. */
  p: Scalars['Float'];
  /** The ask price. */
  P: Scalars['Float'];
  /**
   * There are 3 tapes which define which exchange the ticker is listed on.
   * These are integers in our objects which represent the letter of the alphabet.
   * Eg: 1 = A, 2 = B, 3 = C.
   */
  z: Scalars['Int'];
  /** A list of condition codes. */
  c: Array<Maybe<Scalars['Int']>>;
};

export type IQuoteCryptoEvent = {
  __typename?: 'IQuoteCryptoEvent';
  /** Event Type */
  ev: Scalars['String'];
  /** Crypto Pair */
  pair: Scalars['String'];
  /** Last Trade Price */
  lp: Scalars['Float'];
  /** Last Trade Size */
  ls: Scalars['Float'];
  /** Bid Price */
  bp: Scalars['Float'];
  /** Bid Size */
  bs: Scalars['Float'];
  /** Ask Price */
  ap: Scalars['Float'];
  /** Ask Size */
  as: Scalars['Float'];
  /** Exchange Timestamp Unix (ms) */
  t: Scalars['Float'];
  /** Exchange ID */
  x: Scalars['Float'];
  /** Received @ Polygon Timestamp */
  r: Scalars['Float'];
};

export type ITradeCryptoEvent = {
  __typename?: 'ITradeCryptoEvent';
  /** Event Type */
  ev: Scalars['String'];
  /** Crypto Pair */
  pair: Scalars['String'];
  /** Price */
  p: Scalars['Float'];
  /** Timestamp Unix (ms) */
  t: Scalars['Float'];
  /** Size */
  s: Scalars['Float'];
  /** Conditions */
  c: Array<Maybe<Scalars['Int']>>;
  /** Trade ID */
  i?: Maybe<Scalars['String']>;
  /** Exchange ID */
  x: Scalars['Float'];
  /** Received @ Polygon Timestamp */
  r: Scalars['Float'];
};

export type IAggregateCryptoEvent = {
  __typename?: 'IAggregateCryptoEvent';
  /** Event Type */
  ev: Scalars['String'];
  /** Crypto Pair */
  pair: Scalars['String'];
  /** Open Price */
  o: Scalars['Float'];
  /** Open Exchange */
  ox: Scalars['Float'];
  /** High Price */
  h: Scalars['Float'];
  /** High Exchange */
  hx: Scalars['Float'];
  /** Low Price */
  l: Scalars['Float'];
  /** Low Exchange */
  lx: Scalars['Float'];
  /** Close Price */
  c: Scalars['Float'];
  /** Close Exchange */
  cx: Scalars['Float'];
  /** Volume of Trade in Tick */
  v: Scalars['Float'];
  /** Tick Start Timestamp */
  s: Scalars['Float'];
  /** Tick End Timestamp */
  e: Scalars['Float'];
};

export type IPCryptoEvent = {
  __typename?: 'IPCryptoEvent';
  /** Event Type */
  ev: Scalars['String'];
  /** Event Pair */
  pair: Scalars['String'];
  /** Ask Size */
  as: Scalars['Float'];
  /** Ask Price */
  ap: Scalars['Float'];
  /** Ask Exchange */
  ax: Scalars['Float'];
  /** Bid Size */
  bs: Scalars['Float'];
  /** Bid Price */
  bp: Scalars['Float'];
  /** Bid Exchange */
  bx: Scalars['Float'];
  /** Tick Start Timestamp */
  t: Scalars['Float'];
};

export type ILevel2CryptoEvent = {
  __typename?: 'ILevel2CryptoEvent';
  /** Event Type */
  ev: Scalars['String'];
  /** Event Pair */
  pair: Scalars['String'];
  /** Bid Prices (100 depth cap) [price, size] */
  b: Array<Maybe<Scalars['Float']>>;
  /** Ask Prices (100 depth cap) [price, size] */
  a: Array<Maybe<Scalars['Float']>>;
  /** Timestamp Unix (ms) */
  t: Scalars['Float'];
  /** Exchange ID */
  x: Scalars['Float'];
  /** Tick Received @ Polygon Timestamp */
  r: Scalars['Float'];
};

export type ITradeStockEvent = {
  __typename?: 'ITradeStockEvent';
  /** Event Type */
  ev?: Maybe<Scalars['String']>;
  /** Symbol Ticker */
  sym?: Maybe<Scalars['String']>;
  /** Exchange ID */
  x?: Maybe<Scalars['String']>;
  /** Trade ID */
  i?: Maybe<Scalars['Float']>;
  /** Tape */
  z?: Maybe<Scalars['Float']>;
  /** Price */
  p?: Maybe<Scalars['Float']>;
  /** Trade Size */
  s?: Maybe<Scalars['Float']>;
  /** Trade Conditions */
  c?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Timestamp Unix (ms) */
  t?: Maybe<Scalars['Float']>;
};

export type IQuoteStockEvent = {
  __typename?: 'IQuoteStockEvent';
  /** Event Type */
  ev?: Maybe<Scalars['String']>;
  /** Symbol Ticker */
  sym?: Maybe<Scalars['String']>;
  /** Bid Exchange ID */
  bx?: Maybe<Scalars['String']>;
  /** Bid Price */
  bp?: Maybe<Scalars['Float']>;
  /** Bid Size */
  bs?: Maybe<Scalars['Float']>;
  /** Ask Exchange ID */
  ax?: Maybe<Scalars['String']>;
  /** Ask Price */
  ap?: Maybe<Scalars['Float']>;
  /** Ask Size */
  as?: Maybe<Scalars['Float']>;
  /** Quote Condition */
  c?: Maybe<Scalars['Float']>;
  /** Timestamp Unix (ms) */
  t?: Maybe<Scalars['Float']>;
};

export type IAggregateStockEvent = {
  __typename?: 'IAggregateStockEvent';
  /** Event Type */
  ev?: Maybe<Scalars['String']>;
  /** Symbol Ticker */
  sym?: Maybe<Scalars['String']>;
  /** Tick Volume */
  v?: Maybe<Scalars['Float']>;
  /** Accumulated Volume (Today) */
  av?: Maybe<Scalars['Float']>;
  /** Today's official opening price */
  op?: Maybe<Scalars['Float']>;
  /** VWAP (Volume Weighted Average Price) */
  vw?: Maybe<Scalars['Float']>;
  /** Tick Open Price */
  o?: Maybe<Scalars['Float']>;
  /** Tick Close Price */
  c?: Maybe<Scalars['Float']>;
  /** Tick High Price */
  h?: Maybe<Scalars['Float']>;
  /** Tick Low Price */
  l?: Maybe<Scalars['Float']>;
  /** Tick Average / VWAP Price */
  a?: Maybe<Scalars['Float']>;
  /** Tick Start Timestamp Unix (ms) */
  s?: Maybe<Scalars['Float']>;
  /** Tick End Timesetamp Unix (ms) */
  e?: Maybe<Scalars['Float']>;
};

export type IQuoteForexEvent = {
  __typename?: 'IQuoteForexEvent';
  /** Event Type */
  ev?: Maybe<Scalars['String']>;
  /** Currency Pair */
  p?: Maybe<Scalars['String']>;
  /** FX Exchange ID */
  x?: Maybe<Scalars['String']>;
  /** Ask Price */
  a?: Maybe<Scalars['Float']>;
  /** Bid Price */
  b?: Maybe<Scalars['Float']>;
  /** Quote Timestamp Unix (ms) */
  t?: Maybe<Scalars['Float']>;
};

export type IAggegateForexEvent = {
  __typename?: 'IAggegateForexEvent';
  /** Event Type */
  ev?: Maybe<Scalars['String']>;
  /** Currency Pair */
  pair?: Maybe<Scalars['String']>;
  /** Open Price */
  o?: Maybe<Scalars['Float']>;
  /** Close Price */
  c?: Maybe<Scalars['Float']>;
  /** High Price */
  h?: Maybe<Scalars['Float']>;
  /** Low Price */
  l?: Maybe<Scalars['Float']>;
  /** Volume (Quotes during this duration) */
  v?: Maybe<Scalars['Float']>;
  /** Tick Start Timestamp Unix (ms) */
  s?: Maybe<Scalars['Float']>;
};

export type ITradeCryptoHistorical = {
  __typename?: 'ITradeCryptoHistorical';
  /** The price of the trade. */
  p?: Maybe<Scalars['Float']>;
  /** The size of the trade. */
  s?: Maybe<Scalars['Float']>;
  /** The exchange that this trade happened on. */
  x?: Maybe<Scalars['Float']>;
  /** Conditions of this trade. */
  c?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** The Unix Msec timestamp for the start of the aggregate window. */
  t?: Maybe<Scalars['Float']>;
};

export type IAggregateStockHistorical = {
  __typename?: 'IAggregateStockHistorical';
  /** The open price for the symbol in the given time period. */
  o?: Maybe<Scalars['Float']>;
  /** The highest price for the symbol in the given time period. */
  h?: Maybe<Scalars['Float']>;
  /** The lowest price for the symbol in the given time period. */
  l?: Maybe<Scalars['Float']>;
  /** The close price for the symbol in the given time period. */
  c?: Maybe<Scalars['Float']>;
  /** The trading volume of the symbol in the given time period. */
  v?: Maybe<Scalars['Float']>;
  /** The volume weighted average price. */
  vw?: Maybe<Scalars['Float']>;
  /** The Unix Msec timestamp for the start of the aggregate window. */
  t: Scalars['Float'];
  /** Formatted Timestamp */
  tf?: Maybe<Scalars['String']>;
  /** The number of items in the aggregate window. */
  n?: Maybe<Scalars['Float']>;
};

export type IndicatorEvent = {
  __typename?: 'IndicatorEvent';
  /** Indicator Output */
  i?: Maybe<Scalars['String']>;
  /** Aggregate Window Start Time */
  s?: Maybe<Scalars['Float']>;
  /** Aggregate Window End Time */
  e?: Maybe<Scalars['Float']>;
  /** Aggregate Window Open Price */
  o?: Maybe<Scalars['Float']>;
  /** Aggregate Window Close Price */
  c?: Maybe<Scalars['Float']>;
  /** Aggregate Window High Price */
  h?: Maybe<Scalars['Float']>;
  /** Aggregate Window Low Price */
  l?: Maybe<Scalars['Float']>;
  /** Aggregate Window Volume */
  v?: Maybe<Scalars['Float']>;
};

export type Record = {
  __typename?: 'Record';
  Time?: Maybe<Scalars['Float']>;
  Open?: Maybe<Scalars['Float']>;
  High?: Maybe<Scalars['Float']>;
  Low?: Maybe<Scalars['Float']>;
  Close?: Maybe<Scalars['Float']>;
  Volume?: Maybe<Scalars['Float']>;
};

/** Generated Time input object for Neo4j [Temporal field arguments](https://grandstack.io/docs/graphql-temporal-types-datetime/#temporal-query-arguments). */
export type _Neo4jTimeInput = {
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  millisecond?: Maybe<Scalars['Int']>;
  microsecond?: Maybe<Scalars['Int']>;
  nanosecond?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  /** Creates a Neo4j [Temporal](https://grandstack.io/docs/graphql-temporal-types-datetime/#using-temporal-fields-in-mutations) Time value using a [String format](https://neo4j.com/docs/cypher-manual/current/functions/temporal/time/#functions-time-create-string). */
  formatted?: Maybe<Scalars['String']>;
};

/** Generated Time object type for Neo4j [Temporal fields](https://grandstack.io/docs/graphql-temporal-types-datetime#using-temporal-fields-in-queries). */
export type _Neo4jTime = {
  __typename?: '_Neo4jTime';
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  millisecond?: Maybe<Scalars['Int']>;
  microsecond?: Maybe<Scalars['Int']>;
  nanosecond?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  /** Outputs a Neo4j [Temporal](https://grandstack.io/docs/graphql-temporal-types-datetime#using-temporal-fields-in-queries) Time value as a String type by using the [toString](https://neo4j.com/docs/cypher-manual/current/functions/string/#functions-tostring) Cypher function. */
  formatted?: Maybe<Scalars['String']>;
};

/** Generated Date input object for Neo4j [Temporal field arguments](https://grandstack.io/docs/graphql-temporal-types-datetime/#temporal-query-arguments). */
export type _Neo4jDateInput = {
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  /** Creates a Neo4j [Temporal](https://grandstack.io/docs/graphql-temporal-types-datetime/#using-temporal-fields-in-mutations) Date value using a [String format](https://neo4j.com/docs/cypher-manual/current/functions/temporal/date/#functions-date-create-string). */
  formatted?: Maybe<Scalars['String']>;
};

/** Generated Date object type for Neo4j [Temporal fields](https://grandstack.io/docs/graphql-temporal-types-datetime#using-temporal-fields-in-queries). */
export type _Neo4jDate = {
  __typename?: '_Neo4jDate';
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  /** Outputs a Neo4j [Temporal](https://grandstack.io/docs/graphql-temporal-types-datetime#using-temporal-fields-in-queries) Date value as a String type by using the [toString](https://neo4j.com/docs/cypher-manual/current/functions/string/#functions-tostring) Cypher function. */
  formatted?: Maybe<Scalars['String']>;
};

/** Generated DateTime input object for Neo4j [Temporal field arguments](https://grandstack.io/docs/graphql-temporal-types-datetime/#temporal-query-arguments). */
export type _Neo4jDateTimeInput = {
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  millisecond?: Maybe<Scalars['Int']>;
  microsecond?: Maybe<Scalars['Int']>;
  nanosecond?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  /** Creates a Neo4j [Temporal](https://grandstack.io/docs/graphql-temporal-types-datetime/#using-temporal-fields-in-mutations) DateTime value using a [String format](https://neo4j.com/docs/cypher-manual/current/functions/temporal/datetime/#functions-datetime-create-string). */
  formatted?: Maybe<Scalars['String']>;
};

/** Generated DateTime object type for Neo4j [Temporal fields](https://grandstack.io/docs/graphql-temporal-types-datetime#using-temporal-fields-in-queries). */
export type _Neo4jDateTime = {
  __typename?: '_Neo4jDateTime';
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  millisecond?: Maybe<Scalars['Int']>;
  microsecond?: Maybe<Scalars['Int']>;
  nanosecond?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  /** Outputs a Neo4j [Temporal](https://grandstack.io/docs/graphql-temporal-types-datetime#using-temporal-fields-in-queries) DateTime value as a String type by using the [toString](https://neo4j.com/docs/cypher-manual/current/functions/string/#functions-tostring) Cypher function. */
  formatted?: Maybe<Scalars['String']>;
};

/** Generated LocalTime input object for Neo4j [Temporal field arguments](https://grandstack.io/docs/graphql-temporal-types-datetime/#temporal-query-arguments). */
export type _Neo4jLocalTimeInput = {
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  millisecond?: Maybe<Scalars['Int']>;
  microsecond?: Maybe<Scalars['Int']>;
  nanosecond?: Maybe<Scalars['Int']>;
  /** Creates a Neo4j [Temporal](https://grandstack.io/docs/graphql-temporal-types-datetime/#using-temporal-fields-in-mutations) LocalTime value using a [String format](https://neo4j.com/docs/cypher-manual/current/functions/temporal/localtime/#functions-localtime-create-string). */
  formatted?: Maybe<Scalars['String']>;
};

/** Generated LocalTime object type for Neo4j [Temporal fields](https://grandstack.io/docs/graphql-temporal-types-datetime#using-temporal-fields-in-queries). */
export type _Neo4jLocalTime = {
  __typename?: '_Neo4jLocalTime';
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  millisecond?: Maybe<Scalars['Int']>;
  microsecond?: Maybe<Scalars['Int']>;
  nanosecond?: Maybe<Scalars['Int']>;
  /** Outputs a Neo4j [Temporal](https://grandstack.io/docs/graphql-temporal-types-datetime#using-temporal-fields-in-queries) LocalTime value as a String type by using the [toString](https://neo4j.com/docs/cypher-manual/current/functions/string/#functions-tostring) Cypher function. */
  formatted?: Maybe<Scalars['String']>;
};

/** Generated LocalDateTime input object for Neo4j [Temporal field arguments](https://grandstack.io/docs/graphql-temporal-types-datetime/#temporal-query-arguments). */
export type _Neo4jLocalDateTimeInput = {
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  millisecond?: Maybe<Scalars['Int']>;
  microsecond?: Maybe<Scalars['Int']>;
  nanosecond?: Maybe<Scalars['Int']>;
  /** Creates a Neo4j [Temporal](https://grandstack.io/docs/graphql-temporal-types-datetime/#using-temporal-fields-in-mutations) LocalDateTime value using a [String format](https://neo4j.com/docs/cypher-manual/current/functions/temporal/localdatetime/#functions-localdatetime-create-string). */
  formatted?: Maybe<Scalars['String']>;
};

/** Generated LocalDateTime object type for Neo4j [Temporal fields](https://grandstack.io/docs/graphql-temporal-types-datetime#using-temporal-fields-in-queries). */
export type _Neo4jLocalDateTime = {
  __typename?: '_Neo4jLocalDateTime';
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  millisecond?: Maybe<Scalars['Int']>;
  microsecond?: Maybe<Scalars['Int']>;
  nanosecond?: Maybe<Scalars['Int']>;
  /** Outputs a Neo4j [Temporal](https://grandstack.io/docs/graphql-temporal-types-datetime#using-temporal-fields-in-queries) LocalDateTime value as a String type by using the [toString](https://neo4j.com/docs/cypher-manual/current/functions/string/#functions-tostring) Cypher function. */
  formatted?: Maybe<Scalars['String']>;
};

export type _Neo4jPointDistanceFilter = {
  point: _Neo4jPointInput;
  distance: Scalars['Float'];
};

/** Generated Point input object for Neo4j [Spatial field arguments](https://grandstack.io/docs/graphql-spatial-types/#point-query-arguments). */
export type _Neo4jPointInput = {
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  z?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  crs?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
};

/** Generated Point object type for Neo4j [Spatial fields](https://grandstack.io/docs/graphql-spatial-types#using-point-in-queries). */
export type _Neo4jPoint = {
  __typename?: '_Neo4jPoint';
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  z?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  crs?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
};

export enum _RelationDirections {
  IN = 'IN',
  OUT = 'OUT'
}

export type Query = {
  __typename?: 'Query';
  Market?: Maybe<MarketStatus>;
  Ticker?: Maybe<Array<Maybe<Ticker>>>;
  TickerDetail?: Maybe<TickerDetail>;
  TickerNews?: Maybe<Array<Maybe<TickerNews>>>;
  StockSnapshot?: Maybe<StockSnapshotResponse>;
  StockTradeHistorical?: Maybe<Array<Maybe<ITradeStockHistorical>>>;
  StockQuoteHistorical?: Maybe<Array<Maybe<IQuoteStockHistorical>>>;
  StockAggregates?: Maybe<Array<Maybe<IAggregateStockHistorical>>>;
  CryptoTradeHistorical?: Maybe<Array<Maybe<ITradeCryptoHistorical>>>;
  CryptoSnapshot?: Maybe<CryptoSnapshotResponse>;
  ForexSnapshot?: Maybe<ForexSnapshotResponse>;
  /** [Generated query](https://grandstack.io/docs/graphql-schema-generation-augmentation#generated-queries) for User type nodes. */
  User?: Maybe<Array<Maybe<User>>>;
};


export type QueryTickerArgs = {
  market?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryTickerDetailArgs = {
  stocksTicker?: Maybe<Scalars['String']>;
};


export type QueryTickerNewsArgs = {
  stocksTicker: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryStockTradeHistoricalArgs = {
  ticker: Scalars['String'];
  date: _Neo4jDateInput;
  reverse?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryStockQuoteHistoricalArgs = {
  ticker: Scalars['String'];
  date: _Neo4jDateInput;
  reverse?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryStockAggregatesArgs = {
  ticker: Scalars['String'];
  multiplier: Scalars['Int'];
  timespan: AggregateTimeSpan;
  from: _Neo4jDateTimeInput;
  to: _Neo4jDateTimeInput;
  unadjusted?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCryptoTradeHistoricalArgs = {
  pair: _CurrencyPairInput;
  date: _Neo4jDateInput;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  email?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_UserOrdering>>>;
  filter?: Maybe<_UserFilter>;
};

export type Mutation = {
  __typename?: 'Mutation';
  UnsubscribePolygonStreams?: Maybe<Scalars['Boolean']>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#create) for [creating](https://neo4j.com/docs/cypher-manual/4.1/clauses/create/#create-nodes) a User node. */
  CreateUser?: Maybe<User>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#delete) for [deleting](https://neo4j.com/docs/cypher-manual/4.1/clauses/delete/#delete-delete-single-node) a User node. */
  DeleteUser?: Maybe<User>;
  /** [Generated mutation](https://grandstack.io/docs/graphql-schema-generation-augmentation/#merge) for [merging](https://neo4j.com/docs/cypher-manual/4.1/clauses/merge/#query-merge-node-derived) a User node. */
  MergeUser?: Maybe<User>;
};


export type MutationCreateUserArgs = {
  email: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  email: Scalars['String'];
};


export type MutationMergeUserArgs = {
  email: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  CryptoTrade?: Maybe<ITradeCryptoEvent>;
  CryptoQuote?: Maybe<IQuoteCryptoEvent>;
  CryptoAggregation?: Maybe<IAggregateCryptoEvent>;
  StockTrade?: Maybe<ITradeStockEvent>;
  StockQuote?: Maybe<IQuoteStockEvent>;
  StockAggregationSecond?: Maybe<IAggregateStockEvent>;
  StockAggregationMinute?: Maybe<IAggregateStockEvent>;
  ForexCurrency?: Maybe<IQuoteForexEvent>;
  ForexCurrencyAggregationMinute?: Maybe<IAggegateForexEvent>;
  ForexBonds?: Maybe<IQuoteForexEvent>;
  ForexCommodities?: Maybe<IQuoteForexEvent>;
  ForexMetals?: Maybe<IQuoteForexEvent>;
  StrategyExample?: Maybe<Array<Maybe<Scalars['Float']>>>;
  StrategyAROON?: Maybe<Scalars['String']>;
  /** Acceleration Bands */
  IndicatorACCBANDS?: Maybe<IndicatorEvent>;
  /** Vector Trigonometric ACos */
  IndicatorACOS?: Maybe<IndicatorEvent>;
  /** Chaikin A/D Line */
  IndicatorAD?: Maybe<IndicatorEvent>;
  /** Vector Arithmetic Add */
  IndicatorADD?: Maybe<IndicatorEvent>;
  /** Chaikin A/D Oscillator */
  IndicatorADOSC?: Maybe<IndicatorEvent>;
  /** Average Directional Movement Index */
  IndicatorADX?: Maybe<IndicatorEvent>;
  /** Average Directional Movement Index Rating */
  IndicatorADXR?: Maybe<IndicatorEvent>;
  /** Absolute Price Oscillator */
  IndicatorAPO?: Maybe<IndicatorEvent>;
  /** Aroon */
  IndicatorAROON?: Maybe<IndicatorEvent>;
  /** Aroon Oscillator */
  IndicatorAROONOSC?: Maybe<IndicatorEvent>;
  /** Vector Trigonometric ASin */
  IndicatorASIN?: Maybe<IndicatorEvent>;
  /** Vector Trigonometric ATan */
  IndicatorATAN?: Maybe<IndicatorEvent>;
  /** Average True Range */
  IndicatorATR?: Maybe<IndicatorEvent>;
  /** Average Price */
  IndicatorAVGPRICE?: Maybe<IndicatorEvent>;
  /** Average Deviation */
  IndicatorAVGDEV?: Maybe<IndicatorEvent>;
  /** Bollinger Bands */
  IndicatorBBANDS?: Maybe<IndicatorEvent>;
  /** Beta */
  IndicatorBETA?: Maybe<IndicatorEvent>;
  /** Balance Of Power */
  IndicatorBOP?: Maybe<IndicatorEvent>;
  /** Commodity Channel Index */
  IndicatorCCI?: Maybe<IndicatorEvent>;
  /** Two Crows */
  IndicatorCDL2CROWS?: Maybe<IndicatorEvent>;
  /** Three Black Crows */
  IndicatorCDL3BLACKCROWS?: Maybe<IndicatorEvent>;
  /** Three Inside Up/Down */
  IndicatorCDL3INSIDE?: Maybe<IndicatorEvent>;
  /** Three */
  IndicatorCDL3LINESTRIKE?: Maybe<IndicatorEvent>;
  /** Three Outside Up/Down */
  IndicatorCDL3OUTSIDE?: Maybe<IndicatorEvent>;
  /** Three Stars In The South */
  IndicatorCDL3STARSINSOUTH?: Maybe<IndicatorEvent>;
  /** Three Advancing White Soldiers */
  IndicatorCDL3WHITESOLDIERS?: Maybe<IndicatorEvent>;
  /** Abandoned Baby */
  IndicatorCDLABANDONEDBABY?: Maybe<IndicatorEvent>;
  /** Advance Block */
  IndicatorCDLADVANCEBLOCK?: Maybe<IndicatorEvent>;
  /** Belt */
  IndicatorCDLBELTHOLD?: Maybe<IndicatorEvent>;
  /** Breakaway */
  IndicatorCDLBREAKAWAY?: Maybe<IndicatorEvent>;
  /** Closing Marubozu */
  IndicatorCDLCLOSINGMARUBOZU?: Maybe<IndicatorEvent>;
  /** Concealing Baby Swallow */
  IndicatorCDLCONCEALBABYSWALL?: Maybe<IndicatorEvent>;
  /** Counterattack */
  IndicatorCDLCOUNTERATTACK?: Maybe<IndicatorEvent>;
  /** Dark Cloud Cover */
  IndicatorCDLDARKCLOUDCOVER?: Maybe<IndicatorEvent>;
  /** Doji */
  IndicatorCDLDOJI?: Maybe<IndicatorEvent>;
  /** Doji Star */
  IndicatorCDLDOJISTAR?: Maybe<IndicatorEvent>;
  /** Dragonfly Doji */
  IndicatorCDLDRAGONFLYDOJI?: Maybe<IndicatorEvent>;
  /** Engulfing Pattern */
  IndicatorCDLENGULFING?: Maybe<IndicatorEvent>;
  /** Evening Doji Star */
  IndicatorCDLEVENINGDOJISTAR?: Maybe<IndicatorEvent>;
  /** Evening Star */
  IndicatorCDLEVENINGSTAR?: Maybe<IndicatorEvent>;
  /** Up/Down */
  IndicatorCDLGAPSIDESIDEWHITE?: Maybe<IndicatorEvent>;
  /** Gravestone Doji */
  IndicatorCDLGRAVESTONEDOJI?: Maybe<IndicatorEvent>;
  /** Hammer */
  IndicatorCDLHAMMER?: Maybe<IndicatorEvent>;
  /** Hanging Man */
  IndicatorCDLHANGINGMAN?: Maybe<IndicatorEvent>;
  /** Harami Pattern */
  IndicatorCDLHARAMI?: Maybe<IndicatorEvent>;
  /** Harami Cross Pattern */
  IndicatorCDLHARAMICROSS?: Maybe<IndicatorEvent>;
  /** High */
  IndicatorCDLHIGHWAVE?: Maybe<IndicatorEvent>;
  /** Hikkake Pattern */
  IndicatorCDLHIKKAKE?: Maybe<IndicatorEvent>;
  /** Modified Hikkake Pattern */
  IndicatorCDLHIKKAKEMOD?: Maybe<IndicatorEvent>;
  /** Homing Pigeon */
  IndicatorCDLHOMINGPIGEON?: Maybe<IndicatorEvent>;
  /** Identical Three Crows */
  IndicatorCDLIDENTICAL3CROWS?: Maybe<IndicatorEvent>;
  /** In */
  IndicatorCDLINNECK?: Maybe<IndicatorEvent>;
  /** Inverted Hammer */
  IndicatorCDLINVERTEDHAMMER?: Maybe<IndicatorEvent>;
  /** Kicking */
  IndicatorCDLKICKING?: Maybe<IndicatorEvent>;
  /** Kicking */
  IndicatorCDLKICKINGBYLENGTH?: Maybe<IndicatorEvent>;
  /** Ladder Bottom */
  IndicatorCDLLADDERBOTTOM?: Maybe<IndicatorEvent>;
  /** Long Legged Doji */
  IndicatorCDLLONGLEGGEDDOJI?: Maybe<IndicatorEvent>;
  /** Long Line Candle */
  IndicatorCDLLONGLINE?: Maybe<IndicatorEvent>;
  /** Marubozu */
  IndicatorCDLMARUBOZU?: Maybe<IndicatorEvent>;
  /** Matching Low */
  IndicatorCDLMATCHINGLOW?: Maybe<IndicatorEvent>;
  /** Mat Hold */
  IndicatorCDLMATHOLD?: Maybe<IndicatorEvent>;
  /** Morning Doji Star */
  IndicatorCDLMORNINGDOJISTAR?: Maybe<IndicatorEvent>;
  /** Morning Star */
  IndicatorCDLMORNINGSTAR?: Maybe<IndicatorEvent>;
  /** On */
  IndicatorCDLONNECK?: Maybe<IndicatorEvent>;
  /** Piercing Pattern */
  IndicatorCDLPIERCING?: Maybe<IndicatorEvent>;
  /** Rickshaw Man */
  IndicatorCDLRICKSHAWMAN?: Maybe<IndicatorEvent>;
  /** Rising/Falling Three Methods */
  IndicatorCDLRISEFALL3METHODS?: Maybe<IndicatorEvent>;
  /** Separating Lines */
  IndicatorCDLSEPARATINGLINES?: Maybe<IndicatorEvent>;
  /** Shooting Star */
  IndicatorCDLSHOOTINGSTAR?: Maybe<IndicatorEvent>;
  /** Short Line Candle */
  IndicatorCDLSHORTLINE?: Maybe<IndicatorEvent>;
  /** Spinning Top */
  IndicatorCDLSPINNINGTOP?: Maybe<IndicatorEvent>;
  /** Stalled Pattern */
  IndicatorCDLSTALLEDPATTERN?: Maybe<IndicatorEvent>;
  /** Stick Sandwich */
  IndicatorCDLSTICKSANDWICH?: Maybe<IndicatorEvent>;
  /** Takuri (Dragonfly Doji with very long lower shadow) */
  IndicatorCDLTAKURI?: Maybe<IndicatorEvent>;
  /** Tasuki Gap */
  IndicatorCDLTASUKIGAP?: Maybe<IndicatorEvent>;
  /** Thrusting Pattern */
  IndicatorCDLTHRUSTING?: Maybe<IndicatorEvent>;
  /** Tristar Pattern */
  IndicatorCDLTRISTAR?: Maybe<IndicatorEvent>;
  /** Unique 3 River */
  IndicatorCDLUNIQUE3RIVER?: Maybe<IndicatorEvent>;
  /** Upside Gap Two Crows */
  IndicatorCDLUPSIDEGAP2CROWS?: Maybe<IndicatorEvent>;
  /** Upside/Downside Gap Three Methods */
  IndicatorCDLXSIDEGAP3METHODS?: Maybe<IndicatorEvent>;
  /** Vector Ceil */
  IndicatorCEIL?: Maybe<IndicatorEvent>;
  /** Chande Momentum Oscillator */
  IndicatorCMO?: Maybe<IndicatorEvent>;
  /** Pearson's Correlation Coefficient (r) */
  IndicatorCORREL?: Maybe<IndicatorEvent>;
  /** Vector Trigonometric Cosh */
  IndicatorCOSH?: Maybe<IndicatorEvent>;
  /** Double Exponential Moving Average */
  IndicatorDEMA?: Maybe<IndicatorEvent>;
  /** Vector Arithmetic Div */
  IndicatorDIV?: Maybe<IndicatorEvent>;
  /** Vector Arithmetic Exp */
  IndicatorEXP?: Maybe<IndicatorEvent>;
  /** Vector Floor */
  IndicatorFLOOR?: Maybe<IndicatorEvent>;
  /** Hilbert Transform */
  IndicatorHT_DCPERIOD?: Maybe<IndicatorEvent>;
  /** Hilbert Transform */
  IndicatorHT_DCPHASE?: Maybe<IndicatorEvent>;
  /** Hilbert Transform */
  IndicatorHT_PHASOR?: Maybe<IndicatorEvent>;
  /** Hilbert Transform */
  IndicatorHT_SINE?: Maybe<IndicatorEvent>;
  /** Hilbert Transform */
  IndicatorHT_TRENDLINE?: Maybe<IndicatorEvent>;
  /** Hilbert Transform */
  IndicatorHT_TRENDMODE?: Maybe<IndicatorEvent>;
  /** Intraday Momentum Index */
  IndicatorIMI?: Maybe<IndicatorEvent>;
  /** Kaufman Adaptive Moving Average */
  IndicatorKAMA?: Maybe<IndicatorEvent>;
  /** Linear Regression */
  IndicatorLINEARREG?: Maybe<IndicatorEvent>;
  /** Linear Regression Angle */
  IndicatorLINEARREG_ANGLE?: Maybe<IndicatorEvent>;
  /** Linear Regression Intercept */
  IndicatorLINEARREG_INTERCEPT?: Maybe<IndicatorEvent>;
  /** Linear Regression Slope */
  IndicatorLINEARREG_SLOPE?: Maybe<IndicatorEvent>;
  /** Vector Log Natural */
  IndicatorLN?: Maybe<IndicatorEvent>;
  /** Vector Log10 */
  IndicatorLOG10?: Maybe<IndicatorEvent>;
  /** Moving Average Convergence/Divergence */
  IndicatorMACD?: Maybe<IndicatorEvent>;
  /** MACD with controllable MA type */
  IndicatorMACDEXT?: Maybe<IndicatorEvent>;
  /** Moving Average Convergence/Divergence Fix 12/26 */
  IndicatorMACDFIX?: Maybe<IndicatorEvent>;
  /** MESA Adaptive Moving Average */
  IndicatorMAMA?: Maybe<IndicatorEvent>;
  /** Moving average with variable period */
  IndicatorMAVP?: Maybe<IndicatorEvent>;
  /** Highest value over a specified period */
  IndicatorMAX?: Maybe<IndicatorEvent>;
  /** Index of highest value over a specified period */
  IndicatorMAXINDEX?: Maybe<IndicatorEvent>;
  /** Median Price */
  IndicatorMEDPRICE?: Maybe<IndicatorEvent>;
  /** Money Flow Index */
  IndicatorMFI?: Maybe<IndicatorEvent>;
  /** MidPoint over period */
  IndicatorMIDPOINT?: Maybe<IndicatorEvent>;
  /** Midpoint Price over period */
  IndicatorMIDPRICE?: Maybe<IndicatorEvent>;
  /** Index of lowest value over a specified period */
  IndicatorMININDEX?: Maybe<IndicatorEvent>;
  /** Lowest and highest values over a specified period */
  IndicatorMINMAX?: Maybe<IndicatorEvent>;
  /** Indexes of lowest and highest values over a specified period */
  IndicatorMINMAXINDEX?: Maybe<IndicatorEvent>;
  /** Minus Directional Indicator */
  IndicatorMINUS_DI?: Maybe<IndicatorEvent>;
  /** Minus Directional Movement */
  IndicatorMINUS_DM?: Maybe<IndicatorEvent>;
  /** Momentum */
  IndicatorMOM?: Maybe<IndicatorEvent>;
  /** Vector Arithmetic Mult */
  IndicatorMULT?: Maybe<IndicatorEvent>;
  /** Normalized Average True Range */
  IndicatorNATR?: Maybe<IndicatorEvent>;
  /** On Balance Volume */
  IndicatorOBV?: Maybe<IndicatorEvent>;
  /** Plus Directional Indicator */
  IndicatorPLUS_DI?: Maybe<IndicatorEvent>;
  /** Plus Directional Movement */
  IndicatorPLUS_DM?: Maybe<IndicatorEvent>;
  /** Percentage Price Oscillator */
  IndicatorPPO?: Maybe<IndicatorEvent>;
  /** Rate of change : ((price/prevPrice) */
  IndicatorROC?: Maybe<IndicatorEvent>;
  /** Rate of change Percentage: (price */
  IndicatorROCP?: Maybe<IndicatorEvent>;
  /** Rate of change ratio: (price/prevPrice) */
  IndicatorROCR?: Maybe<IndicatorEvent>;
  /** Rate of change ratio 100 scale: (price/prevPrice)*100 */
  IndicatorROCR100?: Maybe<IndicatorEvent>;
  /** Parabolic SAR */
  IndicatorSAR?: Maybe<IndicatorEvent>;
  /** Parabolic SAR */
  IndicatorSAREXT?: Maybe<IndicatorEvent>;
  /** Vector Trigonometric Sinh */
  IndicatorSINH?: Maybe<IndicatorEvent>;
  /** Simple Moving Average */
  IndicatorSMA?: Maybe<IndicatorEvent>;
  /** Vector Square Root */
  IndicatorSQRT?: Maybe<IndicatorEvent>;
  /** Standard Deviation */
  IndicatorSTDDEV?: Maybe<IndicatorEvent>;
  /** Stochastic */
  IndicatorSTOCH?: Maybe<IndicatorEvent>;
  /** Stochastic Fast */
  IndicatorSTOCHF?: Maybe<IndicatorEvent>;
  /** Stochastic Relative Strength Index */
  IndicatorSTOCHRSI?: Maybe<IndicatorEvent>;
  /** Vector Arithmetic Substraction */
  IndicatorSUB?: Maybe<IndicatorEvent>;
  /** Summation */
  IndicatorSUM?: Maybe<IndicatorEvent>;
  /** Triple Exponential Moving Average (T3) */
  IndicatorT3?: Maybe<IndicatorEvent>;
  /** Vector Trigonometric Tanh */
  IndicatorTANH?: Maybe<IndicatorEvent>;
  /** Triple Exponential Moving Average */
  IndicatorTEMA?: Maybe<IndicatorEvent>;
  /** True Range */
  IndicatorTRANGE?: Maybe<IndicatorEvent>;
  /** Triangular Moving Average */
  IndicatorTRIMA?: Maybe<IndicatorEvent>;
  /** 1 */
  IndicatorTRIX?: Maybe<IndicatorEvent>;
  /** Time Series Forecast */
  IndicatorTSF?: Maybe<IndicatorEvent>;
  /** Typical Price */
  IndicatorTYPPRICE?: Maybe<IndicatorEvent>;
  /** Ultimate Oscillator */
  IndicatorULTOSC?: Maybe<IndicatorEvent>;
  /** Variance */
  IndicatorVAR?: Maybe<IndicatorEvent>;
  /** Weighted Close Price */
  IndicatorWCLPRICE?: Maybe<IndicatorEvent>;
  /** Williams' %R */
  IndicatorWILLR?: Maybe<IndicatorEvent>;
};


export type SubscriptionCryptoTradeArgs = {
  pair: _CurrencyPairInput;
};


export type SubscriptionCryptoQuoteArgs = {
  pair: _CurrencyPairInput;
};


export type SubscriptionCryptoAggregationArgs = {
  pair: _CurrencyPairInput;
};


export type SubscriptionStockTradeArgs = {
  ticker: Scalars['String'];
};


export type SubscriptionStockQuoteArgs = {
  ticker: Scalars['String'];
};


export type SubscriptionStockAggregationSecondArgs = {
  ticker: Scalars['String'];
};


export type SubscriptionStockAggregationMinuteArgs = {
  ticker: Scalars['String'];
};


export type SubscriptionForexCurrencyArgs = {
  pair: _CurrencyPairInput;
};


export type SubscriptionForexCurrencyAggregationMinuteArgs = {
  pair: _CurrencyPairInput;
};


export type SubscriptionForexBondsArgs = {
  pair: _CurrencyPairInput;
};


export type SubscriptionForexCommoditiesArgs = {
  pair: _CurrencyPairInput;
};


export type SubscriptionForexMetalsArgs = {
  pair: _CurrencyPairInput;
};


export type SubscriptionStrategyExampleArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
};


export type SubscriptionStrategyAROONArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
};


export type SubscriptionIndicatorACCBANDSArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorACOSArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorADArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorADDArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorADOSCArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optFast_Period?: Maybe<Scalars['Float']>;
  optSlow_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorADXArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorADXRArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorAPOArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optFast_Period?: Maybe<Scalars['Float']>;
  optSlow_Period?: Maybe<Scalars['Float']>;
  optMA_Type?: Maybe<MATypes>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorAROONArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorAROONOSCArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorASINArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorATANArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorATRArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorAVGPRICEArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorAVGDEVArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorBBANDSArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  optDeviations_up?: Maybe<Scalars['Float']>;
  optDeviations_down?: Maybe<Scalars['Float']>;
  optMA_Type?: Maybe<MATypes>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorBETAArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorBOPArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCCIArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDL2CROWSArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDL3BLACKCROWSArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDL3INSIDEArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDL3LINESTRIKEArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDL3OUTSIDEArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDL3STARSINSOUTHArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDL3WHITESOLDIERSArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLABANDONEDBABYArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optPenetration?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLADVANCEBLOCKArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLBELTHOLDArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLBREAKAWAYArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLCLOSINGMARUBOZUArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLCONCEALBABYSWALLArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLCOUNTERATTACKArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLDARKCLOUDCOVERArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optPenetration?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLDOJIArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLDOJISTARArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLDRAGONFLYDOJIArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLENGULFINGArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLEVENINGDOJISTARArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optPenetration?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLEVENINGSTARArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optPenetration?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLGAPSIDESIDEWHITEArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLGRAVESTONEDOJIArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLHAMMERArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLHANGINGMANArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLHARAMIArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLHARAMICROSSArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLHIGHWAVEArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLHIKKAKEArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLHIKKAKEMODArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLHOMINGPIGEONArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLIDENTICAL3CROWSArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLINNECKArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLINVERTEDHAMMERArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLKICKINGArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLKICKINGBYLENGTHArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLLADDERBOTTOMArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLLONGLEGGEDDOJIArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLLONGLINEArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLMARUBOZUArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLMATCHINGLOWArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLMATHOLDArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optPenetration?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLMORNINGDOJISTARArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optPenetration?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLMORNINGSTARArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optPenetration?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLONNECKArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLPIERCINGArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLRICKSHAWMANArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLRISEFALL3METHODSArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLSEPARATINGLINESArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLSHOOTINGSTARArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLSHORTLINEArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLSPINNINGTOPArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLSTALLEDPATTERNArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLSTICKSANDWICHArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLTAKURIArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLTASUKIGAPArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLTHRUSTINGArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLTRISTARArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLUNIQUE3RIVERArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLUPSIDEGAP2CROWSArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCDLXSIDEGAP3METHODSArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCEILArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCMOArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCORRELArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorCOSHArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorDEMAArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorDIVArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorEXPArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorFLOORArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorHT_DCPERIODArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorHT_DCPHASEArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorHT_PHASORArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorHT_SINEArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorHT_TRENDLINEArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorHT_TRENDMODEArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorIMIArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorKAMAArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorLINEARREGArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorLINEARREG_ANGLEArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorLINEARREG_INTERCEPTArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorLINEARREG_SLOPEArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorLNArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorLOG10Args = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorMACDArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optFast_Period?: Maybe<Scalars['Float']>;
  optSlow_Period?: Maybe<Scalars['Float']>;
  optSignal_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorMACDEXTArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optFast_Period?: Maybe<Scalars['Float']>;
  optFast_MA?: Maybe<MATypes>;
  optSlow_Period?: Maybe<Scalars['Float']>;
  optSlow_MA?: Maybe<MATypes>;
  optSignal_Period?: Maybe<Scalars['Float']>;
  optSignal_MA?: Maybe<MATypes>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorMACDFIXArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optSignal_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorMAMAArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optFast_Limit?: Maybe<Scalars['Float']>;
  optSlow_Limit?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorMAVPArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optMinimum_Period?: Maybe<Scalars['Float']>;
  optMaximum_Period?: Maybe<Scalars['Float']>;
  optMA_Type?: Maybe<MATypes>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorMAXArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorMAXINDEXArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorMEDPRICEArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorMFIArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorMIDPOINTArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorMIDPRICEArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorMININDEXArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorMINMAXArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorMINMAXINDEXArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorMINUS_DIArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorMINUS_DMArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorMOMArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorMULTArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorNATRArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorOBVArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorPLUS_DIArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorPLUS_DMArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorPPOArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optFast_Period?: Maybe<Scalars['Float']>;
  optSlow_Period?: Maybe<Scalars['Float']>;
  optMA_Type?: Maybe<MATypes>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorROCArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorROCPArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorROCRArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorROCR100Args = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorSARArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optAcceleration_Factor?: Maybe<Scalars['Float']>;
  optAF_Maximum?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorSAREXTArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optStart_Value?: Maybe<Scalars['Float']>;
  optOffset_on_Reverse?: Maybe<Scalars['Float']>;
  optAF_Init_Long?: Maybe<Scalars['Float']>;
  optAF_Long?: Maybe<Scalars['Float']>;
  optAF_Max_Long?: Maybe<Scalars['Float']>;
  optAF_Init_Short?: Maybe<Scalars['Float']>;
  optAF_Short?: Maybe<Scalars['Float']>;
  optAF_Max_Short?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorSINHArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorSMAArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorSQRTArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorSTDDEVArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  optDeviations?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorSTOCHArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optFastK_Period?: Maybe<Scalars['Float']>;
  optSlowK_Period?: Maybe<Scalars['Float']>;
  optSlowK_MA?: Maybe<MATypes>;
  optSlowD_Period?: Maybe<Scalars['Float']>;
  optSlowD_MA?: Maybe<MATypes>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorSTOCHFArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optFastK_Period?: Maybe<Scalars['Float']>;
  optFastD_Period?: Maybe<Scalars['Float']>;
  optFastD_MA?: Maybe<MATypes>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorSTOCHRSIArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  optFastK_Period?: Maybe<Scalars['Float']>;
  optFastD_Period?: Maybe<Scalars['Float']>;
  optFastD_MA?: Maybe<MATypes>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorSUBArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorSUMArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorT3Args = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  optVolume_Factor?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorTANHArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorTEMAArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorTRANGEArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorTRIMAArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorTRIXArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorTSFArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorTYPPRICEArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorULTOSCArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optFirst_Period?: Maybe<Scalars['Float']>;
  optSecond_Period?: Maybe<Scalars['Float']>;
  optThird_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorVARArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  optDeviations?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorWCLPRICEArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};


export type SubscriptionIndicatorWILLRArgs = {
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
};

/** A GraphQL Schema defines the capabilities of a GraphQL server. It exposes all available types and directives on the server, as well as the entry points for query, mutation, and subscription operations. */
export type __Schema = {
  __typename?: '__Schema';
  description?: Maybe<Scalars['String']>;
  /** A list of all types supported by this server. */
  types: Array<__Type>;
  /** The type that query operations will be rooted at. */
  queryType: __Type;
  /** If this server supports mutation, the type that mutation operations will be rooted at. */
  mutationType?: Maybe<__Type>;
  /** If this server support subscription, the type that subscription operations will be rooted at. */
  subscriptionType?: Maybe<__Type>;
  /** A list of all directives supported by this server. */
  directives: Array<__Directive>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __Type = {
  __typename?: '__Type';
  kind: __TypeKind;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  specifiedByUrl?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<__Field>>;
  interfaces?: Maybe<Array<__Type>>;
  possibleTypes?: Maybe<Array<__Type>>;
  enumValues?: Maybe<Array<__EnumValue>>;
  inputFields?: Maybe<Array<__InputValue>>;
  ofType?: Maybe<__Type>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypefieldsArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeenumValuesArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeinputFieldsArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};

/** An enum describing what kind of type a given `__Type` is. */
export enum __TypeKind {
  /** Indicates this type is a scalar. */
  SCALAR = 'SCALAR',
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  OBJECT = 'OBJECT',
  /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
  INTERFACE = 'INTERFACE',
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  UNION = 'UNION',
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  ENUM = 'ENUM',
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  INPUT_OBJECT = 'INPUT_OBJECT',
  /** Indicates this type is a list. `ofType` is a valid field. */
  LIST = 'LIST',
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  NON_NULL = 'NON_NULL'
}

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __Field = {
  __typename?: '__Field';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  args: Array<__InputValue>;
  type: __Type;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};


/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __FieldargsArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};

/** Arguments provided to Fields or Directives and the input fields of an InputObject are represented as Input Values which describe their type and optionally a default value. */
export type __InputValue = {
  __typename?: '__InputValue';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: __Type;
  /** A GraphQL-formatted string representing the default value for this input value. */
  defaultValue?: Maybe<Scalars['String']>;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};

/** One possible value for a given Enum. Enum values are unique values, not a placeholder for a string or numeric value. However an Enum value is returned in a JSON response as a string. */
export type __EnumValue = {
  __typename?: '__EnumValue';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};

/**
 * A Directive provides a way to describe alternate runtime execution and type validation behavior in a GraphQL document.
 *
 * In some cases, you need to provide options to alter GraphQL's execution behavior in ways field arguments will not suffice, such as conditionally including or skipping a field. Directives provide this by describing additional information to the executor.
 */
export type __Directive = {
  __typename?: '__Directive';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isRepeatable: Scalars['Boolean'];
  locations: Array<__DirectiveLocation>;
  args: Array<__InputValue>;
};

/** A Directive can be adjacent to many parts of the GraphQL language, a __DirectiveLocation describes one such possible adjacencies. */
export enum __DirectiveLocation {
  /** Location adjacent to a query operation. */
  QUERY = 'QUERY',
  /** Location adjacent to a mutation operation. */
  MUTATION = 'MUTATION',
  /** Location adjacent to a subscription operation. */
  SUBSCRIPTION = 'SUBSCRIPTION',
  /** Location adjacent to a field. */
  FIELD = 'FIELD',
  /** Location adjacent to a fragment definition. */
  FRAGMENT_DEFINITION = 'FRAGMENT_DEFINITION',
  /** Location adjacent to a fragment spread. */
  FRAGMENT_SPREAD = 'FRAGMENT_SPREAD',
  /** Location adjacent to an inline fragment. */
  INLINE_FRAGMENT = 'INLINE_FRAGMENT',
  /** Location adjacent to a variable definition. */
  VARIABLE_DEFINITION = 'VARIABLE_DEFINITION',
  /** Location adjacent to a schema definition. */
  SCHEMA = 'SCHEMA',
  /** Location adjacent to a scalar definition. */
  SCALAR = 'SCALAR',
  /** Location adjacent to an object type definition. */
  OBJECT = 'OBJECT',
  /** Location adjacent to a field definition. */
  FIELD_DEFINITION = 'FIELD_DEFINITION',
  /** Location adjacent to an argument definition. */
  ARGUMENT_DEFINITION = 'ARGUMENT_DEFINITION',
  /** Location adjacent to an interface definition. */
  INTERFACE = 'INTERFACE',
  /** Location adjacent to a union definition. */
  UNION = 'UNION',
  /** Location adjacent to an enum definition. */
  ENUM = 'ENUM',
  /** Location adjacent to an enum value definition. */
  ENUM_VALUE = 'ENUM_VALUE',
  /** Location adjacent to an input object type definition. */
  INPUT_OBJECT = 'INPUT_OBJECT',
  /** Location adjacent to an input object field definition. */
  INPUT_FIELD_DEFINITION = 'INPUT_FIELD_DEFINITION'
}

export type IntrospectSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type IntrospectSubscriptionsQuery = (
  { __typename?: 'Query' }
  & { __schema: (
    { __typename?: '__Schema' }
    & { subscriptionType?: Maybe<(
      { __typename?: '__Type' }
      & { fields?: Maybe<Array<(
        { __typename?: '__Field' }
        & Pick<__Field, 'name' | 'description'>
        & { args: Array<(
          { __typename?: '__InputValue' }
          & Pick<__InputValue, 'name'>
          & { type: (
            { __typename?: '__Type' }
            & { ofType?: Maybe<(
              { __typename?: '__Type' }
              & Pick<__Type, 'name' | 'kind'>
              & { enumValues?: Maybe<Array<(
                { __typename?: '__EnumValue' }
                & Pick<__EnumValue, 'name'>
              )>>, inputFields?: Maybe<Array<(
                { __typename?: '__InputValue' }
                & Pick<__InputValue, 'name'>
                & { type: (
                  { __typename?: '__Type' }
                  & { ofType?: Maybe<(
                    { __typename?: '__Type' }
                    & Pick<__Type, 'name'>
                  )> }
                ) }
              )>> }
            )> }
          ) }
        )> }
      )>> }
    )> }
  ) }
);

export type CreateUserMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { CreateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | '_id'>
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { DeleteUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | '_id'>
  )> }
);

export type MergeUserMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type MergeUserMutation = (
  { __typename?: 'Mutation' }
  & { MergeUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | '_id'>
  )> }
);

export type UnsubscribePolygonStreamsMutationVariables = Exact<{ [key: string]: never; }>;


export type UnsubscribePolygonStreamsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UnsubscribePolygonStreams'>
);

export type CryptoSnapshotQueryVariables = Exact<{ [key: string]: never; }>;


export type CryptoSnapshotQuery = (
  { __typename?: 'Query' }
  & { CryptoSnapshot?: Maybe<(
    { __typename?: 'CryptoSnapshotResponse' }
    & Pick<CryptoSnapshotResponse, 'status'>
    & { tickers?: Maybe<Array<Maybe<(
      { __typename?: 'CryptoSnapshot' }
      & Pick<CryptoSnapshot, 'ticker' | 'todaysChange' | 'todaysChangePerc' | 'updated'>
      & { day?: Maybe<(
        { __typename?: 'SnapshotDay' }
        & Pick<SnapshotDay, 'c' | 'h' | 'l' | 'o' | 'v' | 'vw'>
      )>, lastTrade?: Maybe<(
        { __typename?: 'SnapshotLastTrade' }
        & Pick<SnapshotLastTrade, 'c' | 'i' | 'p' | 's' | 't' | 'x'>
      )>, min?: Maybe<(
        { __typename?: 'CryptoSnapshotMin' }
        & Pick<CryptoSnapshotMin, 'c' | 'h' | 'l' | 'o' | 'v' | 'vw'>
      )>, prevDay?: Maybe<(
        { __typename?: 'SnapshotPrevDay' }
        & Pick<SnapshotPrevDay, 'c' | 'h' | 'l' | 'o' | 'v' | 'vw'>
      )> }
    )>>> }
  )> }
);

export type CryptoTradeHistoricalQueryVariables = Exact<{
  pair: _CurrencyPairInput;
  date: _Neo4jDateInput;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type CryptoTradeHistoricalQuery = (
  { __typename?: 'Query' }
  & { CryptoTradeHistorical?: Maybe<Array<Maybe<(
    { __typename?: 'ITradeCryptoHistorical' }
    & Pick<ITradeCryptoHistorical, 'p' | 's' | 'x' | 'c' | 't'>
  )>>> }
);

export type ForexSnapshotQueryVariables = Exact<{ [key: string]: never; }>;


export type ForexSnapshotQuery = (
  { __typename?: 'Query' }
  & { ForexSnapshot?: Maybe<(
    { __typename?: 'ForexSnapshotResponse' }
    & Pick<ForexSnapshotResponse, 'status'>
    & { tickers?: Maybe<Array<Maybe<(
      { __typename?: 'ForexSnapshot' }
      & Pick<ForexSnapshot, 'ticker' | 'todaysChange' | 'todaysChangePerc' | 'updated'>
      & { day?: Maybe<(
        { __typename?: 'ForexSnapshotDay' }
        & Pick<ForexSnapshotDay, 'c' | 'h' | 'l' | 'o' | 'v'>
      )>, lastQuote?: Maybe<(
        { __typename?: 'ForexSnapshotLastQuote' }
        & Pick<ForexSnapshotLastQuote, 'a' | 'b' | 't' | 'x'>
      )>, min?: Maybe<(
        { __typename?: 'ForexSnapshotMin' }
        & Pick<ForexSnapshotMin, 'c' | 'h' | 'l' | 'o' | 'v'>
      )>, prev?: Maybe<(
        { __typename?: 'SnapshotPrevDay' }
        & Pick<SnapshotPrevDay, 'c' | 'h' | 'l' | 'o' | 'v' | 'vw'>
      )> }
    )>>> }
  )> }
);

export type MarketQueryVariables = Exact<{ [key: string]: never; }>;


export type MarketQuery = (
  { __typename?: 'Query' }
  & { Market?: Maybe<(
    { __typename?: 'MarketStatus' }
    & Pick<MarketStatus, 'market' | 'serverTime'>
    & { exchanges?: Maybe<(
      { __typename?: 'StockMarkets' }
      & Pick<StockMarkets, 'nyse' | 'nasdaq' | 'otc'>
    )>, currencies?: Maybe<(
      { __typename?: 'CurrencyMarkets' }
      & Pick<CurrencyMarkets, 'fx' | 'crypto'>
    )> }
  )> }
);

export type StockAggregatesQueryVariables = Exact<{
  ticker: Scalars['String'];
  multiplier: Scalars['Int'];
  timespan: AggregateTimeSpan;
  from: _Neo4jDateTimeInput;
  to: _Neo4jDateTimeInput;
  unadjusted?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type StockAggregatesQuery = (
  { __typename?: 'Query' }
  & { StockAggregates?: Maybe<Array<Maybe<(
    { __typename?: 'IAggregateStockHistorical' }
    & Pick<IAggregateStockHistorical, 'o' | 'h' | 'l' | 'c' | 'v' | 'vw' | 't' | 'tf' | 'n'>
  )>>> }
);

export type StockQuoteHistoricalQueryVariables = Exact<{
  ticker: Scalars['String'];
  date: _Neo4jDateInput;
  reverse?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type StockQuoteHistoricalQuery = (
  { __typename?: 'Query' }
  & { StockQuoteHistorical?: Maybe<Array<Maybe<(
    { __typename?: 'IQuoteStockHistorical' }
    & Pick<IQuoteStockHistorical, 't' | 'y' | 'q' | 'x' | 'X' | 's' | 'S' | 'p' | 'P' | 'z' | 'c'>
  )>>> }
);

export type StockSnapshotQueryVariables = Exact<{ [key: string]: never; }>;


export type StockSnapshotQuery = (
  { __typename?: 'Query' }
  & { StockSnapshot?: Maybe<(
    { __typename?: 'StockSnapshotResponse' }
    & Pick<StockSnapshotResponse, 'status' | 'count'>
    & { tickers?: Maybe<Array<Maybe<(
      { __typename?: 'StockSnapshot' }
      & Pick<StockSnapshot, 'ticker' | 'todaysChange' | 'todaysChangePerc' | 'updated'>
      & { day?: Maybe<(
        { __typename?: 'SnapshotDay' }
        & Pick<SnapshotDay, 'c' | 'h' | 'l' | 'o' | 'v' | 'vw'>
      )>, lastQuote?: Maybe<(
        { __typename?: 'StockSnapshotLastQuote' }
        & Pick<StockSnapshotLastQuote, 'P' | 'S' | 'p' | 's' | 't'>
      )>, lastTrade?: Maybe<(
        { __typename?: 'SnapshotLastTrade' }
        & Pick<SnapshotLastTrade, 'c' | 'i' | 'p' | 's' | 't' | 'x'>
      )>, min?: Maybe<(
        { __typename?: 'StockSnapshotMin' }
        & Pick<StockSnapshotMin, 'av' | 'c' | 'h' | 'l' | 'o' | 'v' | 'vw'>
      )>, prev?: Maybe<(
        { __typename?: 'SnapshotPrevDay' }
        & Pick<SnapshotPrevDay, 'c' | 'h' | 'l' | 'o' | 'v' | 'vw'>
      )> }
    )>>> }
  )> }
);

export type StockTradeHistoricalQueryVariables = Exact<{
  ticker: Scalars['String'];
  date: _Neo4jDateInput;
  reverse?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type StockTradeHistoricalQuery = (
  { __typename?: 'Query' }
  & { StockTradeHistorical?: Maybe<Array<Maybe<(
    { __typename?: 'ITradeStockHistorical' }
    & Pick<ITradeStockHistorical, 't' | 'y' | 'q' | 'i' | 'x' | 's' | 'p' | 'c' | 'z' | 'e'>
  )>>> }
);

export type TickerQueryVariables = Exact<{
  market?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
}>;


export type TickerQuery = (
  { __typename?: 'Query' }
  & { Ticker?: Maybe<Array<Maybe<(
    { __typename?: 'Ticker' }
    & Pick<Ticker, 'ticker' | 'name' | 'market' | 'locale' | 'currency' | 'active' | 'primaryExch' | 'type'>
  )>>> }
);

export type TickerDetailQueryVariables = Exact<{
  stocksTicker?: Maybe<Scalars['String']>;
}>;


export type TickerDetailQuery = (
  { __typename?: 'Query' }
  & { TickerDetail?: Maybe<(
    { __typename?: 'TickerDetail' }
    & Pick<TickerDetail, 'logo' | 'listdate' | 'cik' | 'bloomberg' | 'lei' | 'sic' | 'country' | 'industry' | 'sector' | 'marketcap' | 'employees' | 'phone' | 'ceo' | 'url' | 'description' | 'exchange' | 'name' | 'symbol' | 'exchangeSymbol' | 'hq_address' | 'hq_state' | 'hq_country' | 'type' | 'updated' | 'tags' | 'similar' | 'active'>
  )> }
);

export type TickerNewsQueryVariables = Exact<{
  stocksTicker: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
}>;


export type TickerNewsQuery = (
  { __typename?: 'Query' }
  & { TickerNews?: Maybe<Array<Maybe<(
    { __typename?: 'TickerNews' }
    & Pick<TickerNews, 'symbols' | 'timestamp' | 'title' | 'url' | 'source' | 'summary' | 'image' | 'keywords'>
  )>>> }
);

export type UserQueryVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<_UserOrdering>> | Maybe<_UserOrdering>>;
  filter?: Maybe<_UserFilter>;
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { User?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | '_id'>
  )>>> }
);

export type CryptoAggregationSubscriptionVariables = Exact<{
  pair: _CurrencyPairInput;
}>;


export type CryptoAggregationSubscription = (
  { __typename?: 'Subscription' }
  & { CryptoAggregation?: Maybe<(
    { __typename?: 'IAggregateCryptoEvent' }
    & Pick<IAggregateCryptoEvent, 'ev' | 'pair' | 'o' | 'ox' | 'h' | 'hx' | 'l' | 'lx' | 'c' | 'cx' | 'v' | 's' | 'e'>
  )> }
);

export type CryptoQuoteSubscriptionVariables = Exact<{
  pair: _CurrencyPairInput;
}>;


export type CryptoQuoteSubscription = (
  { __typename?: 'Subscription' }
  & { CryptoQuote?: Maybe<(
    { __typename?: 'IQuoteCryptoEvent' }
    & Pick<IQuoteCryptoEvent, 'ev' | 'pair' | 'lp' | 'ls' | 'bp' | 'bs' | 'ap' | 'as' | 't' | 'x' | 'r'>
  )> }
);

export type CryptoTradeSubscriptionVariables = Exact<{
  pair: _CurrencyPairInput;
}>;


export type CryptoTradeSubscription = (
  { __typename?: 'Subscription' }
  & { CryptoTrade?: Maybe<(
    { __typename?: 'ITradeCryptoEvent' }
    & Pick<ITradeCryptoEvent, 'ev' | 'pair' | 'p' | 't' | 's' | 'c' | 'i' | 'x' | 'r'>
  )> }
);

export type ForexBondsSubscriptionVariables = Exact<{
  pair: _CurrencyPairInput;
}>;


export type ForexBondsSubscription = (
  { __typename?: 'Subscription' }
  & { ForexBonds?: Maybe<(
    { __typename?: 'IQuoteForexEvent' }
    & Pick<IQuoteForexEvent, 'ev' | 'p' | 'x' | 'a' | 'b' | 't'>
  )> }
);

export type ForexCommoditiesSubscriptionVariables = Exact<{
  pair: _CurrencyPairInput;
}>;


export type ForexCommoditiesSubscription = (
  { __typename?: 'Subscription' }
  & { ForexCommodities?: Maybe<(
    { __typename?: 'IQuoteForexEvent' }
    & Pick<IQuoteForexEvent, 'ev' | 'p' | 'x' | 'a' | 'b' | 't'>
  )> }
);

export type ForexCurrencySubscriptionVariables = Exact<{
  pair: _CurrencyPairInput;
}>;


export type ForexCurrencySubscription = (
  { __typename?: 'Subscription' }
  & { ForexCurrency?: Maybe<(
    { __typename?: 'IQuoteForexEvent' }
    & Pick<IQuoteForexEvent, 'ev' | 'p' | 'x' | 'a' | 'b' | 't'>
  )> }
);

export type ForexCurrencyAggregationMinuteSubscriptionVariables = Exact<{
  pair: _CurrencyPairInput;
}>;


export type ForexCurrencyAggregationMinuteSubscription = (
  { __typename?: 'Subscription' }
  & { ForexCurrencyAggregationMinute?: Maybe<(
    { __typename?: 'IAggegateForexEvent' }
    & Pick<IAggegateForexEvent, 'ev' | 'pair' | 'o' | 'c' | 'h' | 'l' | 'v' | 's'>
  )> }
);

export type ForexMetalsSubscriptionVariables = Exact<{
  pair: _CurrencyPairInput;
}>;


export type ForexMetalsSubscription = (
  { __typename?: 'Subscription' }
  & { ForexMetals?: Maybe<(
    { __typename?: 'IQuoteForexEvent' }
    & Pick<IQuoteForexEvent, 'ev' | 'p' | 'x' | 'a' | 'b' | 't'>
  )> }
);

export type IndicatorACCBANDSSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorACCBANDSSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorACCBANDS?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorACOSSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorACOSSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorACOS?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorADSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorADSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorAD?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorADDSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorADDSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorADD?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorADOSCSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optFast_Period?: Maybe<Scalars['Float']>;
  optSlow_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorADOSCSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorADOSC?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorADXSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorADXSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorADX?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorADXRSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorADXRSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorADXR?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorAPOSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optFast_Period?: Maybe<Scalars['Float']>;
  optSlow_Period?: Maybe<Scalars['Float']>;
  optMA_Type?: Maybe<MATypes>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorAPOSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorAPO?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorAROONSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorAROONSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorAROON?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorAROONOSCSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorAROONOSCSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorAROONOSC?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorASINSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorASINSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorASIN?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorATANSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorATANSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorATAN?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorATRSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorATRSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorATR?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorAVGDEVSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorAVGDEVSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorAVGDEV?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorAVGPRICESubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorAVGPRICESubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorAVGPRICE?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorBBANDSSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  optDeviations_up?: Maybe<Scalars['Float']>;
  optDeviations_down?: Maybe<Scalars['Float']>;
  optMA_Type?: Maybe<MATypes>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorBBANDSSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorBBANDS?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorBETASubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorBETASubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorBETA?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorBOPSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorBOPSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorBOP?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCCISubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCCISubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCCI?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDL2CROWSSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDL2CROWSSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDL2CROWS?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDL3BLACKCROWSSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDL3BLACKCROWSSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDL3BLACKCROWS?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDL3INSIDESubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDL3INSIDESubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDL3INSIDE?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDL3LINESTRIKESubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDL3LINESTRIKESubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDL3LINESTRIKE?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDL3OUTSIDESubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDL3OUTSIDESubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDL3OUTSIDE?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDL3STARSINSOUTHSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDL3STARSINSOUTHSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDL3STARSINSOUTH?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDL3WHITESOLDIERSSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDL3WHITESOLDIERSSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDL3WHITESOLDIERS?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLABANDONEDBABYSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optPenetration?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLABANDONEDBABYSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLABANDONEDBABY?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLADVANCEBLOCKSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLADVANCEBLOCKSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLADVANCEBLOCK?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLBELTHOLDSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLBELTHOLDSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLBELTHOLD?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLBREAKAWAYSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLBREAKAWAYSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLBREAKAWAY?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLCLOSINGMARUBOZUSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLCLOSINGMARUBOZUSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLCLOSINGMARUBOZU?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLCONCEALBABYSWALLSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLCONCEALBABYSWALLSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLCONCEALBABYSWALL?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLCOUNTERATTACKSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLCOUNTERATTACKSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLCOUNTERATTACK?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLDARKCLOUDCOVERSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optPenetration?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLDARKCLOUDCOVERSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLDARKCLOUDCOVER?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLDOJISubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLDOJISubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLDOJI?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLDOJISTARSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLDOJISTARSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLDOJISTAR?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLDRAGONFLYDOJISubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLDRAGONFLYDOJISubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLDRAGONFLYDOJI?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLENGULFINGSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLENGULFINGSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLENGULFING?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLEVENINGDOJISTARSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optPenetration?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLEVENINGDOJISTARSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLEVENINGDOJISTAR?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLEVENINGSTARSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optPenetration?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLEVENINGSTARSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLEVENINGSTAR?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLGAPSIDESIDEWHITESubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLGAPSIDESIDEWHITESubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLGAPSIDESIDEWHITE?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLGRAVESTONEDOJISubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLGRAVESTONEDOJISubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLGRAVESTONEDOJI?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLHAMMERSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLHAMMERSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLHAMMER?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLHANGINGMANSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLHANGINGMANSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLHANGINGMAN?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLHARAMISubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLHARAMISubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLHARAMI?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLHARAMICROSSSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLHARAMICROSSSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLHARAMICROSS?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLHIGHWAVESubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLHIGHWAVESubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLHIGHWAVE?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLHIKKAKESubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLHIKKAKESubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLHIKKAKE?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLHIKKAKEMODSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLHIKKAKEMODSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLHIKKAKEMOD?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLHOMINGPIGEONSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLHOMINGPIGEONSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLHOMINGPIGEON?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLIDENTICAL3CROWSSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLIDENTICAL3CROWSSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLIDENTICAL3CROWS?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLINNECKSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLINNECKSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLINNECK?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLINVERTEDHAMMERSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLINVERTEDHAMMERSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLINVERTEDHAMMER?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLKICKINGSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLKICKINGSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLKICKING?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLKICKINGBYLENGTHSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLKICKINGBYLENGTHSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLKICKINGBYLENGTH?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLLADDERBOTTOMSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLLADDERBOTTOMSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLLADDERBOTTOM?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLLONGLEGGEDDOJISubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLLONGLEGGEDDOJISubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLLONGLEGGEDDOJI?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLLONGLINESubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLLONGLINESubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLLONGLINE?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLMARUBOZUSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLMARUBOZUSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLMARUBOZU?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLMATCHINGLOWSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLMATCHINGLOWSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLMATCHINGLOW?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLMATHOLDSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optPenetration?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLMATHOLDSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLMATHOLD?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLMORNINGDOJISTARSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optPenetration?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLMORNINGDOJISTARSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLMORNINGDOJISTAR?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLMORNINGSTARSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optPenetration?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLMORNINGSTARSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLMORNINGSTAR?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLONNECKSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLONNECKSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLONNECK?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLPIERCINGSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLPIERCINGSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLPIERCING?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLRICKSHAWMANSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLRICKSHAWMANSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLRICKSHAWMAN?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLRISEFALL3METHODSSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLRISEFALL3METHODSSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLRISEFALL3METHODS?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLSEPARATINGLINESSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLSEPARATINGLINESSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLSEPARATINGLINES?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLSHOOTINGSTARSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLSHOOTINGSTARSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLSHOOTINGSTAR?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLSHORTLINESubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLSHORTLINESubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLSHORTLINE?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLSPINNINGTOPSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLSPINNINGTOPSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLSPINNINGTOP?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLSTALLEDPATTERNSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLSTALLEDPATTERNSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLSTALLEDPATTERN?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLSTICKSANDWICHSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLSTICKSANDWICHSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLSTICKSANDWICH?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLTAKURISubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLTAKURISubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLTAKURI?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLTASUKIGAPSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLTASUKIGAPSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLTASUKIGAP?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLTHRUSTINGSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLTHRUSTINGSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLTHRUSTING?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLTRISTARSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLTRISTARSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLTRISTAR?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLUNIQUE3RIVERSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLUNIQUE3RIVERSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLUNIQUE3RIVER?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLUPSIDEGAP2CROWSSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLUPSIDEGAP2CROWSSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLUPSIDEGAP2CROWS?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCDLXSIDEGAP3METHODSSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCDLXSIDEGAP3METHODSSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCDLXSIDEGAP3METHODS?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCEILSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCEILSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCEIL?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCMOSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCMOSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCMO?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCORRELSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCORRELSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCORREL?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorCOSHSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorCOSHSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorCOSH?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorDEMASubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorDEMASubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorDEMA?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorDIVSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorDIVSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorDIV?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorEXPSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorEXPSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorEXP?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorFLOORSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorFLOORSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorFLOOR?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorHT_DCPERIODSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorHT_DCPERIODSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorHT_DCPERIOD?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorHT_DCPHASESubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorHT_DCPHASESubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorHT_DCPHASE?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorHT_PHASORSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorHT_PHASORSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorHT_PHASOR?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorHT_SINESubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorHT_SINESubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorHT_SINE?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorHT_TRENDLINESubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorHT_TRENDLINESubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorHT_TRENDLINE?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorHT_TRENDMODESubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorHT_TRENDMODESubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorHT_TRENDMODE?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorIMISubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorIMISubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorIMI?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorKAMASubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorKAMASubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorKAMA?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorLINEARREGSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorLINEARREGSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorLINEARREG?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorLINEARREG_ANGLESubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorLINEARREG_ANGLESubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorLINEARREG_ANGLE?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorLINEARREG_INTERCEPTSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorLINEARREG_INTERCEPTSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorLINEARREG_INTERCEPT?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorLINEARREG_SLOPESubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorLINEARREG_SLOPESubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorLINEARREG_SLOPE?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorLNSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorLNSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorLN?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorLOG10SubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorLOG10Subscription = (
  { __typename?: 'Subscription' }
  & { IndicatorLOG10?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorMACDSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optFast_Period?: Maybe<Scalars['Float']>;
  optSlow_Period?: Maybe<Scalars['Float']>;
  optSignal_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorMACDSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorMACD?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorMACDEXTSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optFast_Period?: Maybe<Scalars['Float']>;
  optFast_MA?: Maybe<MATypes>;
  optSlow_Period?: Maybe<Scalars['Float']>;
  optSlow_MA?: Maybe<MATypes>;
  optSignal_Period?: Maybe<Scalars['Float']>;
  optSignal_MA?: Maybe<MATypes>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorMACDEXTSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorMACDEXT?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorMACDFIXSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optSignal_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorMACDFIXSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorMACDFIX?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorMAMASubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optFast_Limit?: Maybe<Scalars['Float']>;
  optSlow_Limit?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorMAMASubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorMAMA?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorMAVPSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optMinimum_Period?: Maybe<Scalars['Float']>;
  optMaximum_Period?: Maybe<Scalars['Float']>;
  optMA_Type?: Maybe<MATypes>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorMAVPSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorMAVP?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorMAXSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorMAXSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorMAX?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorMAXINDEXSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorMAXINDEXSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorMAXINDEX?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorMEDPRICESubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorMEDPRICESubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorMEDPRICE?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorMFISubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorMFISubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorMFI?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorMIDPOINTSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorMIDPOINTSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorMIDPOINT?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorMIDPRICESubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorMIDPRICESubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorMIDPRICE?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorMININDEXSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorMININDEXSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorMININDEX?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorMINMAXSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorMINMAXSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorMINMAX?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorMINMAXINDEXSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorMINMAXINDEXSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorMINMAXINDEX?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorMINUS_DISubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorMINUS_DISubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorMINUS_DI?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorMINUS_DMSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorMINUS_DMSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorMINUS_DM?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorMOMSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorMOMSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorMOM?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorMULTSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorMULTSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorMULT?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorNATRSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorNATRSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorNATR?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorOBVSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorOBVSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorOBV?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorPLUS_DISubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorPLUS_DISubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorPLUS_DI?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorPLUS_DMSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorPLUS_DMSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorPLUS_DM?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorPPOSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optFast_Period?: Maybe<Scalars['Float']>;
  optSlow_Period?: Maybe<Scalars['Float']>;
  optMA_Type?: Maybe<MATypes>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorPPOSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorPPO?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorROCSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorROCSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorROC?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorROCPSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorROCPSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorROCP?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorROCRSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorROCRSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorROCR?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorROCR100SubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorROCR100Subscription = (
  { __typename?: 'Subscription' }
  & { IndicatorROCR100?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorSARSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optAcceleration_Factor?: Maybe<Scalars['Float']>;
  optAF_Maximum?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorSARSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorSAR?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorSAREXTSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optStart_Value?: Maybe<Scalars['Float']>;
  optOffset_on_Reverse?: Maybe<Scalars['Float']>;
  optAF_Init_Long?: Maybe<Scalars['Float']>;
  optAF_Long?: Maybe<Scalars['Float']>;
  optAF_Max_Long?: Maybe<Scalars['Float']>;
  optAF_Init_Short?: Maybe<Scalars['Float']>;
  optAF_Short?: Maybe<Scalars['Float']>;
  optAF_Max_Short?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorSAREXTSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorSAREXT?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorSINHSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorSINHSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorSINH?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorSMASubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorSMASubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorSMA?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorSQRTSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorSQRTSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorSQRT?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorSTDDEVSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  optDeviations?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorSTDDEVSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorSTDDEV?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorSTOCHSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optFastK_Period?: Maybe<Scalars['Float']>;
  optSlowK_Period?: Maybe<Scalars['Float']>;
  optSlowK_MA?: Maybe<MATypes>;
  optSlowD_Period?: Maybe<Scalars['Float']>;
  optSlowD_MA?: Maybe<MATypes>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorSTOCHSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorSTOCH?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorSTOCHFSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optFastK_Period?: Maybe<Scalars['Float']>;
  optFastD_Period?: Maybe<Scalars['Float']>;
  optFastD_MA?: Maybe<MATypes>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorSTOCHFSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorSTOCHF?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorSTOCHRSISubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  optFastK_Period?: Maybe<Scalars['Float']>;
  optFastD_Period?: Maybe<Scalars['Float']>;
  optFastD_MA?: Maybe<MATypes>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorSTOCHRSISubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorSTOCHRSI?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorSUBSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorSUBSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorSUB?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorSUMSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorSUMSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorSUM?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorT3SubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  optVolume_Factor?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorT3Subscription = (
  { __typename?: 'Subscription' }
  & { IndicatorT3?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorTANHSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorTANHSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorTANH?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorTEMASubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorTEMASubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorTEMA?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorTRANGESubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorTRANGESubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorTRANGE?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorTRIMASubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorTRIMASubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorTRIMA?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorTRIXSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorTRIXSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorTRIX?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorTSFSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorTSFSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorTSF?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorTYPPRICESubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorTYPPRICESubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorTYPPRICE?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorULTOSCSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optFirst_Period?: Maybe<Scalars['Float']>;
  optSecond_Period?: Maybe<Scalars['Float']>;
  optThird_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorULTOSCSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorULTOSC?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorVARSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  optDeviations?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorVARSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorVAR?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorWCLPRICESubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorWCLPRICESubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorWCLPRICE?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type IndicatorWILLRSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
  bars: Scalars['Int'];
  optTime_Period?: Maybe<Scalars['Float']>;
  startIdx?: Maybe<Scalars['Int']>;
  endIdx?: Maybe<Scalars['Int']>;
}>;


export type IndicatorWILLRSubscription = (
  { __typename?: 'Subscription' }
  & { IndicatorWILLR?: Maybe<(
    { __typename?: 'IndicatorEvent' }
    & Pick<IndicatorEvent, 'i' | 's' | 'e' | 'o' | 'c' | 'h' | 'l' | 'v'>
  )> }
);

export type StockAggregationMinuteSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
}>;


export type StockAggregationMinuteSubscription = (
  { __typename?: 'Subscription' }
  & { StockAggregationMinute?: Maybe<(
    { __typename?: 'IAggregateStockEvent' }
    & Pick<IAggregateStockEvent, 'ev' | 'sym' | 'v' | 'av' | 'op' | 'vw' | 'o' | 'c' | 'h' | 'l' | 'a' | 's' | 'e'>
  )> }
);

export type StockAggregationSecondSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
}>;


export type StockAggregationSecondSubscription = (
  { __typename?: 'Subscription' }
  & { StockAggregationSecond?: Maybe<(
    { __typename?: 'IAggregateStockEvent' }
    & Pick<IAggregateStockEvent, 'ev' | 'sym' | 'v' | 'av' | 'op' | 'vw' | 'o' | 'c' | 'h' | 'l' | 'a' | 's' | 'e'>
  )> }
);

export type StockQuoteSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
}>;


export type StockQuoteSubscription = (
  { __typename?: 'Subscription' }
  & { StockQuote?: Maybe<(
    { __typename?: 'IQuoteStockEvent' }
    & Pick<IQuoteStockEvent, 'ev' | 'sym' | 'bx' | 'bp' | 'bs' | 'ax' | 'ap' | 'as' | 'c' | 't'>
  )> }
);

export type StockTradeSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
}>;


export type StockTradeSubscription = (
  { __typename?: 'Subscription' }
  & { StockTrade?: Maybe<(
    { __typename?: 'ITradeStockEvent' }
    & Pick<ITradeStockEvent, 'ev' | 'sym' | 'x' | 'i' | 'z' | 'p' | 's' | 'c' | 't'>
  )> }
);

export type StrategyAROONSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
}>;


export type StrategyAROONSubscription = (
  { __typename?: 'Subscription' }
  & Pick<Subscription, 'StrategyAROON'>
);

export type StrategyExampleSubscriptionVariables = Exact<{
  ticker: Scalars['String'];
  channel: PolygonEventChannel;
}>;


export type StrategyExampleSubscription = (
  { __typename?: 'Subscription' }
  & Pick<Subscription, 'StrategyExample'>
);


export const IntrospectSubscriptionsDocument = gql`
    query IntrospectSubscriptions {
  __schema {
    subscriptionType {
      fields {
        name
        description
        args {
          name
          type {
            ofType {
              name
              kind
              enumValues {
                name
              }
              inputFields {
                name
                type {
                  ofType {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useIntrospectSubscriptionsQuery__
 *
 * To run a query within a Vue component, call `useIntrospectSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntrospectSubscriptionsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useIntrospectSubscriptionsQuery();
 */
export function useIntrospectSubscriptionsQuery(options: VueApolloComposable.UseQueryOptions<IntrospectSubscriptionsQuery, IntrospectSubscriptionsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<IntrospectSubscriptionsQuery, IntrospectSubscriptionsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<IntrospectSubscriptionsQuery, IntrospectSubscriptionsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<IntrospectSubscriptionsQuery, IntrospectSubscriptionsQueryVariables>(IntrospectSubscriptionsDocument, {}, options);
}
export type IntrospectSubscriptionsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<IntrospectSubscriptionsQuery, IntrospectSubscriptionsQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($email: String!) {
  CreateUser(email: $email) {
    email
    _id
  }
}
    `;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateUserMutation({
 *   variables: {
 *     email: // value for 'email'
 *   },
 * });
 */
export function useCreateUserMutation(options: VueApolloComposable.UseMutationOptions<CreateUserMutation, CreateUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateUserMutation, CreateUserMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
}
export type CreateUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($email: String!) {
  DeleteUser(email: $email) {
    email
    _id
  }
}
    `;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteUserMutation({
 *   variables: {
 *     email: // value for 'email'
 *   },
 * });
 */
export function useDeleteUserMutation(options: VueApolloComposable.UseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
}
export type DeleteUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteUserMutation, DeleteUserMutationVariables>;
export const MergeUserDocument = gql`
    mutation MergeUser($email: String!) {
  MergeUser(email: $email) {
    email
    _id
  }
}
    `;

/**
 * __useMergeUserMutation__
 *
 * To run a mutation, you first call `useMergeUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useMergeUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useMergeUserMutation({
 *   variables: {
 *     email: // value for 'email'
 *   },
 * });
 */
export function useMergeUserMutation(options: VueApolloComposable.UseMutationOptions<MergeUserMutation, MergeUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<MergeUserMutation, MergeUserMutationVariables>>) {
  return VueApolloComposable.useMutation<MergeUserMutation, MergeUserMutationVariables>(MergeUserDocument, options);
}
export type MergeUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<MergeUserMutation, MergeUserMutationVariables>;
export const UnsubscribePolygonStreamsDocument = gql`
    mutation UnsubscribePolygonStreams {
  UnsubscribePolygonStreams
}
    `;

/**
 * __useUnsubscribePolygonStreamsMutation__
 *
 * To run a mutation, you first call `useUnsubscribePolygonStreamsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribePolygonStreamsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUnsubscribePolygonStreamsMutation();
 */
export function useUnsubscribePolygonStreamsMutation(options: VueApolloComposable.UseMutationOptions<UnsubscribePolygonStreamsMutation, UnsubscribePolygonStreamsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UnsubscribePolygonStreamsMutation, UnsubscribePolygonStreamsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UnsubscribePolygonStreamsMutation, UnsubscribePolygonStreamsMutationVariables>(UnsubscribePolygonStreamsDocument, options);
}
export type UnsubscribePolygonStreamsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UnsubscribePolygonStreamsMutation, UnsubscribePolygonStreamsMutationVariables>;
export const CryptoSnapshotDocument = gql`
    query CryptoSnapshot {
  CryptoSnapshot {
    status
    tickers {
      day {
        c
        h
        l
        o
        v
        vw
      }
      lastTrade {
        c
        i
        p
        s
        t
        x
      }
      min {
        c
        h
        l
        o
        v
        vw
      }
      prevDay {
        c
        h
        l
        o
        v
        vw
      }
      ticker
      todaysChange
      todaysChangePerc
      updated
    }
  }
}
    `;

/**
 * __useCryptoSnapshotQuery__
 *
 * To run a query within a Vue component, call `useCryptoSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useCryptoSnapshotQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCryptoSnapshotQuery();
 */
export function useCryptoSnapshotQuery(options: VueApolloComposable.UseQueryOptions<CryptoSnapshotQuery, CryptoSnapshotQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CryptoSnapshotQuery, CryptoSnapshotQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CryptoSnapshotQuery, CryptoSnapshotQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CryptoSnapshotQuery, CryptoSnapshotQueryVariables>(CryptoSnapshotDocument, {}, options);
}
export type CryptoSnapshotQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CryptoSnapshotQuery, CryptoSnapshotQueryVariables>;
export const CryptoTradeHistoricalDocument = gql`
    query CryptoTradeHistorical($pair: _CurrencyPairInput!, $date: _Neo4jDateInput!, $offset: Int, $limit: Int) {
  CryptoTradeHistorical(pair: $pair, date: $date, offset: $offset, limit: $limit) {
    p
    s
    x
    c
    t
  }
}
    `;

/**
 * __useCryptoTradeHistoricalQuery__
 *
 * To run a query within a Vue component, call `useCryptoTradeHistoricalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCryptoTradeHistoricalQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCryptoTradeHistoricalQuery({
 *   pair: // value for 'pair'
 *   date: // value for 'date'
 *   offset: // value for 'offset'
 *   limit: // value for 'limit'
 * });
 */
export function useCryptoTradeHistoricalQuery(variables: CryptoTradeHistoricalQueryVariables | VueCompositionApi.Ref<CryptoTradeHistoricalQueryVariables> | ReactiveFunction<CryptoTradeHistoricalQueryVariables>, options: VueApolloComposable.UseQueryOptions<CryptoTradeHistoricalQuery, CryptoTradeHistoricalQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CryptoTradeHistoricalQuery, CryptoTradeHistoricalQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CryptoTradeHistoricalQuery, CryptoTradeHistoricalQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CryptoTradeHistoricalQuery, CryptoTradeHistoricalQueryVariables>(CryptoTradeHistoricalDocument, variables, options);
}
export type CryptoTradeHistoricalQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CryptoTradeHistoricalQuery, CryptoTradeHistoricalQueryVariables>;
export const ForexSnapshotDocument = gql`
    query ForexSnapshot {
  ForexSnapshot {
    status
    tickers {
      day {
        c
        h
        l
        o
        v
      }
      lastQuote {
        a
        b
        t
        x
      }
      min {
        c
        h
        l
        o
        v
      }
      prev {
        c
        h
        l
        o
        v
        vw
      }
      ticker
      todaysChange
      todaysChangePerc
      updated
    }
  }
}
    `;

/**
 * __useForexSnapshotQuery__
 *
 * To run a query within a Vue component, call `useForexSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useForexSnapshotQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useForexSnapshotQuery();
 */
export function useForexSnapshotQuery(options: VueApolloComposable.UseQueryOptions<ForexSnapshotQuery, ForexSnapshotQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ForexSnapshotQuery, ForexSnapshotQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ForexSnapshotQuery, ForexSnapshotQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ForexSnapshotQuery, ForexSnapshotQueryVariables>(ForexSnapshotDocument, {}, options);
}
export type ForexSnapshotQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ForexSnapshotQuery, ForexSnapshotQueryVariables>;
export const MarketDocument = gql`
    query Market {
  Market {
    market
    serverTime
    exchanges {
      nyse
      nasdaq
      otc
    }
    currencies {
      fx
      crypto
    }
  }
}
    `;

/**
 * __useMarketQuery__
 *
 * To run a query within a Vue component, call `useMarketQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMarketQuery();
 */
export function useMarketQuery(options: VueApolloComposable.UseQueryOptions<MarketQuery, MarketQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MarketQuery, MarketQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MarketQuery, MarketQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MarketQuery, MarketQueryVariables>(MarketDocument, {}, options);
}
export type MarketQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MarketQuery, MarketQueryVariables>;
export const StockAggregatesDocument = gql`
    query StockAggregates($ticker: String!, $multiplier: Int!, $timespan: AggregateTimeSpan!, $from: _Neo4jDateTimeInput!, $to: _Neo4jDateTimeInput!, $unadjusted: Boolean, $limit: Int) {
  StockAggregates(
    ticker: $ticker
    multiplier: $multiplier
    timespan: $timespan
    from: $from
    to: $to
    unadjusted: $unadjusted
    limit: $limit
  ) {
    o
    h
    l
    c
    v
    vw
    t
    tf
    n
  }
}
    `;

/**
 * __useStockAggregatesQuery__
 *
 * To run a query within a Vue component, call `useStockAggregatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockAggregatesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useStockAggregatesQuery({
 *   ticker: // value for 'ticker'
 *   multiplier: // value for 'multiplier'
 *   timespan: // value for 'timespan'
 *   from: // value for 'from'
 *   to: // value for 'to'
 *   unadjusted: // value for 'unadjusted'
 *   limit: // value for 'limit'
 * });
 */
export function useStockAggregatesQuery(variables: StockAggregatesQueryVariables | VueCompositionApi.Ref<StockAggregatesQueryVariables> | ReactiveFunction<StockAggregatesQueryVariables>, options: VueApolloComposable.UseQueryOptions<StockAggregatesQuery, StockAggregatesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<StockAggregatesQuery, StockAggregatesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<StockAggregatesQuery, StockAggregatesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<StockAggregatesQuery, StockAggregatesQueryVariables>(StockAggregatesDocument, variables, options);
}
export type StockAggregatesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<StockAggregatesQuery, StockAggregatesQueryVariables>;
export const StockQuoteHistoricalDocument = gql`
    query StockQuoteHistorical($ticker: String!, $date: _Neo4jDateInput!, $reverse: Boolean, $limit: Int) {
  StockQuoteHistorical(
    ticker: $ticker
    date: $date
    reverse: $reverse
    limit: $limit
  ) {
    t
    y
    q
    x
    X
    s
    S
    p
    P
    z
    c
  }
}
    `;

/**
 * __useStockQuoteHistoricalQuery__
 *
 * To run a query within a Vue component, call `useStockQuoteHistoricalQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockQuoteHistoricalQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useStockQuoteHistoricalQuery({
 *   ticker: // value for 'ticker'
 *   date: // value for 'date'
 *   reverse: // value for 'reverse'
 *   limit: // value for 'limit'
 * });
 */
export function useStockQuoteHistoricalQuery(variables: StockQuoteHistoricalQueryVariables | VueCompositionApi.Ref<StockQuoteHistoricalQueryVariables> | ReactiveFunction<StockQuoteHistoricalQueryVariables>, options: VueApolloComposable.UseQueryOptions<StockQuoteHistoricalQuery, StockQuoteHistoricalQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<StockQuoteHistoricalQuery, StockQuoteHistoricalQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<StockQuoteHistoricalQuery, StockQuoteHistoricalQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<StockQuoteHistoricalQuery, StockQuoteHistoricalQueryVariables>(StockQuoteHistoricalDocument, variables, options);
}
export type StockQuoteHistoricalQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<StockQuoteHistoricalQuery, StockQuoteHistoricalQueryVariables>;
export const StockSnapshotDocument = gql`
    query StockSnapshot {
  StockSnapshot {
    status
    count
    tickers {
      day {
        c
        h
        l
        o
        v
        vw
      }
      lastQuote {
        P
        S
        p
        s
        t
      }
      lastTrade {
        c
        i
        p
        s
        t
        x
      }
      min {
        av
        c
        h
        l
        o
        v
        vw
      }
      prev {
        c
        h
        l
        o
        v
        vw
      }
      ticker
      todaysChange
      todaysChangePerc
      updated
    }
  }
}
    `;

/**
 * __useStockSnapshotQuery__
 *
 * To run a query within a Vue component, call `useStockSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockSnapshotQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useStockSnapshotQuery();
 */
export function useStockSnapshotQuery(options: VueApolloComposable.UseQueryOptions<StockSnapshotQuery, StockSnapshotQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<StockSnapshotQuery, StockSnapshotQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<StockSnapshotQuery, StockSnapshotQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<StockSnapshotQuery, StockSnapshotQueryVariables>(StockSnapshotDocument, {}, options);
}
export type StockSnapshotQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<StockSnapshotQuery, StockSnapshotQueryVariables>;
export const StockTradeHistoricalDocument = gql`
    query StockTradeHistorical($ticker: String!, $date: _Neo4jDateInput!, $reverse: Boolean, $limit: Int) {
  StockTradeHistorical(
    ticker: $ticker
    date: $date
    reverse: $reverse
    limit: $limit
  ) {
    t
    y
    q
    i
    x
    s
    p
    c
    z
    e
  }
}
    `;

/**
 * __useStockTradeHistoricalQuery__
 *
 * To run a query within a Vue component, call `useStockTradeHistoricalQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockTradeHistoricalQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useStockTradeHistoricalQuery({
 *   ticker: // value for 'ticker'
 *   date: // value for 'date'
 *   reverse: // value for 'reverse'
 *   limit: // value for 'limit'
 * });
 */
export function useStockTradeHistoricalQuery(variables: StockTradeHistoricalQueryVariables | VueCompositionApi.Ref<StockTradeHistoricalQueryVariables> | ReactiveFunction<StockTradeHistoricalQueryVariables>, options: VueApolloComposable.UseQueryOptions<StockTradeHistoricalQuery, StockTradeHistoricalQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<StockTradeHistoricalQuery, StockTradeHistoricalQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<StockTradeHistoricalQuery, StockTradeHistoricalQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<StockTradeHistoricalQuery, StockTradeHistoricalQueryVariables>(StockTradeHistoricalDocument, variables, options);
}
export type StockTradeHistoricalQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<StockTradeHistoricalQuery, StockTradeHistoricalQueryVariables>;
export const TickerDocument = gql`
    query Ticker($market: String, $page: Int, $search: String) {
  Ticker(market: $market, page: $page, search: $search) {
    ticker
    name
    market
    locale
    currency
    active
    primaryExch
    type
  }
}
    `;

/**
 * __useTickerQuery__
 *
 * To run a query within a Vue component, call `useTickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useTickerQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTickerQuery({
 *   market: // value for 'market'
 *   page: // value for 'page'
 *   search: // value for 'search'
 * });
 */
export function useTickerQuery(variables: TickerQueryVariables | VueCompositionApi.Ref<TickerQueryVariables> | ReactiveFunction<TickerQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<TickerQuery, TickerQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TickerQuery, TickerQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TickerQuery, TickerQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TickerQuery, TickerQueryVariables>(TickerDocument, variables, options);
}
export type TickerQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TickerQuery, TickerQueryVariables>;
export const TickerDetailDocument = gql`
    query TickerDetail($stocksTicker: String) {
  TickerDetail(stocksTicker: $stocksTicker) {
    logo
    listdate
    cik
    bloomberg
    lei
    sic
    country
    industry
    sector
    marketcap
    employees
    phone
    ceo
    url
    description
    exchange
    name
    symbol
    exchangeSymbol
    hq_address
    hq_state
    hq_country
    type
    updated
    tags
    similar
    active
  }
}
    `;

/**
 * __useTickerDetailQuery__
 *
 * To run a query within a Vue component, call `useTickerDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTickerDetailQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTickerDetailQuery({
 *   stocksTicker: // value for 'stocksTicker'
 * });
 */
export function useTickerDetailQuery(variables: TickerDetailQueryVariables | VueCompositionApi.Ref<TickerDetailQueryVariables> | ReactiveFunction<TickerDetailQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<TickerDetailQuery, TickerDetailQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TickerDetailQuery, TickerDetailQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TickerDetailQuery, TickerDetailQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TickerDetailQuery, TickerDetailQueryVariables>(TickerDetailDocument, variables, options);
}
export type TickerDetailQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TickerDetailQuery, TickerDetailQueryVariables>;
export const TickerNewsDocument = gql`
    query TickerNews($stocksTicker: String!, $page: Int) {
  TickerNews(stocksTicker: $stocksTicker, page: $page) {
    symbols
    timestamp
    title
    url
    source
    summary
    image
    keywords
  }
}
    `;

/**
 * __useTickerNewsQuery__
 *
 * To run a query within a Vue component, call `useTickerNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTickerNewsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTickerNewsQuery({
 *   stocksTicker: // value for 'stocksTicker'
 *   page: // value for 'page'
 * });
 */
export function useTickerNewsQuery(variables: TickerNewsQueryVariables | VueCompositionApi.Ref<TickerNewsQueryVariables> | ReactiveFunction<TickerNewsQueryVariables>, options: VueApolloComposable.UseQueryOptions<TickerNewsQuery, TickerNewsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TickerNewsQuery, TickerNewsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TickerNewsQuery, TickerNewsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TickerNewsQuery, TickerNewsQueryVariables>(TickerNewsDocument, variables, options);
}
export type TickerNewsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TickerNewsQuery, TickerNewsQueryVariables>;
export const UserDocument = gql`
    query User($email: String, $_id: String, $first: Int, $offset: Int, $orderBy: [_UserOrdering], $filter: _UserFilter) {
  User(
    email: $email
    _id: $_id
    first: $first
    offset: $offset
    orderBy: $orderBy
    filter: $filter
  ) {
    email
    _id
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a Vue component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUserQuery({
 *   email: // value for 'email'
 *   _id: // value for '_id'
 *   first: // value for 'first'
 *   offset: // value for 'offset'
 *   orderBy: // value for 'orderBy'
 *   filter: // value for 'filter'
 * });
 */
export function useUserQuery(variables: UserQueryVariables | VueCompositionApi.Ref<UserQueryVariables> | ReactiveFunction<UserQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<UserQuery, UserQueryVariables>(UserDocument, variables, options);
}
export type UserQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<UserQuery, UserQueryVariables>;
export const CryptoAggregationDocument = gql`
    subscription CryptoAggregation($pair: _CurrencyPairInput!) {
  CryptoAggregation(pair: $pair) {
    ev
    pair
    o
    ox
    h
    hx
    l
    lx
    c
    cx
    v
    s
    e
  }
}
    `;

/**
 * __useCryptoAggregationSubscription__
 *
 * To run a query within a Vue component, call `useCryptoAggregationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCryptoAggregationSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useCryptoAggregationSubscription({
 *   pair: // value for 'pair'
 * });
 */
export function useCryptoAggregationSubscription(variables: CryptoAggregationSubscriptionVariables | VueCompositionApi.Ref<CryptoAggregationSubscriptionVariables> | ReactiveFunction<CryptoAggregationSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<CryptoAggregationSubscription, CryptoAggregationSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<CryptoAggregationSubscription, CryptoAggregationSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<CryptoAggregationSubscription, CryptoAggregationSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<CryptoAggregationSubscription, CryptoAggregationSubscriptionVariables>(CryptoAggregationDocument, variables, options);
}
export type CryptoAggregationSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<CryptoAggregationSubscription, CryptoAggregationSubscriptionVariables>;
export const CryptoQuoteDocument = gql`
    subscription CryptoQuote($pair: _CurrencyPairInput!) {
  CryptoQuote(pair: $pair) {
    ev
    pair
    lp
    ls
    bp
    bs
    ap
    as
    t
    x
    r
  }
}
    `;

/**
 * __useCryptoQuoteSubscription__
 *
 * To run a query within a Vue component, call `useCryptoQuoteSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCryptoQuoteSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useCryptoQuoteSubscription({
 *   pair: // value for 'pair'
 * });
 */
export function useCryptoQuoteSubscription(variables: CryptoQuoteSubscriptionVariables | VueCompositionApi.Ref<CryptoQuoteSubscriptionVariables> | ReactiveFunction<CryptoQuoteSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<CryptoQuoteSubscription, CryptoQuoteSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<CryptoQuoteSubscription, CryptoQuoteSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<CryptoQuoteSubscription, CryptoQuoteSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<CryptoQuoteSubscription, CryptoQuoteSubscriptionVariables>(CryptoQuoteDocument, variables, options);
}
export type CryptoQuoteSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<CryptoQuoteSubscription, CryptoQuoteSubscriptionVariables>;
export const CryptoTradeDocument = gql`
    subscription CryptoTrade($pair: _CurrencyPairInput!) {
  CryptoTrade(pair: $pair) {
    ev
    pair
    p
    t
    s
    c
    i
    x
    r
  }
}
    `;

/**
 * __useCryptoTradeSubscription__
 *
 * To run a query within a Vue component, call `useCryptoTradeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCryptoTradeSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useCryptoTradeSubscription({
 *   pair: // value for 'pair'
 * });
 */
export function useCryptoTradeSubscription(variables: CryptoTradeSubscriptionVariables | VueCompositionApi.Ref<CryptoTradeSubscriptionVariables> | ReactiveFunction<CryptoTradeSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<CryptoTradeSubscription, CryptoTradeSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<CryptoTradeSubscription, CryptoTradeSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<CryptoTradeSubscription, CryptoTradeSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<CryptoTradeSubscription, CryptoTradeSubscriptionVariables>(CryptoTradeDocument, variables, options);
}
export type CryptoTradeSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<CryptoTradeSubscription, CryptoTradeSubscriptionVariables>;
export const ForexBondsDocument = gql`
    subscription ForexBonds($pair: _CurrencyPairInput!) {
  ForexBonds(pair: $pair) {
    ev
    p
    x
    a
    b
    t
  }
}
    `;

/**
 * __useForexBondsSubscription__
 *
 * To run a query within a Vue component, call `useForexBondsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useForexBondsSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useForexBondsSubscription({
 *   pair: // value for 'pair'
 * });
 */
export function useForexBondsSubscription(variables: ForexBondsSubscriptionVariables | VueCompositionApi.Ref<ForexBondsSubscriptionVariables> | ReactiveFunction<ForexBondsSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<ForexBondsSubscription, ForexBondsSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<ForexBondsSubscription, ForexBondsSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<ForexBondsSubscription, ForexBondsSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<ForexBondsSubscription, ForexBondsSubscriptionVariables>(ForexBondsDocument, variables, options);
}
export type ForexBondsSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<ForexBondsSubscription, ForexBondsSubscriptionVariables>;
export const ForexCommoditiesDocument = gql`
    subscription ForexCommodities($pair: _CurrencyPairInput!) {
  ForexCommodities(pair: $pair) {
    ev
    p
    x
    a
    b
    t
  }
}
    `;

/**
 * __useForexCommoditiesSubscription__
 *
 * To run a query within a Vue component, call `useForexCommoditiesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useForexCommoditiesSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useForexCommoditiesSubscription({
 *   pair: // value for 'pair'
 * });
 */
export function useForexCommoditiesSubscription(variables: ForexCommoditiesSubscriptionVariables | VueCompositionApi.Ref<ForexCommoditiesSubscriptionVariables> | ReactiveFunction<ForexCommoditiesSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<ForexCommoditiesSubscription, ForexCommoditiesSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<ForexCommoditiesSubscription, ForexCommoditiesSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<ForexCommoditiesSubscription, ForexCommoditiesSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<ForexCommoditiesSubscription, ForexCommoditiesSubscriptionVariables>(ForexCommoditiesDocument, variables, options);
}
export type ForexCommoditiesSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<ForexCommoditiesSubscription, ForexCommoditiesSubscriptionVariables>;
export const ForexCurrencyDocument = gql`
    subscription ForexCurrency($pair: _CurrencyPairInput!) {
  ForexCurrency(pair: $pair) {
    ev
    p
    x
    a
    b
    t
  }
}
    `;

/**
 * __useForexCurrencySubscription__
 *
 * To run a query within a Vue component, call `useForexCurrencySubscription` and pass it any options that fit your needs.
 * When your component renders, `useForexCurrencySubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useForexCurrencySubscription({
 *   pair: // value for 'pair'
 * });
 */
export function useForexCurrencySubscription(variables: ForexCurrencySubscriptionVariables | VueCompositionApi.Ref<ForexCurrencySubscriptionVariables> | ReactiveFunction<ForexCurrencySubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<ForexCurrencySubscription, ForexCurrencySubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<ForexCurrencySubscription, ForexCurrencySubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<ForexCurrencySubscription, ForexCurrencySubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<ForexCurrencySubscription, ForexCurrencySubscriptionVariables>(ForexCurrencyDocument, variables, options);
}
export type ForexCurrencySubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<ForexCurrencySubscription, ForexCurrencySubscriptionVariables>;
export const ForexCurrencyAggregationMinuteDocument = gql`
    subscription ForexCurrencyAggregationMinute($pair: _CurrencyPairInput!) {
  ForexCurrencyAggregationMinute(pair: $pair) {
    ev
    pair
    o
    c
    h
    l
    v
    s
  }
}
    `;

/**
 * __useForexCurrencyAggregationMinuteSubscription__
 *
 * To run a query within a Vue component, call `useForexCurrencyAggregationMinuteSubscription` and pass it any options that fit your needs.
 * When your component renders, `useForexCurrencyAggregationMinuteSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useForexCurrencyAggregationMinuteSubscription({
 *   pair: // value for 'pair'
 * });
 */
export function useForexCurrencyAggregationMinuteSubscription(variables: ForexCurrencyAggregationMinuteSubscriptionVariables | VueCompositionApi.Ref<ForexCurrencyAggregationMinuteSubscriptionVariables> | ReactiveFunction<ForexCurrencyAggregationMinuteSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<ForexCurrencyAggregationMinuteSubscription, ForexCurrencyAggregationMinuteSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<ForexCurrencyAggregationMinuteSubscription, ForexCurrencyAggregationMinuteSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<ForexCurrencyAggregationMinuteSubscription, ForexCurrencyAggregationMinuteSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<ForexCurrencyAggregationMinuteSubscription, ForexCurrencyAggregationMinuteSubscriptionVariables>(ForexCurrencyAggregationMinuteDocument, variables, options);
}
export type ForexCurrencyAggregationMinuteSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<ForexCurrencyAggregationMinuteSubscription, ForexCurrencyAggregationMinuteSubscriptionVariables>;
export const ForexMetalsDocument = gql`
    subscription ForexMetals($pair: _CurrencyPairInput!) {
  ForexMetals(pair: $pair) {
    ev
    p
    x
    a
    b
    t
  }
}
    `;

/**
 * __useForexMetalsSubscription__
 *
 * To run a query within a Vue component, call `useForexMetalsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useForexMetalsSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useForexMetalsSubscription({
 *   pair: // value for 'pair'
 * });
 */
export function useForexMetalsSubscription(variables: ForexMetalsSubscriptionVariables | VueCompositionApi.Ref<ForexMetalsSubscriptionVariables> | ReactiveFunction<ForexMetalsSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<ForexMetalsSubscription, ForexMetalsSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<ForexMetalsSubscription, ForexMetalsSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<ForexMetalsSubscription, ForexMetalsSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<ForexMetalsSubscription, ForexMetalsSubscriptionVariables>(ForexMetalsDocument, variables, options);
}
export type ForexMetalsSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<ForexMetalsSubscription, ForexMetalsSubscriptionVariables>;
export const IndicatorACCBANDSDocument = gql`
    subscription IndicatorACCBANDS($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorACCBANDS(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorACCBANDSSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorACCBANDSSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorACCBANDSSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorACCBANDSSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorACCBANDSSubscription(variables: IndicatorACCBANDSSubscriptionVariables | VueCompositionApi.Ref<IndicatorACCBANDSSubscriptionVariables> | ReactiveFunction<IndicatorACCBANDSSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorACCBANDSSubscription, IndicatorACCBANDSSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorACCBANDSSubscription, IndicatorACCBANDSSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorACCBANDSSubscription, IndicatorACCBANDSSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorACCBANDSSubscription, IndicatorACCBANDSSubscriptionVariables>(IndicatorACCBANDSDocument, variables, options);
}
export type IndicatorACCBANDSSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorACCBANDSSubscription, IndicatorACCBANDSSubscriptionVariables>;
export const IndicatorACOSDocument = gql`
    subscription IndicatorACOS($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorACOS(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorACOSSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorACOSSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorACOSSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorACOSSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorACOSSubscription(variables: IndicatorACOSSubscriptionVariables | VueCompositionApi.Ref<IndicatorACOSSubscriptionVariables> | ReactiveFunction<IndicatorACOSSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorACOSSubscription, IndicatorACOSSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorACOSSubscription, IndicatorACOSSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorACOSSubscription, IndicatorACOSSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorACOSSubscription, IndicatorACOSSubscriptionVariables>(IndicatorACOSDocument, variables, options);
}
export type IndicatorACOSSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorACOSSubscription, IndicatorACOSSubscriptionVariables>;
export const IndicatorADDocument = gql`
    subscription IndicatorAD($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorAD(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorADSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorADSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorADSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorADSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorADSubscription(variables: IndicatorADSubscriptionVariables | VueCompositionApi.Ref<IndicatorADSubscriptionVariables> | ReactiveFunction<IndicatorADSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorADSubscription, IndicatorADSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorADSubscription, IndicatorADSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorADSubscription, IndicatorADSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorADSubscription, IndicatorADSubscriptionVariables>(IndicatorADDocument, variables, options);
}
export type IndicatorADSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorADSubscription, IndicatorADSubscriptionVariables>;
export const IndicatorADDDocument = gql`
    subscription IndicatorADD($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorADD(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorADDSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorADDSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorADDSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorADDSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorADDSubscription(variables: IndicatorADDSubscriptionVariables | VueCompositionApi.Ref<IndicatorADDSubscriptionVariables> | ReactiveFunction<IndicatorADDSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorADDSubscription, IndicatorADDSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorADDSubscription, IndicatorADDSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorADDSubscription, IndicatorADDSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorADDSubscription, IndicatorADDSubscriptionVariables>(IndicatorADDDocument, variables, options);
}
export type IndicatorADDSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorADDSubscription, IndicatorADDSubscriptionVariables>;
export const IndicatorADOSCDocument = gql`
    subscription IndicatorADOSC($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optFast_Period: Float, $optSlow_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorADOSC(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optFast_Period: $optFast_Period
    optSlow_Period: $optSlow_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorADOSCSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorADOSCSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorADOSCSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorADOSCSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optFast_Period: // value for 'optFast_Period'
 *   optSlow_Period: // value for 'optSlow_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorADOSCSubscription(variables: IndicatorADOSCSubscriptionVariables | VueCompositionApi.Ref<IndicatorADOSCSubscriptionVariables> | ReactiveFunction<IndicatorADOSCSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorADOSCSubscription, IndicatorADOSCSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorADOSCSubscription, IndicatorADOSCSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorADOSCSubscription, IndicatorADOSCSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorADOSCSubscription, IndicatorADOSCSubscriptionVariables>(IndicatorADOSCDocument, variables, options);
}
export type IndicatorADOSCSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorADOSCSubscription, IndicatorADOSCSubscriptionVariables>;
export const IndicatorADXDocument = gql`
    subscription IndicatorADX($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorADX(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorADXSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorADXSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorADXSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorADXSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorADXSubscription(variables: IndicatorADXSubscriptionVariables | VueCompositionApi.Ref<IndicatorADXSubscriptionVariables> | ReactiveFunction<IndicatorADXSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorADXSubscription, IndicatorADXSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorADXSubscription, IndicatorADXSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorADXSubscription, IndicatorADXSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorADXSubscription, IndicatorADXSubscriptionVariables>(IndicatorADXDocument, variables, options);
}
export type IndicatorADXSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorADXSubscription, IndicatorADXSubscriptionVariables>;
export const IndicatorADXRDocument = gql`
    subscription IndicatorADXR($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorADXR(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorADXRSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorADXRSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorADXRSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorADXRSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorADXRSubscription(variables: IndicatorADXRSubscriptionVariables | VueCompositionApi.Ref<IndicatorADXRSubscriptionVariables> | ReactiveFunction<IndicatorADXRSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorADXRSubscription, IndicatorADXRSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorADXRSubscription, IndicatorADXRSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorADXRSubscription, IndicatorADXRSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorADXRSubscription, IndicatorADXRSubscriptionVariables>(IndicatorADXRDocument, variables, options);
}
export type IndicatorADXRSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorADXRSubscription, IndicatorADXRSubscriptionVariables>;
export const IndicatorAPODocument = gql`
    subscription IndicatorAPO($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optFast_Period: Float, $optSlow_Period: Float, $optMA_Type: MATypes, $startIdx: Int, $endIdx: Int) {
  IndicatorAPO(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optFast_Period: $optFast_Period
    optSlow_Period: $optSlow_Period
    optMA_Type: $optMA_Type
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorAPOSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorAPOSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorAPOSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorAPOSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optFast_Period: // value for 'optFast_Period'
 *   optSlow_Period: // value for 'optSlow_Period'
 *   optMA_Type: // value for 'optMA_Type'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorAPOSubscription(variables: IndicatorAPOSubscriptionVariables | VueCompositionApi.Ref<IndicatorAPOSubscriptionVariables> | ReactiveFunction<IndicatorAPOSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorAPOSubscription, IndicatorAPOSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorAPOSubscription, IndicatorAPOSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorAPOSubscription, IndicatorAPOSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorAPOSubscription, IndicatorAPOSubscriptionVariables>(IndicatorAPODocument, variables, options);
}
export type IndicatorAPOSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorAPOSubscription, IndicatorAPOSubscriptionVariables>;
export const IndicatorAROONDocument = gql`
    subscription IndicatorAROON($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorAROON(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorAROONSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorAROONSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorAROONSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorAROONSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorAROONSubscription(variables: IndicatorAROONSubscriptionVariables | VueCompositionApi.Ref<IndicatorAROONSubscriptionVariables> | ReactiveFunction<IndicatorAROONSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorAROONSubscription, IndicatorAROONSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorAROONSubscription, IndicatorAROONSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorAROONSubscription, IndicatorAROONSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorAROONSubscription, IndicatorAROONSubscriptionVariables>(IndicatorAROONDocument, variables, options);
}
export type IndicatorAROONSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorAROONSubscription, IndicatorAROONSubscriptionVariables>;
export const IndicatorAROONOSCDocument = gql`
    subscription IndicatorAROONOSC($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorAROONOSC(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorAROONOSCSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorAROONOSCSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorAROONOSCSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorAROONOSCSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorAROONOSCSubscription(variables: IndicatorAROONOSCSubscriptionVariables | VueCompositionApi.Ref<IndicatorAROONOSCSubscriptionVariables> | ReactiveFunction<IndicatorAROONOSCSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorAROONOSCSubscription, IndicatorAROONOSCSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorAROONOSCSubscription, IndicatorAROONOSCSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorAROONOSCSubscription, IndicatorAROONOSCSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorAROONOSCSubscription, IndicatorAROONOSCSubscriptionVariables>(IndicatorAROONOSCDocument, variables, options);
}
export type IndicatorAROONOSCSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorAROONOSCSubscription, IndicatorAROONOSCSubscriptionVariables>;
export const IndicatorASINDocument = gql`
    subscription IndicatorASIN($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorASIN(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorASINSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorASINSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorASINSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorASINSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorASINSubscription(variables: IndicatorASINSubscriptionVariables | VueCompositionApi.Ref<IndicatorASINSubscriptionVariables> | ReactiveFunction<IndicatorASINSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorASINSubscription, IndicatorASINSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorASINSubscription, IndicatorASINSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorASINSubscription, IndicatorASINSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorASINSubscription, IndicatorASINSubscriptionVariables>(IndicatorASINDocument, variables, options);
}
export type IndicatorASINSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorASINSubscription, IndicatorASINSubscriptionVariables>;
export const IndicatorATANDocument = gql`
    subscription IndicatorATAN($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorATAN(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorATANSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorATANSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorATANSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorATANSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorATANSubscription(variables: IndicatorATANSubscriptionVariables | VueCompositionApi.Ref<IndicatorATANSubscriptionVariables> | ReactiveFunction<IndicatorATANSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorATANSubscription, IndicatorATANSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorATANSubscription, IndicatorATANSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorATANSubscription, IndicatorATANSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorATANSubscription, IndicatorATANSubscriptionVariables>(IndicatorATANDocument, variables, options);
}
export type IndicatorATANSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorATANSubscription, IndicatorATANSubscriptionVariables>;
export const IndicatorATRDocument = gql`
    subscription IndicatorATR($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorATR(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorATRSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorATRSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorATRSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorATRSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorATRSubscription(variables: IndicatorATRSubscriptionVariables | VueCompositionApi.Ref<IndicatorATRSubscriptionVariables> | ReactiveFunction<IndicatorATRSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorATRSubscription, IndicatorATRSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorATRSubscription, IndicatorATRSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorATRSubscription, IndicatorATRSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorATRSubscription, IndicatorATRSubscriptionVariables>(IndicatorATRDocument, variables, options);
}
export type IndicatorATRSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorATRSubscription, IndicatorATRSubscriptionVariables>;
export const IndicatorAVGDEVDocument = gql`
    subscription IndicatorAVGDEV($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorAVGDEV(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorAVGDEVSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorAVGDEVSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorAVGDEVSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorAVGDEVSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorAVGDEVSubscription(variables: IndicatorAVGDEVSubscriptionVariables | VueCompositionApi.Ref<IndicatorAVGDEVSubscriptionVariables> | ReactiveFunction<IndicatorAVGDEVSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorAVGDEVSubscription, IndicatorAVGDEVSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorAVGDEVSubscription, IndicatorAVGDEVSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorAVGDEVSubscription, IndicatorAVGDEVSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorAVGDEVSubscription, IndicatorAVGDEVSubscriptionVariables>(IndicatorAVGDEVDocument, variables, options);
}
export type IndicatorAVGDEVSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorAVGDEVSubscription, IndicatorAVGDEVSubscriptionVariables>;
export const IndicatorAVGPRICEDocument = gql`
    subscription IndicatorAVGPRICE($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorAVGPRICE(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorAVGPRICESubscription__
 *
 * To run a query within a Vue component, call `useIndicatorAVGPRICESubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorAVGPRICESubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorAVGPRICESubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorAVGPRICESubscription(variables: IndicatorAVGPRICESubscriptionVariables | VueCompositionApi.Ref<IndicatorAVGPRICESubscriptionVariables> | ReactiveFunction<IndicatorAVGPRICESubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorAVGPRICESubscription, IndicatorAVGPRICESubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorAVGPRICESubscription, IndicatorAVGPRICESubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorAVGPRICESubscription, IndicatorAVGPRICESubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorAVGPRICESubscription, IndicatorAVGPRICESubscriptionVariables>(IndicatorAVGPRICEDocument, variables, options);
}
export type IndicatorAVGPRICESubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorAVGPRICESubscription, IndicatorAVGPRICESubscriptionVariables>;
export const IndicatorBBANDSDocument = gql`
    subscription IndicatorBBANDS($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $optDeviations_up: Float, $optDeviations_down: Float, $optMA_Type: MATypes, $startIdx: Int, $endIdx: Int) {
  IndicatorBBANDS(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    optDeviations_up: $optDeviations_up
    optDeviations_down: $optDeviations_down
    optMA_Type: $optMA_Type
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorBBANDSSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorBBANDSSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorBBANDSSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorBBANDSSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   optDeviations_up: // value for 'optDeviations_up'
 *   optDeviations_down: // value for 'optDeviations_down'
 *   optMA_Type: // value for 'optMA_Type'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorBBANDSSubscription(variables: IndicatorBBANDSSubscriptionVariables | VueCompositionApi.Ref<IndicatorBBANDSSubscriptionVariables> | ReactiveFunction<IndicatorBBANDSSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorBBANDSSubscription, IndicatorBBANDSSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorBBANDSSubscription, IndicatorBBANDSSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorBBANDSSubscription, IndicatorBBANDSSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorBBANDSSubscription, IndicatorBBANDSSubscriptionVariables>(IndicatorBBANDSDocument, variables, options);
}
export type IndicatorBBANDSSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorBBANDSSubscription, IndicatorBBANDSSubscriptionVariables>;
export const IndicatorBETADocument = gql`
    subscription IndicatorBETA($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorBETA(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorBETASubscription__
 *
 * To run a query within a Vue component, call `useIndicatorBETASubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorBETASubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorBETASubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorBETASubscription(variables: IndicatorBETASubscriptionVariables | VueCompositionApi.Ref<IndicatorBETASubscriptionVariables> | ReactiveFunction<IndicatorBETASubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorBETASubscription, IndicatorBETASubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorBETASubscription, IndicatorBETASubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorBETASubscription, IndicatorBETASubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorBETASubscription, IndicatorBETASubscriptionVariables>(IndicatorBETADocument, variables, options);
}
export type IndicatorBETASubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorBETASubscription, IndicatorBETASubscriptionVariables>;
export const IndicatorBOPDocument = gql`
    subscription IndicatorBOP($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorBOP(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorBOPSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorBOPSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorBOPSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorBOPSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorBOPSubscription(variables: IndicatorBOPSubscriptionVariables | VueCompositionApi.Ref<IndicatorBOPSubscriptionVariables> | ReactiveFunction<IndicatorBOPSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorBOPSubscription, IndicatorBOPSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorBOPSubscription, IndicatorBOPSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorBOPSubscription, IndicatorBOPSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorBOPSubscription, IndicatorBOPSubscriptionVariables>(IndicatorBOPDocument, variables, options);
}
export type IndicatorBOPSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorBOPSubscription, IndicatorBOPSubscriptionVariables>;
export const IndicatorCCIDocument = gql`
    subscription IndicatorCCI($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorCCI(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCCISubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCCISubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCCISubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCCISubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCCISubscription(variables: IndicatorCCISubscriptionVariables | VueCompositionApi.Ref<IndicatorCCISubscriptionVariables> | ReactiveFunction<IndicatorCCISubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCCISubscription, IndicatorCCISubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCCISubscription, IndicatorCCISubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCCISubscription, IndicatorCCISubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCCISubscription, IndicatorCCISubscriptionVariables>(IndicatorCCIDocument, variables, options);
}
export type IndicatorCCISubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCCISubscription, IndicatorCCISubscriptionVariables>;
export const IndicatorCDL2CROWSDocument = gql`
    subscription IndicatorCDL2CROWS($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDL2CROWS(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDL2CROWSSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDL2CROWSSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDL2CROWSSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDL2CROWSSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDL2CROWSSubscription(variables: IndicatorCDL2CROWSSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDL2CROWSSubscriptionVariables> | ReactiveFunction<IndicatorCDL2CROWSSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDL2CROWSSubscription, IndicatorCDL2CROWSSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDL2CROWSSubscription, IndicatorCDL2CROWSSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDL2CROWSSubscription, IndicatorCDL2CROWSSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDL2CROWSSubscription, IndicatorCDL2CROWSSubscriptionVariables>(IndicatorCDL2CROWSDocument, variables, options);
}
export type IndicatorCDL2CROWSSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDL2CROWSSubscription, IndicatorCDL2CROWSSubscriptionVariables>;
export const IndicatorCDL3BLACKCROWSDocument = gql`
    subscription IndicatorCDL3BLACKCROWS($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDL3BLACKCROWS(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDL3BLACKCROWSSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDL3BLACKCROWSSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDL3BLACKCROWSSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDL3BLACKCROWSSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDL3BLACKCROWSSubscription(variables: IndicatorCDL3BLACKCROWSSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDL3BLACKCROWSSubscriptionVariables> | ReactiveFunction<IndicatorCDL3BLACKCROWSSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDL3BLACKCROWSSubscription, IndicatorCDL3BLACKCROWSSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDL3BLACKCROWSSubscription, IndicatorCDL3BLACKCROWSSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDL3BLACKCROWSSubscription, IndicatorCDL3BLACKCROWSSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDL3BLACKCROWSSubscription, IndicatorCDL3BLACKCROWSSubscriptionVariables>(IndicatorCDL3BLACKCROWSDocument, variables, options);
}
export type IndicatorCDL3BLACKCROWSSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDL3BLACKCROWSSubscription, IndicatorCDL3BLACKCROWSSubscriptionVariables>;
export const IndicatorCDL3INSIDEDocument = gql`
    subscription IndicatorCDL3INSIDE($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDL3INSIDE(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDL3INSIDESubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDL3INSIDESubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDL3INSIDESubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDL3INSIDESubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDL3INSIDESubscription(variables: IndicatorCDL3INSIDESubscriptionVariables | VueCompositionApi.Ref<IndicatorCDL3INSIDESubscriptionVariables> | ReactiveFunction<IndicatorCDL3INSIDESubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDL3INSIDESubscription, IndicatorCDL3INSIDESubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDL3INSIDESubscription, IndicatorCDL3INSIDESubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDL3INSIDESubscription, IndicatorCDL3INSIDESubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDL3INSIDESubscription, IndicatorCDL3INSIDESubscriptionVariables>(IndicatorCDL3INSIDEDocument, variables, options);
}
export type IndicatorCDL3INSIDESubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDL3INSIDESubscription, IndicatorCDL3INSIDESubscriptionVariables>;
export const IndicatorCDL3LINESTRIKEDocument = gql`
    subscription IndicatorCDL3LINESTRIKE($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDL3LINESTRIKE(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDL3LINESTRIKESubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDL3LINESTRIKESubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDL3LINESTRIKESubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDL3LINESTRIKESubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDL3LINESTRIKESubscription(variables: IndicatorCDL3LINESTRIKESubscriptionVariables | VueCompositionApi.Ref<IndicatorCDL3LINESTRIKESubscriptionVariables> | ReactiveFunction<IndicatorCDL3LINESTRIKESubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDL3LINESTRIKESubscription, IndicatorCDL3LINESTRIKESubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDL3LINESTRIKESubscription, IndicatorCDL3LINESTRIKESubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDL3LINESTRIKESubscription, IndicatorCDL3LINESTRIKESubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDL3LINESTRIKESubscription, IndicatorCDL3LINESTRIKESubscriptionVariables>(IndicatorCDL3LINESTRIKEDocument, variables, options);
}
export type IndicatorCDL3LINESTRIKESubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDL3LINESTRIKESubscription, IndicatorCDL3LINESTRIKESubscriptionVariables>;
export const IndicatorCDL3OUTSIDEDocument = gql`
    subscription IndicatorCDL3OUTSIDE($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDL3OUTSIDE(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDL3OUTSIDESubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDL3OUTSIDESubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDL3OUTSIDESubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDL3OUTSIDESubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDL3OUTSIDESubscription(variables: IndicatorCDL3OUTSIDESubscriptionVariables | VueCompositionApi.Ref<IndicatorCDL3OUTSIDESubscriptionVariables> | ReactiveFunction<IndicatorCDL3OUTSIDESubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDL3OUTSIDESubscription, IndicatorCDL3OUTSIDESubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDL3OUTSIDESubscription, IndicatorCDL3OUTSIDESubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDL3OUTSIDESubscription, IndicatorCDL3OUTSIDESubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDL3OUTSIDESubscription, IndicatorCDL3OUTSIDESubscriptionVariables>(IndicatorCDL3OUTSIDEDocument, variables, options);
}
export type IndicatorCDL3OUTSIDESubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDL3OUTSIDESubscription, IndicatorCDL3OUTSIDESubscriptionVariables>;
export const IndicatorCDL3STARSINSOUTHDocument = gql`
    subscription IndicatorCDL3STARSINSOUTH($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDL3STARSINSOUTH(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDL3STARSINSOUTHSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDL3STARSINSOUTHSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDL3STARSINSOUTHSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDL3STARSINSOUTHSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDL3STARSINSOUTHSubscription(variables: IndicatorCDL3STARSINSOUTHSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDL3STARSINSOUTHSubscriptionVariables> | ReactiveFunction<IndicatorCDL3STARSINSOUTHSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDL3STARSINSOUTHSubscription, IndicatorCDL3STARSINSOUTHSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDL3STARSINSOUTHSubscription, IndicatorCDL3STARSINSOUTHSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDL3STARSINSOUTHSubscription, IndicatorCDL3STARSINSOUTHSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDL3STARSINSOUTHSubscription, IndicatorCDL3STARSINSOUTHSubscriptionVariables>(IndicatorCDL3STARSINSOUTHDocument, variables, options);
}
export type IndicatorCDL3STARSINSOUTHSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDL3STARSINSOUTHSubscription, IndicatorCDL3STARSINSOUTHSubscriptionVariables>;
export const IndicatorCDL3WHITESOLDIERSDocument = gql`
    subscription IndicatorCDL3WHITESOLDIERS($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDL3WHITESOLDIERS(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDL3WHITESOLDIERSSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDL3WHITESOLDIERSSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDL3WHITESOLDIERSSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDL3WHITESOLDIERSSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDL3WHITESOLDIERSSubscription(variables: IndicatorCDL3WHITESOLDIERSSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDL3WHITESOLDIERSSubscriptionVariables> | ReactiveFunction<IndicatorCDL3WHITESOLDIERSSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDL3WHITESOLDIERSSubscription, IndicatorCDL3WHITESOLDIERSSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDL3WHITESOLDIERSSubscription, IndicatorCDL3WHITESOLDIERSSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDL3WHITESOLDIERSSubscription, IndicatorCDL3WHITESOLDIERSSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDL3WHITESOLDIERSSubscription, IndicatorCDL3WHITESOLDIERSSubscriptionVariables>(IndicatorCDL3WHITESOLDIERSDocument, variables, options);
}
export type IndicatorCDL3WHITESOLDIERSSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDL3WHITESOLDIERSSubscription, IndicatorCDL3WHITESOLDIERSSubscriptionVariables>;
export const IndicatorCDLABANDONEDBABYDocument = gql`
    subscription IndicatorCDLABANDONEDBABY($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optPenetration: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLABANDONEDBABY(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optPenetration: $optPenetration
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLABANDONEDBABYSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLABANDONEDBABYSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLABANDONEDBABYSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLABANDONEDBABYSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optPenetration: // value for 'optPenetration'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLABANDONEDBABYSubscription(variables: IndicatorCDLABANDONEDBABYSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLABANDONEDBABYSubscriptionVariables> | ReactiveFunction<IndicatorCDLABANDONEDBABYSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLABANDONEDBABYSubscription, IndicatorCDLABANDONEDBABYSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLABANDONEDBABYSubscription, IndicatorCDLABANDONEDBABYSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLABANDONEDBABYSubscription, IndicatorCDLABANDONEDBABYSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLABANDONEDBABYSubscription, IndicatorCDLABANDONEDBABYSubscriptionVariables>(IndicatorCDLABANDONEDBABYDocument, variables, options);
}
export type IndicatorCDLABANDONEDBABYSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLABANDONEDBABYSubscription, IndicatorCDLABANDONEDBABYSubscriptionVariables>;
export const IndicatorCDLADVANCEBLOCKDocument = gql`
    subscription IndicatorCDLADVANCEBLOCK($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLADVANCEBLOCK(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLADVANCEBLOCKSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLADVANCEBLOCKSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLADVANCEBLOCKSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLADVANCEBLOCKSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLADVANCEBLOCKSubscription(variables: IndicatorCDLADVANCEBLOCKSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLADVANCEBLOCKSubscriptionVariables> | ReactiveFunction<IndicatorCDLADVANCEBLOCKSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLADVANCEBLOCKSubscription, IndicatorCDLADVANCEBLOCKSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLADVANCEBLOCKSubscription, IndicatorCDLADVANCEBLOCKSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLADVANCEBLOCKSubscription, IndicatorCDLADVANCEBLOCKSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLADVANCEBLOCKSubscription, IndicatorCDLADVANCEBLOCKSubscriptionVariables>(IndicatorCDLADVANCEBLOCKDocument, variables, options);
}
export type IndicatorCDLADVANCEBLOCKSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLADVANCEBLOCKSubscription, IndicatorCDLADVANCEBLOCKSubscriptionVariables>;
export const IndicatorCDLBELTHOLDDocument = gql`
    subscription IndicatorCDLBELTHOLD($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLBELTHOLD(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLBELTHOLDSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLBELTHOLDSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLBELTHOLDSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLBELTHOLDSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLBELTHOLDSubscription(variables: IndicatorCDLBELTHOLDSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLBELTHOLDSubscriptionVariables> | ReactiveFunction<IndicatorCDLBELTHOLDSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLBELTHOLDSubscription, IndicatorCDLBELTHOLDSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLBELTHOLDSubscription, IndicatorCDLBELTHOLDSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLBELTHOLDSubscription, IndicatorCDLBELTHOLDSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLBELTHOLDSubscription, IndicatorCDLBELTHOLDSubscriptionVariables>(IndicatorCDLBELTHOLDDocument, variables, options);
}
export type IndicatorCDLBELTHOLDSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLBELTHOLDSubscription, IndicatorCDLBELTHOLDSubscriptionVariables>;
export const IndicatorCDLBREAKAWAYDocument = gql`
    subscription IndicatorCDLBREAKAWAY($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLBREAKAWAY(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLBREAKAWAYSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLBREAKAWAYSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLBREAKAWAYSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLBREAKAWAYSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLBREAKAWAYSubscription(variables: IndicatorCDLBREAKAWAYSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLBREAKAWAYSubscriptionVariables> | ReactiveFunction<IndicatorCDLBREAKAWAYSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLBREAKAWAYSubscription, IndicatorCDLBREAKAWAYSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLBREAKAWAYSubscription, IndicatorCDLBREAKAWAYSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLBREAKAWAYSubscription, IndicatorCDLBREAKAWAYSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLBREAKAWAYSubscription, IndicatorCDLBREAKAWAYSubscriptionVariables>(IndicatorCDLBREAKAWAYDocument, variables, options);
}
export type IndicatorCDLBREAKAWAYSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLBREAKAWAYSubscription, IndicatorCDLBREAKAWAYSubscriptionVariables>;
export const IndicatorCDLCLOSINGMARUBOZUDocument = gql`
    subscription IndicatorCDLCLOSINGMARUBOZU($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLCLOSINGMARUBOZU(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLCLOSINGMARUBOZUSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLCLOSINGMARUBOZUSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLCLOSINGMARUBOZUSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLCLOSINGMARUBOZUSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLCLOSINGMARUBOZUSubscription(variables: IndicatorCDLCLOSINGMARUBOZUSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLCLOSINGMARUBOZUSubscriptionVariables> | ReactiveFunction<IndicatorCDLCLOSINGMARUBOZUSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLCLOSINGMARUBOZUSubscription, IndicatorCDLCLOSINGMARUBOZUSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLCLOSINGMARUBOZUSubscription, IndicatorCDLCLOSINGMARUBOZUSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLCLOSINGMARUBOZUSubscription, IndicatorCDLCLOSINGMARUBOZUSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLCLOSINGMARUBOZUSubscription, IndicatorCDLCLOSINGMARUBOZUSubscriptionVariables>(IndicatorCDLCLOSINGMARUBOZUDocument, variables, options);
}
export type IndicatorCDLCLOSINGMARUBOZUSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLCLOSINGMARUBOZUSubscription, IndicatorCDLCLOSINGMARUBOZUSubscriptionVariables>;
export const IndicatorCDLCONCEALBABYSWALLDocument = gql`
    subscription IndicatorCDLCONCEALBABYSWALL($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLCONCEALBABYSWALL(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLCONCEALBABYSWALLSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLCONCEALBABYSWALLSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLCONCEALBABYSWALLSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLCONCEALBABYSWALLSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLCONCEALBABYSWALLSubscription(variables: IndicatorCDLCONCEALBABYSWALLSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLCONCEALBABYSWALLSubscriptionVariables> | ReactiveFunction<IndicatorCDLCONCEALBABYSWALLSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLCONCEALBABYSWALLSubscription, IndicatorCDLCONCEALBABYSWALLSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLCONCEALBABYSWALLSubscription, IndicatorCDLCONCEALBABYSWALLSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLCONCEALBABYSWALLSubscription, IndicatorCDLCONCEALBABYSWALLSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLCONCEALBABYSWALLSubscription, IndicatorCDLCONCEALBABYSWALLSubscriptionVariables>(IndicatorCDLCONCEALBABYSWALLDocument, variables, options);
}
export type IndicatorCDLCONCEALBABYSWALLSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLCONCEALBABYSWALLSubscription, IndicatorCDLCONCEALBABYSWALLSubscriptionVariables>;
export const IndicatorCDLCOUNTERATTACKDocument = gql`
    subscription IndicatorCDLCOUNTERATTACK($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLCOUNTERATTACK(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLCOUNTERATTACKSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLCOUNTERATTACKSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLCOUNTERATTACKSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLCOUNTERATTACKSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLCOUNTERATTACKSubscription(variables: IndicatorCDLCOUNTERATTACKSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLCOUNTERATTACKSubscriptionVariables> | ReactiveFunction<IndicatorCDLCOUNTERATTACKSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLCOUNTERATTACKSubscription, IndicatorCDLCOUNTERATTACKSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLCOUNTERATTACKSubscription, IndicatorCDLCOUNTERATTACKSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLCOUNTERATTACKSubscription, IndicatorCDLCOUNTERATTACKSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLCOUNTERATTACKSubscription, IndicatorCDLCOUNTERATTACKSubscriptionVariables>(IndicatorCDLCOUNTERATTACKDocument, variables, options);
}
export type IndicatorCDLCOUNTERATTACKSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLCOUNTERATTACKSubscription, IndicatorCDLCOUNTERATTACKSubscriptionVariables>;
export const IndicatorCDLDARKCLOUDCOVERDocument = gql`
    subscription IndicatorCDLDARKCLOUDCOVER($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optPenetration: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLDARKCLOUDCOVER(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optPenetration: $optPenetration
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLDARKCLOUDCOVERSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLDARKCLOUDCOVERSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLDARKCLOUDCOVERSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLDARKCLOUDCOVERSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optPenetration: // value for 'optPenetration'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLDARKCLOUDCOVERSubscription(variables: IndicatorCDLDARKCLOUDCOVERSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLDARKCLOUDCOVERSubscriptionVariables> | ReactiveFunction<IndicatorCDLDARKCLOUDCOVERSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLDARKCLOUDCOVERSubscription, IndicatorCDLDARKCLOUDCOVERSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLDARKCLOUDCOVERSubscription, IndicatorCDLDARKCLOUDCOVERSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLDARKCLOUDCOVERSubscription, IndicatorCDLDARKCLOUDCOVERSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLDARKCLOUDCOVERSubscription, IndicatorCDLDARKCLOUDCOVERSubscriptionVariables>(IndicatorCDLDARKCLOUDCOVERDocument, variables, options);
}
export type IndicatorCDLDARKCLOUDCOVERSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLDARKCLOUDCOVERSubscription, IndicatorCDLDARKCLOUDCOVERSubscriptionVariables>;
export const IndicatorCDLDOJIDocument = gql`
    subscription IndicatorCDLDOJI($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLDOJI(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLDOJISubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLDOJISubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLDOJISubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLDOJISubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLDOJISubscription(variables: IndicatorCDLDOJISubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLDOJISubscriptionVariables> | ReactiveFunction<IndicatorCDLDOJISubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLDOJISubscription, IndicatorCDLDOJISubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLDOJISubscription, IndicatorCDLDOJISubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLDOJISubscription, IndicatorCDLDOJISubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLDOJISubscription, IndicatorCDLDOJISubscriptionVariables>(IndicatorCDLDOJIDocument, variables, options);
}
export type IndicatorCDLDOJISubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLDOJISubscription, IndicatorCDLDOJISubscriptionVariables>;
export const IndicatorCDLDOJISTARDocument = gql`
    subscription IndicatorCDLDOJISTAR($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLDOJISTAR(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLDOJISTARSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLDOJISTARSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLDOJISTARSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLDOJISTARSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLDOJISTARSubscription(variables: IndicatorCDLDOJISTARSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLDOJISTARSubscriptionVariables> | ReactiveFunction<IndicatorCDLDOJISTARSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLDOJISTARSubscription, IndicatorCDLDOJISTARSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLDOJISTARSubscription, IndicatorCDLDOJISTARSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLDOJISTARSubscription, IndicatorCDLDOJISTARSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLDOJISTARSubscription, IndicatorCDLDOJISTARSubscriptionVariables>(IndicatorCDLDOJISTARDocument, variables, options);
}
export type IndicatorCDLDOJISTARSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLDOJISTARSubscription, IndicatorCDLDOJISTARSubscriptionVariables>;
export const IndicatorCDLDRAGONFLYDOJIDocument = gql`
    subscription IndicatorCDLDRAGONFLYDOJI($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLDRAGONFLYDOJI(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLDRAGONFLYDOJISubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLDRAGONFLYDOJISubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLDRAGONFLYDOJISubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLDRAGONFLYDOJISubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLDRAGONFLYDOJISubscription(variables: IndicatorCDLDRAGONFLYDOJISubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLDRAGONFLYDOJISubscriptionVariables> | ReactiveFunction<IndicatorCDLDRAGONFLYDOJISubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLDRAGONFLYDOJISubscription, IndicatorCDLDRAGONFLYDOJISubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLDRAGONFLYDOJISubscription, IndicatorCDLDRAGONFLYDOJISubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLDRAGONFLYDOJISubscription, IndicatorCDLDRAGONFLYDOJISubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLDRAGONFLYDOJISubscription, IndicatorCDLDRAGONFLYDOJISubscriptionVariables>(IndicatorCDLDRAGONFLYDOJIDocument, variables, options);
}
export type IndicatorCDLDRAGONFLYDOJISubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLDRAGONFLYDOJISubscription, IndicatorCDLDRAGONFLYDOJISubscriptionVariables>;
export const IndicatorCDLENGULFINGDocument = gql`
    subscription IndicatorCDLENGULFING($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLENGULFING(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLENGULFINGSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLENGULFINGSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLENGULFINGSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLENGULFINGSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLENGULFINGSubscription(variables: IndicatorCDLENGULFINGSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLENGULFINGSubscriptionVariables> | ReactiveFunction<IndicatorCDLENGULFINGSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLENGULFINGSubscription, IndicatorCDLENGULFINGSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLENGULFINGSubscription, IndicatorCDLENGULFINGSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLENGULFINGSubscription, IndicatorCDLENGULFINGSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLENGULFINGSubscription, IndicatorCDLENGULFINGSubscriptionVariables>(IndicatorCDLENGULFINGDocument, variables, options);
}
export type IndicatorCDLENGULFINGSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLENGULFINGSubscription, IndicatorCDLENGULFINGSubscriptionVariables>;
export const IndicatorCDLEVENINGDOJISTARDocument = gql`
    subscription IndicatorCDLEVENINGDOJISTAR($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optPenetration: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLEVENINGDOJISTAR(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optPenetration: $optPenetration
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLEVENINGDOJISTARSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLEVENINGDOJISTARSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLEVENINGDOJISTARSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLEVENINGDOJISTARSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optPenetration: // value for 'optPenetration'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLEVENINGDOJISTARSubscription(variables: IndicatorCDLEVENINGDOJISTARSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLEVENINGDOJISTARSubscriptionVariables> | ReactiveFunction<IndicatorCDLEVENINGDOJISTARSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLEVENINGDOJISTARSubscription, IndicatorCDLEVENINGDOJISTARSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLEVENINGDOJISTARSubscription, IndicatorCDLEVENINGDOJISTARSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLEVENINGDOJISTARSubscription, IndicatorCDLEVENINGDOJISTARSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLEVENINGDOJISTARSubscription, IndicatorCDLEVENINGDOJISTARSubscriptionVariables>(IndicatorCDLEVENINGDOJISTARDocument, variables, options);
}
export type IndicatorCDLEVENINGDOJISTARSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLEVENINGDOJISTARSubscription, IndicatorCDLEVENINGDOJISTARSubscriptionVariables>;
export const IndicatorCDLEVENINGSTARDocument = gql`
    subscription IndicatorCDLEVENINGSTAR($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optPenetration: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLEVENINGSTAR(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optPenetration: $optPenetration
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLEVENINGSTARSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLEVENINGSTARSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLEVENINGSTARSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLEVENINGSTARSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optPenetration: // value for 'optPenetration'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLEVENINGSTARSubscription(variables: IndicatorCDLEVENINGSTARSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLEVENINGSTARSubscriptionVariables> | ReactiveFunction<IndicatorCDLEVENINGSTARSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLEVENINGSTARSubscription, IndicatorCDLEVENINGSTARSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLEVENINGSTARSubscription, IndicatorCDLEVENINGSTARSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLEVENINGSTARSubscription, IndicatorCDLEVENINGSTARSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLEVENINGSTARSubscription, IndicatorCDLEVENINGSTARSubscriptionVariables>(IndicatorCDLEVENINGSTARDocument, variables, options);
}
export type IndicatorCDLEVENINGSTARSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLEVENINGSTARSubscription, IndicatorCDLEVENINGSTARSubscriptionVariables>;
export const IndicatorCDLGAPSIDESIDEWHITEDocument = gql`
    subscription IndicatorCDLGAPSIDESIDEWHITE($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLGAPSIDESIDEWHITE(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLGAPSIDESIDEWHITESubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLGAPSIDESIDEWHITESubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLGAPSIDESIDEWHITESubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLGAPSIDESIDEWHITESubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLGAPSIDESIDEWHITESubscription(variables: IndicatorCDLGAPSIDESIDEWHITESubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLGAPSIDESIDEWHITESubscriptionVariables> | ReactiveFunction<IndicatorCDLGAPSIDESIDEWHITESubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLGAPSIDESIDEWHITESubscription, IndicatorCDLGAPSIDESIDEWHITESubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLGAPSIDESIDEWHITESubscription, IndicatorCDLGAPSIDESIDEWHITESubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLGAPSIDESIDEWHITESubscription, IndicatorCDLGAPSIDESIDEWHITESubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLGAPSIDESIDEWHITESubscription, IndicatorCDLGAPSIDESIDEWHITESubscriptionVariables>(IndicatorCDLGAPSIDESIDEWHITEDocument, variables, options);
}
export type IndicatorCDLGAPSIDESIDEWHITESubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLGAPSIDESIDEWHITESubscription, IndicatorCDLGAPSIDESIDEWHITESubscriptionVariables>;
export const IndicatorCDLGRAVESTONEDOJIDocument = gql`
    subscription IndicatorCDLGRAVESTONEDOJI($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLGRAVESTONEDOJI(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLGRAVESTONEDOJISubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLGRAVESTONEDOJISubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLGRAVESTONEDOJISubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLGRAVESTONEDOJISubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLGRAVESTONEDOJISubscription(variables: IndicatorCDLGRAVESTONEDOJISubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLGRAVESTONEDOJISubscriptionVariables> | ReactiveFunction<IndicatorCDLGRAVESTONEDOJISubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLGRAVESTONEDOJISubscription, IndicatorCDLGRAVESTONEDOJISubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLGRAVESTONEDOJISubscription, IndicatorCDLGRAVESTONEDOJISubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLGRAVESTONEDOJISubscription, IndicatorCDLGRAVESTONEDOJISubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLGRAVESTONEDOJISubscription, IndicatorCDLGRAVESTONEDOJISubscriptionVariables>(IndicatorCDLGRAVESTONEDOJIDocument, variables, options);
}
export type IndicatorCDLGRAVESTONEDOJISubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLGRAVESTONEDOJISubscription, IndicatorCDLGRAVESTONEDOJISubscriptionVariables>;
export const IndicatorCDLHAMMERDocument = gql`
    subscription IndicatorCDLHAMMER($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLHAMMER(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLHAMMERSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLHAMMERSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLHAMMERSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLHAMMERSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLHAMMERSubscription(variables: IndicatorCDLHAMMERSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLHAMMERSubscriptionVariables> | ReactiveFunction<IndicatorCDLHAMMERSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHAMMERSubscription, IndicatorCDLHAMMERSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHAMMERSubscription, IndicatorCDLHAMMERSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHAMMERSubscription, IndicatorCDLHAMMERSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLHAMMERSubscription, IndicatorCDLHAMMERSubscriptionVariables>(IndicatorCDLHAMMERDocument, variables, options);
}
export type IndicatorCDLHAMMERSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLHAMMERSubscription, IndicatorCDLHAMMERSubscriptionVariables>;
export const IndicatorCDLHANGINGMANDocument = gql`
    subscription IndicatorCDLHANGINGMAN($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLHANGINGMAN(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLHANGINGMANSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLHANGINGMANSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLHANGINGMANSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLHANGINGMANSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLHANGINGMANSubscription(variables: IndicatorCDLHANGINGMANSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLHANGINGMANSubscriptionVariables> | ReactiveFunction<IndicatorCDLHANGINGMANSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHANGINGMANSubscription, IndicatorCDLHANGINGMANSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHANGINGMANSubscription, IndicatorCDLHANGINGMANSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHANGINGMANSubscription, IndicatorCDLHANGINGMANSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLHANGINGMANSubscription, IndicatorCDLHANGINGMANSubscriptionVariables>(IndicatorCDLHANGINGMANDocument, variables, options);
}
export type IndicatorCDLHANGINGMANSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLHANGINGMANSubscription, IndicatorCDLHANGINGMANSubscriptionVariables>;
export const IndicatorCDLHARAMIDocument = gql`
    subscription IndicatorCDLHARAMI($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLHARAMI(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLHARAMISubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLHARAMISubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLHARAMISubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLHARAMISubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLHARAMISubscription(variables: IndicatorCDLHARAMISubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLHARAMISubscriptionVariables> | ReactiveFunction<IndicatorCDLHARAMISubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHARAMISubscription, IndicatorCDLHARAMISubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHARAMISubscription, IndicatorCDLHARAMISubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHARAMISubscription, IndicatorCDLHARAMISubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLHARAMISubscription, IndicatorCDLHARAMISubscriptionVariables>(IndicatorCDLHARAMIDocument, variables, options);
}
export type IndicatorCDLHARAMISubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLHARAMISubscription, IndicatorCDLHARAMISubscriptionVariables>;
export const IndicatorCDLHARAMICROSSDocument = gql`
    subscription IndicatorCDLHARAMICROSS($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLHARAMICROSS(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLHARAMICROSSSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLHARAMICROSSSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLHARAMICROSSSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLHARAMICROSSSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLHARAMICROSSSubscription(variables: IndicatorCDLHARAMICROSSSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLHARAMICROSSSubscriptionVariables> | ReactiveFunction<IndicatorCDLHARAMICROSSSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHARAMICROSSSubscription, IndicatorCDLHARAMICROSSSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHARAMICROSSSubscription, IndicatorCDLHARAMICROSSSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHARAMICROSSSubscription, IndicatorCDLHARAMICROSSSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLHARAMICROSSSubscription, IndicatorCDLHARAMICROSSSubscriptionVariables>(IndicatorCDLHARAMICROSSDocument, variables, options);
}
export type IndicatorCDLHARAMICROSSSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLHARAMICROSSSubscription, IndicatorCDLHARAMICROSSSubscriptionVariables>;
export const IndicatorCDLHIGHWAVEDocument = gql`
    subscription IndicatorCDLHIGHWAVE($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLHIGHWAVE(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLHIGHWAVESubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLHIGHWAVESubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLHIGHWAVESubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLHIGHWAVESubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLHIGHWAVESubscription(variables: IndicatorCDLHIGHWAVESubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLHIGHWAVESubscriptionVariables> | ReactiveFunction<IndicatorCDLHIGHWAVESubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHIGHWAVESubscription, IndicatorCDLHIGHWAVESubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHIGHWAVESubscription, IndicatorCDLHIGHWAVESubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHIGHWAVESubscription, IndicatorCDLHIGHWAVESubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLHIGHWAVESubscription, IndicatorCDLHIGHWAVESubscriptionVariables>(IndicatorCDLHIGHWAVEDocument, variables, options);
}
export type IndicatorCDLHIGHWAVESubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLHIGHWAVESubscription, IndicatorCDLHIGHWAVESubscriptionVariables>;
export const IndicatorCDLHIKKAKEDocument = gql`
    subscription IndicatorCDLHIKKAKE($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLHIKKAKE(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLHIKKAKESubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLHIKKAKESubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLHIKKAKESubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLHIKKAKESubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLHIKKAKESubscription(variables: IndicatorCDLHIKKAKESubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLHIKKAKESubscriptionVariables> | ReactiveFunction<IndicatorCDLHIKKAKESubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHIKKAKESubscription, IndicatorCDLHIKKAKESubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHIKKAKESubscription, IndicatorCDLHIKKAKESubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHIKKAKESubscription, IndicatorCDLHIKKAKESubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLHIKKAKESubscription, IndicatorCDLHIKKAKESubscriptionVariables>(IndicatorCDLHIKKAKEDocument, variables, options);
}
export type IndicatorCDLHIKKAKESubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLHIKKAKESubscription, IndicatorCDLHIKKAKESubscriptionVariables>;
export const IndicatorCDLHIKKAKEMODDocument = gql`
    subscription IndicatorCDLHIKKAKEMOD($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLHIKKAKEMOD(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLHIKKAKEMODSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLHIKKAKEMODSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLHIKKAKEMODSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLHIKKAKEMODSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLHIKKAKEMODSubscription(variables: IndicatorCDLHIKKAKEMODSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLHIKKAKEMODSubscriptionVariables> | ReactiveFunction<IndicatorCDLHIKKAKEMODSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHIKKAKEMODSubscription, IndicatorCDLHIKKAKEMODSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHIKKAKEMODSubscription, IndicatorCDLHIKKAKEMODSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHIKKAKEMODSubscription, IndicatorCDLHIKKAKEMODSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLHIKKAKEMODSubscription, IndicatorCDLHIKKAKEMODSubscriptionVariables>(IndicatorCDLHIKKAKEMODDocument, variables, options);
}
export type IndicatorCDLHIKKAKEMODSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLHIKKAKEMODSubscription, IndicatorCDLHIKKAKEMODSubscriptionVariables>;
export const IndicatorCDLHOMINGPIGEONDocument = gql`
    subscription IndicatorCDLHOMINGPIGEON($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLHOMINGPIGEON(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLHOMINGPIGEONSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLHOMINGPIGEONSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLHOMINGPIGEONSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLHOMINGPIGEONSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLHOMINGPIGEONSubscription(variables: IndicatorCDLHOMINGPIGEONSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLHOMINGPIGEONSubscriptionVariables> | ReactiveFunction<IndicatorCDLHOMINGPIGEONSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHOMINGPIGEONSubscription, IndicatorCDLHOMINGPIGEONSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHOMINGPIGEONSubscription, IndicatorCDLHOMINGPIGEONSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLHOMINGPIGEONSubscription, IndicatorCDLHOMINGPIGEONSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLHOMINGPIGEONSubscription, IndicatorCDLHOMINGPIGEONSubscriptionVariables>(IndicatorCDLHOMINGPIGEONDocument, variables, options);
}
export type IndicatorCDLHOMINGPIGEONSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLHOMINGPIGEONSubscription, IndicatorCDLHOMINGPIGEONSubscriptionVariables>;
export const IndicatorCDLIDENTICAL3CROWSDocument = gql`
    subscription IndicatorCDLIDENTICAL3CROWS($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLIDENTICAL3CROWS(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLIDENTICAL3CROWSSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLIDENTICAL3CROWSSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLIDENTICAL3CROWSSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLIDENTICAL3CROWSSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLIDENTICAL3CROWSSubscription(variables: IndicatorCDLIDENTICAL3CROWSSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLIDENTICAL3CROWSSubscriptionVariables> | ReactiveFunction<IndicatorCDLIDENTICAL3CROWSSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLIDENTICAL3CROWSSubscription, IndicatorCDLIDENTICAL3CROWSSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLIDENTICAL3CROWSSubscription, IndicatorCDLIDENTICAL3CROWSSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLIDENTICAL3CROWSSubscription, IndicatorCDLIDENTICAL3CROWSSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLIDENTICAL3CROWSSubscription, IndicatorCDLIDENTICAL3CROWSSubscriptionVariables>(IndicatorCDLIDENTICAL3CROWSDocument, variables, options);
}
export type IndicatorCDLIDENTICAL3CROWSSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLIDENTICAL3CROWSSubscription, IndicatorCDLIDENTICAL3CROWSSubscriptionVariables>;
export const IndicatorCDLINNECKDocument = gql`
    subscription IndicatorCDLINNECK($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLINNECK(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLINNECKSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLINNECKSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLINNECKSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLINNECKSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLINNECKSubscription(variables: IndicatorCDLINNECKSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLINNECKSubscriptionVariables> | ReactiveFunction<IndicatorCDLINNECKSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLINNECKSubscription, IndicatorCDLINNECKSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLINNECKSubscription, IndicatorCDLINNECKSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLINNECKSubscription, IndicatorCDLINNECKSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLINNECKSubscription, IndicatorCDLINNECKSubscriptionVariables>(IndicatorCDLINNECKDocument, variables, options);
}
export type IndicatorCDLINNECKSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLINNECKSubscription, IndicatorCDLINNECKSubscriptionVariables>;
export const IndicatorCDLINVERTEDHAMMERDocument = gql`
    subscription IndicatorCDLINVERTEDHAMMER($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLINVERTEDHAMMER(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLINVERTEDHAMMERSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLINVERTEDHAMMERSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLINVERTEDHAMMERSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLINVERTEDHAMMERSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLINVERTEDHAMMERSubscription(variables: IndicatorCDLINVERTEDHAMMERSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLINVERTEDHAMMERSubscriptionVariables> | ReactiveFunction<IndicatorCDLINVERTEDHAMMERSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLINVERTEDHAMMERSubscription, IndicatorCDLINVERTEDHAMMERSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLINVERTEDHAMMERSubscription, IndicatorCDLINVERTEDHAMMERSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLINVERTEDHAMMERSubscription, IndicatorCDLINVERTEDHAMMERSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLINVERTEDHAMMERSubscription, IndicatorCDLINVERTEDHAMMERSubscriptionVariables>(IndicatorCDLINVERTEDHAMMERDocument, variables, options);
}
export type IndicatorCDLINVERTEDHAMMERSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLINVERTEDHAMMERSubscription, IndicatorCDLINVERTEDHAMMERSubscriptionVariables>;
export const IndicatorCDLKICKINGDocument = gql`
    subscription IndicatorCDLKICKING($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLKICKING(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLKICKINGSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLKICKINGSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLKICKINGSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLKICKINGSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLKICKINGSubscription(variables: IndicatorCDLKICKINGSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLKICKINGSubscriptionVariables> | ReactiveFunction<IndicatorCDLKICKINGSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLKICKINGSubscription, IndicatorCDLKICKINGSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLKICKINGSubscription, IndicatorCDLKICKINGSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLKICKINGSubscription, IndicatorCDLKICKINGSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLKICKINGSubscription, IndicatorCDLKICKINGSubscriptionVariables>(IndicatorCDLKICKINGDocument, variables, options);
}
export type IndicatorCDLKICKINGSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLKICKINGSubscription, IndicatorCDLKICKINGSubscriptionVariables>;
export const IndicatorCDLKICKINGBYLENGTHDocument = gql`
    subscription IndicatorCDLKICKINGBYLENGTH($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLKICKINGBYLENGTH(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLKICKINGBYLENGTHSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLKICKINGBYLENGTHSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLKICKINGBYLENGTHSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLKICKINGBYLENGTHSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLKICKINGBYLENGTHSubscription(variables: IndicatorCDLKICKINGBYLENGTHSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLKICKINGBYLENGTHSubscriptionVariables> | ReactiveFunction<IndicatorCDLKICKINGBYLENGTHSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLKICKINGBYLENGTHSubscription, IndicatorCDLKICKINGBYLENGTHSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLKICKINGBYLENGTHSubscription, IndicatorCDLKICKINGBYLENGTHSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLKICKINGBYLENGTHSubscription, IndicatorCDLKICKINGBYLENGTHSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLKICKINGBYLENGTHSubscription, IndicatorCDLKICKINGBYLENGTHSubscriptionVariables>(IndicatorCDLKICKINGBYLENGTHDocument, variables, options);
}
export type IndicatorCDLKICKINGBYLENGTHSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLKICKINGBYLENGTHSubscription, IndicatorCDLKICKINGBYLENGTHSubscriptionVariables>;
export const IndicatorCDLLADDERBOTTOMDocument = gql`
    subscription IndicatorCDLLADDERBOTTOM($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLLADDERBOTTOM(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLLADDERBOTTOMSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLLADDERBOTTOMSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLLADDERBOTTOMSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLLADDERBOTTOMSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLLADDERBOTTOMSubscription(variables: IndicatorCDLLADDERBOTTOMSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLLADDERBOTTOMSubscriptionVariables> | ReactiveFunction<IndicatorCDLLADDERBOTTOMSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLLADDERBOTTOMSubscription, IndicatorCDLLADDERBOTTOMSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLLADDERBOTTOMSubscription, IndicatorCDLLADDERBOTTOMSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLLADDERBOTTOMSubscription, IndicatorCDLLADDERBOTTOMSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLLADDERBOTTOMSubscription, IndicatorCDLLADDERBOTTOMSubscriptionVariables>(IndicatorCDLLADDERBOTTOMDocument, variables, options);
}
export type IndicatorCDLLADDERBOTTOMSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLLADDERBOTTOMSubscription, IndicatorCDLLADDERBOTTOMSubscriptionVariables>;
export const IndicatorCDLLONGLEGGEDDOJIDocument = gql`
    subscription IndicatorCDLLONGLEGGEDDOJI($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLLONGLEGGEDDOJI(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLLONGLEGGEDDOJISubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLLONGLEGGEDDOJISubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLLONGLEGGEDDOJISubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLLONGLEGGEDDOJISubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLLONGLEGGEDDOJISubscription(variables: IndicatorCDLLONGLEGGEDDOJISubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLLONGLEGGEDDOJISubscriptionVariables> | ReactiveFunction<IndicatorCDLLONGLEGGEDDOJISubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLLONGLEGGEDDOJISubscription, IndicatorCDLLONGLEGGEDDOJISubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLLONGLEGGEDDOJISubscription, IndicatorCDLLONGLEGGEDDOJISubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLLONGLEGGEDDOJISubscription, IndicatorCDLLONGLEGGEDDOJISubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLLONGLEGGEDDOJISubscription, IndicatorCDLLONGLEGGEDDOJISubscriptionVariables>(IndicatorCDLLONGLEGGEDDOJIDocument, variables, options);
}
export type IndicatorCDLLONGLEGGEDDOJISubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLLONGLEGGEDDOJISubscription, IndicatorCDLLONGLEGGEDDOJISubscriptionVariables>;
export const IndicatorCDLLONGLINEDocument = gql`
    subscription IndicatorCDLLONGLINE($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLLONGLINE(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLLONGLINESubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLLONGLINESubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLLONGLINESubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLLONGLINESubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLLONGLINESubscription(variables: IndicatorCDLLONGLINESubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLLONGLINESubscriptionVariables> | ReactiveFunction<IndicatorCDLLONGLINESubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLLONGLINESubscription, IndicatorCDLLONGLINESubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLLONGLINESubscription, IndicatorCDLLONGLINESubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLLONGLINESubscription, IndicatorCDLLONGLINESubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLLONGLINESubscription, IndicatorCDLLONGLINESubscriptionVariables>(IndicatorCDLLONGLINEDocument, variables, options);
}
export type IndicatorCDLLONGLINESubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLLONGLINESubscription, IndicatorCDLLONGLINESubscriptionVariables>;
export const IndicatorCDLMARUBOZUDocument = gql`
    subscription IndicatorCDLMARUBOZU($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLMARUBOZU(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLMARUBOZUSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLMARUBOZUSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLMARUBOZUSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLMARUBOZUSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLMARUBOZUSubscription(variables: IndicatorCDLMARUBOZUSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLMARUBOZUSubscriptionVariables> | ReactiveFunction<IndicatorCDLMARUBOZUSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLMARUBOZUSubscription, IndicatorCDLMARUBOZUSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLMARUBOZUSubscription, IndicatorCDLMARUBOZUSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLMARUBOZUSubscription, IndicatorCDLMARUBOZUSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLMARUBOZUSubscription, IndicatorCDLMARUBOZUSubscriptionVariables>(IndicatorCDLMARUBOZUDocument, variables, options);
}
export type IndicatorCDLMARUBOZUSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLMARUBOZUSubscription, IndicatorCDLMARUBOZUSubscriptionVariables>;
export const IndicatorCDLMATCHINGLOWDocument = gql`
    subscription IndicatorCDLMATCHINGLOW($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLMATCHINGLOW(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLMATCHINGLOWSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLMATCHINGLOWSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLMATCHINGLOWSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLMATCHINGLOWSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLMATCHINGLOWSubscription(variables: IndicatorCDLMATCHINGLOWSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLMATCHINGLOWSubscriptionVariables> | ReactiveFunction<IndicatorCDLMATCHINGLOWSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLMATCHINGLOWSubscription, IndicatorCDLMATCHINGLOWSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLMATCHINGLOWSubscription, IndicatorCDLMATCHINGLOWSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLMATCHINGLOWSubscription, IndicatorCDLMATCHINGLOWSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLMATCHINGLOWSubscription, IndicatorCDLMATCHINGLOWSubscriptionVariables>(IndicatorCDLMATCHINGLOWDocument, variables, options);
}
export type IndicatorCDLMATCHINGLOWSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLMATCHINGLOWSubscription, IndicatorCDLMATCHINGLOWSubscriptionVariables>;
export const IndicatorCDLMATHOLDDocument = gql`
    subscription IndicatorCDLMATHOLD($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optPenetration: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLMATHOLD(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optPenetration: $optPenetration
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLMATHOLDSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLMATHOLDSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLMATHOLDSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLMATHOLDSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optPenetration: // value for 'optPenetration'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLMATHOLDSubscription(variables: IndicatorCDLMATHOLDSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLMATHOLDSubscriptionVariables> | ReactiveFunction<IndicatorCDLMATHOLDSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLMATHOLDSubscription, IndicatorCDLMATHOLDSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLMATHOLDSubscription, IndicatorCDLMATHOLDSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLMATHOLDSubscription, IndicatorCDLMATHOLDSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLMATHOLDSubscription, IndicatorCDLMATHOLDSubscriptionVariables>(IndicatorCDLMATHOLDDocument, variables, options);
}
export type IndicatorCDLMATHOLDSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLMATHOLDSubscription, IndicatorCDLMATHOLDSubscriptionVariables>;
export const IndicatorCDLMORNINGDOJISTARDocument = gql`
    subscription IndicatorCDLMORNINGDOJISTAR($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optPenetration: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLMORNINGDOJISTAR(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optPenetration: $optPenetration
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLMORNINGDOJISTARSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLMORNINGDOJISTARSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLMORNINGDOJISTARSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLMORNINGDOJISTARSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optPenetration: // value for 'optPenetration'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLMORNINGDOJISTARSubscription(variables: IndicatorCDLMORNINGDOJISTARSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLMORNINGDOJISTARSubscriptionVariables> | ReactiveFunction<IndicatorCDLMORNINGDOJISTARSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLMORNINGDOJISTARSubscription, IndicatorCDLMORNINGDOJISTARSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLMORNINGDOJISTARSubscription, IndicatorCDLMORNINGDOJISTARSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLMORNINGDOJISTARSubscription, IndicatorCDLMORNINGDOJISTARSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLMORNINGDOJISTARSubscription, IndicatorCDLMORNINGDOJISTARSubscriptionVariables>(IndicatorCDLMORNINGDOJISTARDocument, variables, options);
}
export type IndicatorCDLMORNINGDOJISTARSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLMORNINGDOJISTARSubscription, IndicatorCDLMORNINGDOJISTARSubscriptionVariables>;
export const IndicatorCDLMORNINGSTARDocument = gql`
    subscription IndicatorCDLMORNINGSTAR($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optPenetration: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLMORNINGSTAR(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optPenetration: $optPenetration
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLMORNINGSTARSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLMORNINGSTARSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLMORNINGSTARSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLMORNINGSTARSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optPenetration: // value for 'optPenetration'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLMORNINGSTARSubscription(variables: IndicatorCDLMORNINGSTARSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLMORNINGSTARSubscriptionVariables> | ReactiveFunction<IndicatorCDLMORNINGSTARSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLMORNINGSTARSubscription, IndicatorCDLMORNINGSTARSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLMORNINGSTARSubscription, IndicatorCDLMORNINGSTARSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLMORNINGSTARSubscription, IndicatorCDLMORNINGSTARSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLMORNINGSTARSubscription, IndicatorCDLMORNINGSTARSubscriptionVariables>(IndicatorCDLMORNINGSTARDocument, variables, options);
}
export type IndicatorCDLMORNINGSTARSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLMORNINGSTARSubscription, IndicatorCDLMORNINGSTARSubscriptionVariables>;
export const IndicatorCDLONNECKDocument = gql`
    subscription IndicatorCDLONNECK($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLONNECK(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLONNECKSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLONNECKSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLONNECKSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLONNECKSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLONNECKSubscription(variables: IndicatorCDLONNECKSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLONNECKSubscriptionVariables> | ReactiveFunction<IndicatorCDLONNECKSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLONNECKSubscription, IndicatorCDLONNECKSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLONNECKSubscription, IndicatorCDLONNECKSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLONNECKSubscription, IndicatorCDLONNECKSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLONNECKSubscription, IndicatorCDLONNECKSubscriptionVariables>(IndicatorCDLONNECKDocument, variables, options);
}
export type IndicatorCDLONNECKSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLONNECKSubscription, IndicatorCDLONNECKSubscriptionVariables>;
export const IndicatorCDLPIERCINGDocument = gql`
    subscription IndicatorCDLPIERCING($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLPIERCING(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLPIERCINGSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLPIERCINGSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLPIERCINGSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLPIERCINGSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLPIERCINGSubscription(variables: IndicatorCDLPIERCINGSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLPIERCINGSubscriptionVariables> | ReactiveFunction<IndicatorCDLPIERCINGSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLPIERCINGSubscription, IndicatorCDLPIERCINGSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLPIERCINGSubscription, IndicatorCDLPIERCINGSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLPIERCINGSubscription, IndicatorCDLPIERCINGSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLPIERCINGSubscription, IndicatorCDLPIERCINGSubscriptionVariables>(IndicatorCDLPIERCINGDocument, variables, options);
}
export type IndicatorCDLPIERCINGSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLPIERCINGSubscription, IndicatorCDLPIERCINGSubscriptionVariables>;
export const IndicatorCDLRICKSHAWMANDocument = gql`
    subscription IndicatorCDLRICKSHAWMAN($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLRICKSHAWMAN(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLRICKSHAWMANSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLRICKSHAWMANSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLRICKSHAWMANSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLRICKSHAWMANSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLRICKSHAWMANSubscription(variables: IndicatorCDLRICKSHAWMANSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLRICKSHAWMANSubscriptionVariables> | ReactiveFunction<IndicatorCDLRICKSHAWMANSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLRICKSHAWMANSubscription, IndicatorCDLRICKSHAWMANSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLRICKSHAWMANSubscription, IndicatorCDLRICKSHAWMANSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLRICKSHAWMANSubscription, IndicatorCDLRICKSHAWMANSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLRICKSHAWMANSubscription, IndicatorCDLRICKSHAWMANSubscriptionVariables>(IndicatorCDLRICKSHAWMANDocument, variables, options);
}
export type IndicatorCDLRICKSHAWMANSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLRICKSHAWMANSubscription, IndicatorCDLRICKSHAWMANSubscriptionVariables>;
export const IndicatorCDLRISEFALL3METHODSDocument = gql`
    subscription IndicatorCDLRISEFALL3METHODS($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLRISEFALL3METHODS(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLRISEFALL3METHODSSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLRISEFALL3METHODSSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLRISEFALL3METHODSSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLRISEFALL3METHODSSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLRISEFALL3METHODSSubscription(variables: IndicatorCDLRISEFALL3METHODSSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLRISEFALL3METHODSSubscriptionVariables> | ReactiveFunction<IndicatorCDLRISEFALL3METHODSSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLRISEFALL3METHODSSubscription, IndicatorCDLRISEFALL3METHODSSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLRISEFALL3METHODSSubscription, IndicatorCDLRISEFALL3METHODSSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLRISEFALL3METHODSSubscription, IndicatorCDLRISEFALL3METHODSSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLRISEFALL3METHODSSubscription, IndicatorCDLRISEFALL3METHODSSubscriptionVariables>(IndicatorCDLRISEFALL3METHODSDocument, variables, options);
}
export type IndicatorCDLRISEFALL3METHODSSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLRISEFALL3METHODSSubscription, IndicatorCDLRISEFALL3METHODSSubscriptionVariables>;
export const IndicatorCDLSEPARATINGLINESDocument = gql`
    subscription IndicatorCDLSEPARATINGLINES($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLSEPARATINGLINES(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLSEPARATINGLINESSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLSEPARATINGLINESSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLSEPARATINGLINESSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLSEPARATINGLINESSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLSEPARATINGLINESSubscription(variables: IndicatorCDLSEPARATINGLINESSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLSEPARATINGLINESSubscriptionVariables> | ReactiveFunction<IndicatorCDLSEPARATINGLINESSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLSEPARATINGLINESSubscription, IndicatorCDLSEPARATINGLINESSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLSEPARATINGLINESSubscription, IndicatorCDLSEPARATINGLINESSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLSEPARATINGLINESSubscription, IndicatorCDLSEPARATINGLINESSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLSEPARATINGLINESSubscription, IndicatorCDLSEPARATINGLINESSubscriptionVariables>(IndicatorCDLSEPARATINGLINESDocument, variables, options);
}
export type IndicatorCDLSEPARATINGLINESSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLSEPARATINGLINESSubscription, IndicatorCDLSEPARATINGLINESSubscriptionVariables>;
export const IndicatorCDLSHOOTINGSTARDocument = gql`
    subscription IndicatorCDLSHOOTINGSTAR($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLSHOOTINGSTAR(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLSHOOTINGSTARSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLSHOOTINGSTARSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLSHOOTINGSTARSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLSHOOTINGSTARSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLSHOOTINGSTARSubscription(variables: IndicatorCDLSHOOTINGSTARSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLSHOOTINGSTARSubscriptionVariables> | ReactiveFunction<IndicatorCDLSHOOTINGSTARSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLSHOOTINGSTARSubscription, IndicatorCDLSHOOTINGSTARSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLSHOOTINGSTARSubscription, IndicatorCDLSHOOTINGSTARSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLSHOOTINGSTARSubscription, IndicatorCDLSHOOTINGSTARSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLSHOOTINGSTARSubscription, IndicatorCDLSHOOTINGSTARSubscriptionVariables>(IndicatorCDLSHOOTINGSTARDocument, variables, options);
}
export type IndicatorCDLSHOOTINGSTARSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLSHOOTINGSTARSubscription, IndicatorCDLSHOOTINGSTARSubscriptionVariables>;
export const IndicatorCDLSHORTLINEDocument = gql`
    subscription IndicatorCDLSHORTLINE($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLSHORTLINE(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLSHORTLINESubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLSHORTLINESubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLSHORTLINESubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLSHORTLINESubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLSHORTLINESubscription(variables: IndicatorCDLSHORTLINESubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLSHORTLINESubscriptionVariables> | ReactiveFunction<IndicatorCDLSHORTLINESubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLSHORTLINESubscription, IndicatorCDLSHORTLINESubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLSHORTLINESubscription, IndicatorCDLSHORTLINESubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLSHORTLINESubscription, IndicatorCDLSHORTLINESubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLSHORTLINESubscription, IndicatorCDLSHORTLINESubscriptionVariables>(IndicatorCDLSHORTLINEDocument, variables, options);
}
export type IndicatorCDLSHORTLINESubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLSHORTLINESubscription, IndicatorCDLSHORTLINESubscriptionVariables>;
export const IndicatorCDLSPINNINGTOPDocument = gql`
    subscription IndicatorCDLSPINNINGTOP($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLSPINNINGTOP(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLSPINNINGTOPSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLSPINNINGTOPSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLSPINNINGTOPSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLSPINNINGTOPSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLSPINNINGTOPSubscription(variables: IndicatorCDLSPINNINGTOPSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLSPINNINGTOPSubscriptionVariables> | ReactiveFunction<IndicatorCDLSPINNINGTOPSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLSPINNINGTOPSubscription, IndicatorCDLSPINNINGTOPSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLSPINNINGTOPSubscription, IndicatorCDLSPINNINGTOPSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLSPINNINGTOPSubscription, IndicatorCDLSPINNINGTOPSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLSPINNINGTOPSubscription, IndicatorCDLSPINNINGTOPSubscriptionVariables>(IndicatorCDLSPINNINGTOPDocument, variables, options);
}
export type IndicatorCDLSPINNINGTOPSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLSPINNINGTOPSubscription, IndicatorCDLSPINNINGTOPSubscriptionVariables>;
export const IndicatorCDLSTALLEDPATTERNDocument = gql`
    subscription IndicatorCDLSTALLEDPATTERN($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLSTALLEDPATTERN(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLSTALLEDPATTERNSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLSTALLEDPATTERNSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLSTALLEDPATTERNSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLSTALLEDPATTERNSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLSTALLEDPATTERNSubscription(variables: IndicatorCDLSTALLEDPATTERNSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLSTALLEDPATTERNSubscriptionVariables> | ReactiveFunction<IndicatorCDLSTALLEDPATTERNSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLSTALLEDPATTERNSubscription, IndicatorCDLSTALLEDPATTERNSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLSTALLEDPATTERNSubscription, IndicatorCDLSTALLEDPATTERNSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLSTALLEDPATTERNSubscription, IndicatorCDLSTALLEDPATTERNSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLSTALLEDPATTERNSubscription, IndicatorCDLSTALLEDPATTERNSubscriptionVariables>(IndicatorCDLSTALLEDPATTERNDocument, variables, options);
}
export type IndicatorCDLSTALLEDPATTERNSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLSTALLEDPATTERNSubscription, IndicatorCDLSTALLEDPATTERNSubscriptionVariables>;
export const IndicatorCDLSTICKSANDWICHDocument = gql`
    subscription IndicatorCDLSTICKSANDWICH($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLSTICKSANDWICH(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLSTICKSANDWICHSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLSTICKSANDWICHSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLSTICKSANDWICHSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLSTICKSANDWICHSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLSTICKSANDWICHSubscription(variables: IndicatorCDLSTICKSANDWICHSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLSTICKSANDWICHSubscriptionVariables> | ReactiveFunction<IndicatorCDLSTICKSANDWICHSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLSTICKSANDWICHSubscription, IndicatorCDLSTICKSANDWICHSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLSTICKSANDWICHSubscription, IndicatorCDLSTICKSANDWICHSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLSTICKSANDWICHSubscription, IndicatorCDLSTICKSANDWICHSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLSTICKSANDWICHSubscription, IndicatorCDLSTICKSANDWICHSubscriptionVariables>(IndicatorCDLSTICKSANDWICHDocument, variables, options);
}
export type IndicatorCDLSTICKSANDWICHSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLSTICKSANDWICHSubscription, IndicatorCDLSTICKSANDWICHSubscriptionVariables>;
export const IndicatorCDLTAKURIDocument = gql`
    subscription IndicatorCDLTAKURI($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLTAKURI(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLTAKURISubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLTAKURISubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLTAKURISubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLTAKURISubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLTAKURISubscription(variables: IndicatorCDLTAKURISubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLTAKURISubscriptionVariables> | ReactiveFunction<IndicatorCDLTAKURISubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLTAKURISubscription, IndicatorCDLTAKURISubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLTAKURISubscription, IndicatorCDLTAKURISubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLTAKURISubscription, IndicatorCDLTAKURISubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLTAKURISubscription, IndicatorCDLTAKURISubscriptionVariables>(IndicatorCDLTAKURIDocument, variables, options);
}
export type IndicatorCDLTAKURISubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLTAKURISubscription, IndicatorCDLTAKURISubscriptionVariables>;
export const IndicatorCDLTASUKIGAPDocument = gql`
    subscription IndicatorCDLTASUKIGAP($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLTASUKIGAP(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLTASUKIGAPSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLTASUKIGAPSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLTASUKIGAPSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLTASUKIGAPSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLTASUKIGAPSubscription(variables: IndicatorCDLTASUKIGAPSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLTASUKIGAPSubscriptionVariables> | ReactiveFunction<IndicatorCDLTASUKIGAPSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLTASUKIGAPSubscription, IndicatorCDLTASUKIGAPSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLTASUKIGAPSubscription, IndicatorCDLTASUKIGAPSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLTASUKIGAPSubscription, IndicatorCDLTASUKIGAPSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLTASUKIGAPSubscription, IndicatorCDLTASUKIGAPSubscriptionVariables>(IndicatorCDLTASUKIGAPDocument, variables, options);
}
export type IndicatorCDLTASUKIGAPSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLTASUKIGAPSubscription, IndicatorCDLTASUKIGAPSubscriptionVariables>;
export const IndicatorCDLTHRUSTINGDocument = gql`
    subscription IndicatorCDLTHRUSTING($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLTHRUSTING(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLTHRUSTINGSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLTHRUSTINGSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLTHRUSTINGSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLTHRUSTINGSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLTHRUSTINGSubscription(variables: IndicatorCDLTHRUSTINGSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLTHRUSTINGSubscriptionVariables> | ReactiveFunction<IndicatorCDLTHRUSTINGSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLTHRUSTINGSubscription, IndicatorCDLTHRUSTINGSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLTHRUSTINGSubscription, IndicatorCDLTHRUSTINGSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLTHRUSTINGSubscription, IndicatorCDLTHRUSTINGSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLTHRUSTINGSubscription, IndicatorCDLTHRUSTINGSubscriptionVariables>(IndicatorCDLTHRUSTINGDocument, variables, options);
}
export type IndicatorCDLTHRUSTINGSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLTHRUSTINGSubscription, IndicatorCDLTHRUSTINGSubscriptionVariables>;
export const IndicatorCDLTRISTARDocument = gql`
    subscription IndicatorCDLTRISTAR($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLTRISTAR(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLTRISTARSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLTRISTARSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLTRISTARSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLTRISTARSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLTRISTARSubscription(variables: IndicatorCDLTRISTARSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLTRISTARSubscriptionVariables> | ReactiveFunction<IndicatorCDLTRISTARSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLTRISTARSubscription, IndicatorCDLTRISTARSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLTRISTARSubscription, IndicatorCDLTRISTARSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLTRISTARSubscription, IndicatorCDLTRISTARSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLTRISTARSubscription, IndicatorCDLTRISTARSubscriptionVariables>(IndicatorCDLTRISTARDocument, variables, options);
}
export type IndicatorCDLTRISTARSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLTRISTARSubscription, IndicatorCDLTRISTARSubscriptionVariables>;
export const IndicatorCDLUNIQUE3RIVERDocument = gql`
    subscription IndicatorCDLUNIQUE3RIVER($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLUNIQUE3RIVER(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLUNIQUE3RIVERSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLUNIQUE3RIVERSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLUNIQUE3RIVERSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLUNIQUE3RIVERSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLUNIQUE3RIVERSubscription(variables: IndicatorCDLUNIQUE3RIVERSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLUNIQUE3RIVERSubscriptionVariables> | ReactiveFunction<IndicatorCDLUNIQUE3RIVERSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLUNIQUE3RIVERSubscription, IndicatorCDLUNIQUE3RIVERSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLUNIQUE3RIVERSubscription, IndicatorCDLUNIQUE3RIVERSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLUNIQUE3RIVERSubscription, IndicatorCDLUNIQUE3RIVERSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLUNIQUE3RIVERSubscription, IndicatorCDLUNIQUE3RIVERSubscriptionVariables>(IndicatorCDLUNIQUE3RIVERDocument, variables, options);
}
export type IndicatorCDLUNIQUE3RIVERSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLUNIQUE3RIVERSubscription, IndicatorCDLUNIQUE3RIVERSubscriptionVariables>;
export const IndicatorCDLUPSIDEGAP2CROWSDocument = gql`
    subscription IndicatorCDLUPSIDEGAP2CROWS($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLUPSIDEGAP2CROWS(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLUPSIDEGAP2CROWSSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLUPSIDEGAP2CROWSSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLUPSIDEGAP2CROWSSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLUPSIDEGAP2CROWSSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLUPSIDEGAP2CROWSSubscription(variables: IndicatorCDLUPSIDEGAP2CROWSSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLUPSIDEGAP2CROWSSubscriptionVariables> | ReactiveFunction<IndicatorCDLUPSIDEGAP2CROWSSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLUPSIDEGAP2CROWSSubscription, IndicatorCDLUPSIDEGAP2CROWSSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLUPSIDEGAP2CROWSSubscription, IndicatorCDLUPSIDEGAP2CROWSSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLUPSIDEGAP2CROWSSubscription, IndicatorCDLUPSIDEGAP2CROWSSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLUPSIDEGAP2CROWSSubscription, IndicatorCDLUPSIDEGAP2CROWSSubscriptionVariables>(IndicatorCDLUPSIDEGAP2CROWSDocument, variables, options);
}
export type IndicatorCDLUPSIDEGAP2CROWSSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLUPSIDEGAP2CROWSSubscription, IndicatorCDLUPSIDEGAP2CROWSSubscriptionVariables>;
export const IndicatorCDLXSIDEGAP3METHODSDocument = gql`
    subscription IndicatorCDLXSIDEGAP3METHODS($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCDLXSIDEGAP3METHODS(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCDLXSIDEGAP3METHODSSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCDLXSIDEGAP3METHODSSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCDLXSIDEGAP3METHODSSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCDLXSIDEGAP3METHODSSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCDLXSIDEGAP3METHODSSubscription(variables: IndicatorCDLXSIDEGAP3METHODSSubscriptionVariables | VueCompositionApi.Ref<IndicatorCDLXSIDEGAP3METHODSSubscriptionVariables> | ReactiveFunction<IndicatorCDLXSIDEGAP3METHODSSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCDLXSIDEGAP3METHODSSubscription, IndicatorCDLXSIDEGAP3METHODSSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLXSIDEGAP3METHODSSubscription, IndicatorCDLXSIDEGAP3METHODSSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCDLXSIDEGAP3METHODSSubscription, IndicatorCDLXSIDEGAP3METHODSSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCDLXSIDEGAP3METHODSSubscription, IndicatorCDLXSIDEGAP3METHODSSubscriptionVariables>(IndicatorCDLXSIDEGAP3METHODSDocument, variables, options);
}
export type IndicatorCDLXSIDEGAP3METHODSSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCDLXSIDEGAP3METHODSSubscription, IndicatorCDLXSIDEGAP3METHODSSubscriptionVariables>;
export const IndicatorCEILDocument = gql`
    subscription IndicatorCEIL($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCEIL(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCEILSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCEILSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCEILSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCEILSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCEILSubscription(variables: IndicatorCEILSubscriptionVariables | VueCompositionApi.Ref<IndicatorCEILSubscriptionVariables> | ReactiveFunction<IndicatorCEILSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCEILSubscription, IndicatorCEILSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCEILSubscription, IndicatorCEILSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCEILSubscription, IndicatorCEILSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCEILSubscription, IndicatorCEILSubscriptionVariables>(IndicatorCEILDocument, variables, options);
}
export type IndicatorCEILSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCEILSubscription, IndicatorCEILSubscriptionVariables>;
export const IndicatorCMODocument = gql`
    subscription IndicatorCMO($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorCMO(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCMOSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCMOSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCMOSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCMOSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCMOSubscription(variables: IndicatorCMOSubscriptionVariables | VueCompositionApi.Ref<IndicatorCMOSubscriptionVariables> | ReactiveFunction<IndicatorCMOSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCMOSubscription, IndicatorCMOSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCMOSubscription, IndicatorCMOSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCMOSubscription, IndicatorCMOSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCMOSubscription, IndicatorCMOSubscriptionVariables>(IndicatorCMODocument, variables, options);
}
export type IndicatorCMOSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCMOSubscription, IndicatorCMOSubscriptionVariables>;
export const IndicatorCORRELDocument = gql`
    subscription IndicatorCORREL($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorCORREL(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCORRELSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCORRELSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCORRELSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCORRELSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCORRELSubscription(variables: IndicatorCORRELSubscriptionVariables | VueCompositionApi.Ref<IndicatorCORRELSubscriptionVariables> | ReactiveFunction<IndicatorCORRELSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCORRELSubscription, IndicatorCORRELSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCORRELSubscription, IndicatorCORRELSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCORRELSubscription, IndicatorCORRELSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCORRELSubscription, IndicatorCORRELSubscriptionVariables>(IndicatorCORRELDocument, variables, options);
}
export type IndicatorCORRELSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCORRELSubscription, IndicatorCORRELSubscriptionVariables>;
export const IndicatorCOSHDocument = gql`
    subscription IndicatorCOSH($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorCOSH(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorCOSHSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorCOSHSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorCOSHSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorCOSHSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorCOSHSubscription(variables: IndicatorCOSHSubscriptionVariables | VueCompositionApi.Ref<IndicatorCOSHSubscriptionVariables> | ReactiveFunction<IndicatorCOSHSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorCOSHSubscription, IndicatorCOSHSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorCOSHSubscription, IndicatorCOSHSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorCOSHSubscription, IndicatorCOSHSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorCOSHSubscription, IndicatorCOSHSubscriptionVariables>(IndicatorCOSHDocument, variables, options);
}
export type IndicatorCOSHSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorCOSHSubscription, IndicatorCOSHSubscriptionVariables>;
export const IndicatorDEMADocument = gql`
    subscription IndicatorDEMA($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorDEMA(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorDEMASubscription__
 *
 * To run a query within a Vue component, call `useIndicatorDEMASubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorDEMASubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorDEMASubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorDEMASubscription(variables: IndicatorDEMASubscriptionVariables | VueCompositionApi.Ref<IndicatorDEMASubscriptionVariables> | ReactiveFunction<IndicatorDEMASubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorDEMASubscription, IndicatorDEMASubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorDEMASubscription, IndicatorDEMASubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorDEMASubscription, IndicatorDEMASubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorDEMASubscription, IndicatorDEMASubscriptionVariables>(IndicatorDEMADocument, variables, options);
}
export type IndicatorDEMASubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorDEMASubscription, IndicatorDEMASubscriptionVariables>;
export const IndicatorDIVDocument = gql`
    subscription IndicatorDIV($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorDIV(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorDIVSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorDIVSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorDIVSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorDIVSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorDIVSubscription(variables: IndicatorDIVSubscriptionVariables | VueCompositionApi.Ref<IndicatorDIVSubscriptionVariables> | ReactiveFunction<IndicatorDIVSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorDIVSubscription, IndicatorDIVSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorDIVSubscription, IndicatorDIVSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorDIVSubscription, IndicatorDIVSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorDIVSubscription, IndicatorDIVSubscriptionVariables>(IndicatorDIVDocument, variables, options);
}
export type IndicatorDIVSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorDIVSubscription, IndicatorDIVSubscriptionVariables>;
export const IndicatorEXPDocument = gql`
    subscription IndicatorEXP($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorEXP(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorEXPSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorEXPSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorEXPSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorEXPSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorEXPSubscription(variables: IndicatorEXPSubscriptionVariables | VueCompositionApi.Ref<IndicatorEXPSubscriptionVariables> | ReactiveFunction<IndicatorEXPSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorEXPSubscription, IndicatorEXPSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorEXPSubscription, IndicatorEXPSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorEXPSubscription, IndicatorEXPSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorEXPSubscription, IndicatorEXPSubscriptionVariables>(IndicatorEXPDocument, variables, options);
}
export type IndicatorEXPSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorEXPSubscription, IndicatorEXPSubscriptionVariables>;
export const IndicatorFLOORDocument = gql`
    subscription IndicatorFLOOR($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorFLOOR(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorFLOORSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorFLOORSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorFLOORSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorFLOORSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorFLOORSubscription(variables: IndicatorFLOORSubscriptionVariables | VueCompositionApi.Ref<IndicatorFLOORSubscriptionVariables> | ReactiveFunction<IndicatorFLOORSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorFLOORSubscription, IndicatorFLOORSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorFLOORSubscription, IndicatorFLOORSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorFLOORSubscription, IndicatorFLOORSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorFLOORSubscription, IndicatorFLOORSubscriptionVariables>(IndicatorFLOORDocument, variables, options);
}
export type IndicatorFLOORSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorFLOORSubscription, IndicatorFLOORSubscriptionVariables>;
export const IndicatorHT_DCPERIODDocument = gql`
    subscription IndicatorHT_DCPERIOD($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorHT_DCPERIOD(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorHT_DCPERIODSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorHT_DCPERIODSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorHT_DCPERIODSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorHT_DCPERIODSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorHT_DCPERIODSubscription(variables: IndicatorHT_DCPERIODSubscriptionVariables | VueCompositionApi.Ref<IndicatorHT_DCPERIODSubscriptionVariables> | ReactiveFunction<IndicatorHT_DCPERIODSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorHT_DCPERIODSubscription, IndicatorHT_DCPERIODSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorHT_DCPERIODSubscription, IndicatorHT_DCPERIODSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorHT_DCPERIODSubscription, IndicatorHT_DCPERIODSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorHT_DCPERIODSubscription, IndicatorHT_DCPERIODSubscriptionVariables>(IndicatorHT_DCPERIODDocument, variables, options);
}
export type IndicatorHT_DCPERIODSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorHT_DCPERIODSubscription, IndicatorHT_DCPERIODSubscriptionVariables>;
export const IndicatorHT_DCPHASEDocument = gql`
    subscription IndicatorHT_DCPHASE($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorHT_DCPHASE(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorHT_DCPHASESubscription__
 *
 * To run a query within a Vue component, call `useIndicatorHT_DCPHASESubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorHT_DCPHASESubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorHT_DCPHASESubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorHT_DCPHASESubscription(variables: IndicatorHT_DCPHASESubscriptionVariables | VueCompositionApi.Ref<IndicatorHT_DCPHASESubscriptionVariables> | ReactiveFunction<IndicatorHT_DCPHASESubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorHT_DCPHASESubscription, IndicatorHT_DCPHASESubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorHT_DCPHASESubscription, IndicatorHT_DCPHASESubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorHT_DCPHASESubscription, IndicatorHT_DCPHASESubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorHT_DCPHASESubscription, IndicatorHT_DCPHASESubscriptionVariables>(IndicatorHT_DCPHASEDocument, variables, options);
}
export type IndicatorHT_DCPHASESubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorHT_DCPHASESubscription, IndicatorHT_DCPHASESubscriptionVariables>;
export const IndicatorHT_PHASORDocument = gql`
    subscription IndicatorHT_PHASOR($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorHT_PHASOR(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorHT_PHASORSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorHT_PHASORSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorHT_PHASORSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorHT_PHASORSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorHT_PHASORSubscription(variables: IndicatorHT_PHASORSubscriptionVariables | VueCompositionApi.Ref<IndicatorHT_PHASORSubscriptionVariables> | ReactiveFunction<IndicatorHT_PHASORSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorHT_PHASORSubscription, IndicatorHT_PHASORSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorHT_PHASORSubscription, IndicatorHT_PHASORSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorHT_PHASORSubscription, IndicatorHT_PHASORSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorHT_PHASORSubscription, IndicatorHT_PHASORSubscriptionVariables>(IndicatorHT_PHASORDocument, variables, options);
}
export type IndicatorHT_PHASORSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorHT_PHASORSubscription, IndicatorHT_PHASORSubscriptionVariables>;
export const IndicatorHT_SINEDocument = gql`
    subscription IndicatorHT_SINE($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorHT_SINE(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorHT_SINESubscription__
 *
 * To run a query within a Vue component, call `useIndicatorHT_SINESubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorHT_SINESubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorHT_SINESubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorHT_SINESubscription(variables: IndicatorHT_SINESubscriptionVariables | VueCompositionApi.Ref<IndicatorHT_SINESubscriptionVariables> | ReactiveFunction<IndicatorHT_SINESubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorHT_SINESubscription, IndicatorHT_SINESubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorHT_SINESubscription, IndicatorHT_SINESubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorHT_SINESubscription, IndicatorHT_SINESubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorHT_SINESubscription, IndicatorHT_SINESubscriptionVariables>(IndicatorHT_SINEDocument, variables, options);
}
export type IndicatorHT_SINESubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorHT_SINESubscription, IndicatorHT_SINESubscriptionVariables>;
export const IndicatorHT_TRENDLINEDocument = gql`
    subscription IndicatorHT_TRENDLINE($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorHT_TRENDLINE(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorHT_TRENDLINESubscription__
 *
 * To run a query within a Vue component, call `useIndicatorHT_TRENDLINESubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorHT_TRENDLINESubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorHT_TRENDLINESubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorHT_TRENDLINESubscription(variables: IndicatorHT_TRENDLINESubscriptionVariables | VueCompositionApi.Ref<IndicatorHT_TRENDLINESubscriptionVariables> | ReactiveFunction<IndicatorHT_TRENDLINESubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorHT_TRENDLINESubscription, IndicatorHT_TRENDLINESubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorHT_TRENDLINESubscription, IndicatorHT_TRENDLINESubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorHT_TRENDLINESubscription, IndicatorHT_TRENDLINESubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorHT_TRENDLINESubscription, IndicatorHT_TRENDLINESubscriptionVariables>(IndicatorHT_TRENDLINEDocument, variables, options);
}
export type IndicatorHT_TRENDLINESubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorHT_TRENDLINESubscription, IndicatorHT_TRENDLINESubscriptionVariables>;
export const IndicatorHT_TRENDMODEDocument = gql`
    subscription IndicatorHT_TRENDMODE($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorHT_TRENDMODE(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorHT_TRENDMODESubscription__
 *
 * To run a query within a Vue component, call `useIndicatorHT_TRENDMODESubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorHT_TRENDMODESubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorHT_TRENDMODESubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorHT_TRENDMODESubscription(variables: IndicatorHT_TRENDMODESubscriptionVariables | VueCompositionApi.Ref<IndicatorHT_TRENDMODESubscriptionVariables> | ReactiveFunction<IndicatorHT_TRENDMODESubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorHT_TRENDMODESubscription, IndicatorHT_TRENDMODESubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorHT_TRENDMODESubscription, IndicatorHT_TRENDMODESubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorHT_TRENDMODESubscription, IndicatorHT_TRENDMODESubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorHT_TRENDMODESubscription, IndicatorHT_TRENDMODESubscriptionVariables>(IndicatorHT_TRENDMODEDocument, variables, options);
}
export type IndicatorHT_TRENDMODESubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorHT_TRENDMODESubscription, IndicatorHT_TRENDMODESubscriptionVariables>;
export const IndicatorIMIDocument = gql`
    subscription IndicatorIMI($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorIMI(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorIMISubscription__
 *
 * To run a query within a Vue component, call `useIndicatorIMISubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorIMISubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorIMISubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorIMISubscription(variables: IndicatorIMISubscriptionVariables | VueCompositionApi.Ref<IndicatorIMISubscriptionVariables> | ReactiveFunction<IndicatorIMISubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorIMISubscription, IndicatorIMISubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorIMISubscription, IndicatorIMISubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorIMISubscription, IndicatorIMISubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorIMISubscription, IndicatorIMISubscriptionVariables>(IndicatorIMIDocument, variables, options);
}
export type IndicatorIMISubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorIMISubscription, IndicatorIMISubscriptionVariables>;
export const IndicatorKAMADocument = gql`
    subscription IndicatorKAMA($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorKAMA(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorKAMASubscription__
 *
 * To run a query within a Vue component, call `useIndicatorKAMASubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorKAMASubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorKAMASubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorKAMASubscription(variables: IndicatorKAMASubscriptionVariables | VueCompositionApi.Ref<IndicatorKAMASubscriptionVariables> | ReactiveFunction<IndicatorKAMASubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorKAMASubscription, IndicatorKAMASubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorKAMASubscription, IndicatorKAMASubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorKAMASubscription, IndicatorKAMASubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorKAMASubscription, IndicatorKAMASubscriptionVariables>(IndicatorKAMADocument, variables, options);
}
export type IndicatorKAMASubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorKAMASubscription, IndicatorKAMASubscriptionVariables>;
export const IndicatorLINEARREGDocument = gql`
    subscription IndicatorLINEARREG($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorLINEARREG(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorLINEARREGSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorLINEARREGSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorLINEARREGSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorLINEARREGSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorLINEARREGSubscription(variables: IndicatorLINEARREGSubscriptionVariables | VueCompositionApi.Ref<IndicatorLINEARREGSubscriptionVariables> | ReactiveFunction<IndicatorLINEARREGSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorLINEARREGSubscription, IndicatorLINEARREGSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorLINEARREGSubscription, IndicatorLINEARREGSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorLINEARREGSubscription, IndicatorLINEARREGSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorLINEARREGSubscription, IndicatorLINEARREGSubscriptionVariables>(IndicatorLINEARREGDocument, variables, options);
}
export type IndicatorLINEARREGSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorLINEARREGSubscription, IndicatorLINEARREGSubscriptionVariables>;
export const IndicatorLINEARREG_ANGLEDocument = gql`
    subscription IndicatorLINEARREG_ANGLE($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorLINEARREG_ANGLE(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorLINEARREG_ANGLESubscription__
 *
 * To run a query within a Vue component, call `useIndicatorLINEARREG_ANGLESubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorLINEARREG_ANGLESubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorLINEARREG_ANGLESubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorLINEARREG_ANGLESubscription(variables: IndicatorLINEARREG_ANGLESubscriptionVariables | VueCompositionApi.Ref<IndicatorLINEARREG_ANGLESubscriptionVariables> | ReactiveFunction<IndicatorLINEARREG_ANGLESubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorLINEARREG_ANGLESubscription, IndicatorLINEARREG_ANGLESubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorLINEARREG_ANGLESubscription, IndicatorLINEARREG_ANGLESubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorLINEARREG_ANGLESubscription, IndicatorLINEARREG_ANGLESubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorLINEARREG_ANGLESubscription, IndicatorLINEARREG_ANGLESubscriptionVariables>(IndicatorLINEARREG_ANGLEDocument, variables, options);
}
export type IndicatorLINEARREG_ANGLESubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorLINEARREG_ANGLESubscription, IndicatorLINEARREG_ANGLESubscriptionVariables>;
export const IndicatorLINEARREG_INTERCEPTDocument = gql`
    subscription IndicatorLINEARREG_INTERCEPT($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorLINEARREG_INTERCEPT(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorLINEARREG_INTERCEPTSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorLINEARREG_INTERCEPTSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorLINEARREG_INTERCEPTSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorLINEARREG_INTERCEPTSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorLINEARREG_INTERCEPTSubscription(variables: IndicatorLINEARREG_INTERCEPTSubscriptionVariables | VueCompositionApi.Ref<IndicatorLINEARREG_INTERCEPTSubscriptionVariables> | ReactiveFunction<IndicatorLINEARREG_INTERCEPTSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorLINEARREG_INTERCEPTSubscription, IndicatorLINEARREG_INTERCEPTSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorLINEARREG_INTERCEPTSubscription, IndicatorLINEARREG_INTERCEPTSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorLINEARREG_INTERCEPTSubscription, IndicatorLINEARREG_INTERCEPTSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorLINEARREG_INTERCEPTSubscription, IndicatorLINEARREG_INTERCEPTSubscriptionVariables>(IndicatorLINEARREG_INTERCEPTDocument, variables, options);
}
export type IndicatorLINEARREG_INTERCEPTSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorLINEARREG_INTERCEPTSubscription, IndicatorLINEARREG_INTERCEPTSubscriptionVariables>;
export const IndicatorLINEARREG_SLOPEDocument = gql`
    subscription IndicatorLINEARREG_SLOPE($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorLINEARREG_SLOPE(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorLINEARREG_SLOPESubscription__
 *
 * To run a query within a Vue component, call `useIndicatorLINEARREG_SLOPESubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorLINEARREG_SLOPESubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorLINEARREG_SLOPESubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorLINEARREG_SLOPESubscription(variables: IndicatorLINEARREG_SLOPESubscriptionVariables | VueCompositionApi.Ref<IndicatorLINEARREG_SLOPESubscriptionVariables> | ReactiveFunction<IndicatorLINEARREG_SLOPESubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorLINEARREG_SLOPESubscription, IndicatorLINEARREG_SLOPESubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorLINEARREG_SLOPESubscription, IndicatorLINEARREG_SLOPESubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorLINEARREG_SLOPESubscription, IndicatorLINEARREG_SLOPESubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorLINEARREG_SLOPESubscription, IndicatorLINEARREG_SLOPESubscriptionVariables>(IndicatorLINEARREG_SLOPEDocument, variables, options);
}
export type IndicatorLINEARREG_SLOPESubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorLINEARREG_SLOPESubscription, IndicatorLINEARREG_SLOPESubscriptionVariables>;
export const IndicatorLNDocument = gql`
    subscription IndicatorLN($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorLN(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorLNSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorLNSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorLNSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorLNSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorLNSubscription(variables: IndicatorLNSubscriptionVariables | VueCompositionApi.Ref<IndicatorLNSubscriptionVariables> | ReactiveFunction<IndicatorLNSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorLNSubscription, IndicatorLNSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorLNSubscription, IndicatorLNSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorLNSubscription, IndicatorLNSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorLNSubscription, IndicatorLNSubscriptionVariables>(IndicatorLNDocument, variables, options);
}
export type IndicatorLNSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorLNSubscription, IndicatorLNSubscriptionVariables>;
export const IndicatorLOG10Document = gql`
    subscription IndicatorLOG10($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorLOG10(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorLOG10Subscription__
 *
 * To run a query within a Vue component, call `useIndicatorLOG10Subscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorLOG10Subscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorLOG10Subscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorLOG10Subscription(variables: IndicatorLOG10SubscriptionVariables | VueCompositionApi.Ref<IndicatorLOG10SubscriptionVariables> | ReactiveFunction<IndicatorLOG10SubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorLOG10Subscription, IndicatorLOG10SubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorLOG10Subscription, IndicatorLOG10SubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorLOG10Subscription, IndicatorLOG10SubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorLOG10Subscription, IndicatorLOG10SubscriptionVariables>(IndicatorLOG10Document, variables, options);
}
export type IndicatorLOG10SubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorLOG10Subscription, IndicatorLOG10SubscriptionVariables>;
export const IndicatorMACDDocument = gql`
    subscription IndicatorMACD($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optFast_Period: Float, $optSlow_Period: Float, $optSignal_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorMACD(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optFast_Period: $optFast_Period
    optSlow_Period: $optSlow_Period
    optSignal_Period: $optSignal_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorMACDSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorMACDSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorMACDSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorMACDSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optFast_Period: // value for 'optFast_Period'
 *   optSlow_Period: // value for 'optSlow_Period'
 *   optSignal_Period: // value for 'optSignal_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorMACDSubscription(variables: IndicatorMACDSubscriptionVariables | VueCompositionApi.Ref<IndicatorMACDSubscriptionVariables> | ReactiveFunction<IndicatorMACDSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorMACDSubscription, IndicatorMACDSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorMACDSubscription, IndicatorMACDSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorMACDSubscription, IndicatorMACDSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorMACDSubscription, IndicatorMACDSubscriptionVariables>(IndicatorMACDDocument, variables, options);
}
export type IndicatorMACDSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorMACDSubscription, IndicatorMACDSubscriptionVariables>;
export const IndicatorMACDEXTDocument = gql`
    subscription IndicatorMACDEXT($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optFast_Period: Float, $optFast_MA: MATypes, $optSlow_Period: Float, $optSlow_MA: MATypes, $optSignal_Period: Float, $optSignal_MA: MATypes, $startIdx: Int, $endIdx: Int) {
  IndicatorMACDEXT(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optFast_Period: $optFast_Period
    optFast_MA: $optFast_MA
    optSlow_Period: $optSlow_Period
    optSlow_MA: $optSlow_MA
    optSignal_Period: $optSignal_Period
    optSignal_MA: $optSignal_MA
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorMACDEXTSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorMACDEXTSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorMACDEXTSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorMACDEXTSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optFast_Period: // value for 'optFast_Period'
 *   optFast_MA: // value for 'optFast_MA'
 *   optSlow_Period: // value for 'optSlow_Period'
 *   optSlow_MA: // value for 'optSlow_MA'
 *   optSignal_Period: // value for 'optSignal_Period'
 *   optSignal_MA: // value for 'optSignal_MA'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorMACDEXTSubscription(variables: IndicatorMACDEXTSubscriptionVariables | VueCompositionApi.Ref<IndicatorMACDEXTSubscriptionVariables> | ReactiveFunction<IndicatorMACDEXTSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorMACDEXTSubscription, IndicatorMACDEXTSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorMACDEXTSubscription, IndicatorMACDEXTSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorMACDEXTSubscription, IndicatorMACDEXTSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorMACDEXTSubscription, IndicatorMACDEXTSubscriptionVariables>(IndicatorMACDEXTDocument, variables, options);
}
export type IndicatorMACDEXTSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorMACDEXTSubscription, IndicatorMACDEXTSubscriptionVariables>;
export const IndicatorMACDFIXDocument = gql`
    subscription IndicatorMACDFIX($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optSignal_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorMACDFIX(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optSignal_Period: $optSignal_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorMACDFIXSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorMACDFIXSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorMACDFIXSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorMACDFIXSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optSignal_Period: // value for 'optSignal_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorMACDFIXSubscription(variables: IndicatorMACDFIXSubscriptionVariables | VueCompositionApi.Ref<IndicatorMACDFIXSubscriptionVariables> | ReactiveFunction<IndicatorMACDFIXSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorMACDFIXSubscription, IndicatorMACDFIXSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorMACDFIXSubscription, IndicatorMACDFIXSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorMACDFIXSubscription, IndicatorMACDFIXSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorMACDFIXSubscription, IndicatorMACDFIXSubscriptionVariables>(IndicatorMACDFIXDocument, variables, options);
}
export type IndicatorMACDFIXSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorMACDFIXSubscription, IndicatorMACDFIXSubscriptionVariables>;
export const IndicatorMAMADocument = gql`
    subscription IndicatorMAMA($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optFast_Limit: Float, $optSlow_Limit: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorMAMA(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optFast_Limit: $optFast_Limit
    optSlow_Limit: $optSlow_Limit
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorMAMASubscription__
 *
 * To run a query within a Vue component, call `useIndicatorMAMASubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorMAMASubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorMAMASubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optFast_Limit: // value for 'optFast_Limit'
 *   optSlow_Limit: // value for 'optSlow_Limit'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorMAMASubscription(variables: IndicatorMAMASubscriptionVariables | VueCompositionApi.Ref<IndicatorMAMASubscriptionVariables> | ReactiveFunction<IndicatorMAMASubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorMAMASubscription, IndicatorMAMASubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorMAMASubscription, IndicatorMAMASubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorMAMASubscription, IndicatorMAMASubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorMAMASubscription, IndicatorMAMASubscriptionVariables>(IndicatorMAMADocument, variables, options);
}
export type IndicatorMAMASubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorMAMASubscription, IndicatorMAMASubscriptionVariables>;
export const IndicatorMAVPDocument = gql`
    subscription IndicatorMAVP($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optMinimum_Period: Float, $optMaximum_Period: Float, $optMA_Type: MATypes, $startIdx: Int, $endIdx: Int) {
  IndicatorMAVP(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optMinimum_Period: $optMinimum_Period
    optMaximum_Period: $optMaximum_Period
    optMA_Type: $optMA_Type
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorMAVPSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorMAVPSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorMAVPSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorMAVPSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optMinimum_Period: // value for 'optMinimum_Period'
 *   optMaximum_Period: // value for 'optMaximum_Period'
 *   optMA_Type: // value for 'optMA_Type'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorMAVPSubscription(variables: IndicatorMAVPSubscriptionVariables | VueCompositionApi.Ref<IndicatorMAVPSubscriptionVariables> | ReactiveFunction<IndicatorMAVPSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorMAVPSubscription, IndicatorMAVPSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorMAVPSubscription, IndicatorMAVPSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorMAVPSubscription, IndicatorMAVPSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorMAVPSubscription, IndicatorMAVPSubscriptionVariables>(IndicatorMAVPDocument, variables, options);
}
export type IndicatorMAVPSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorMAVPSubscription, IndicatorMAVPSubscriptionVariables>;
export const IndicatorMAXDocument = gql`
    subscription IndicatorMAX($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorMAX(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorMAXSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorMAXSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorMAXSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorMAXSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorMAXSubscription(variables: IndicatorMAXSubscriptionVariables | VueCompositionApi.Ref<IndicatorMAXSubscriptionVariables> | ReactiveFunction<IndicatorMAXSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorMAXSubscription, IndicatorMAXSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorMAXSubscription, IndicatorMAXSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorMAXSubscription, IndicatorMAXSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorMAXSubscription, IndicatorMAXSubscriptionVariables>(IndicatorMAXDocument, variables, options);
}
export type IndicatorMAXSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorMAXSubscription, IndicatorMAXSubscriptionVariables>;
export const IndicatorMAXINDEXDocument = gql`
    subscription IndicatorMAXINDEX($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorMAXINDEX(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorMAXINDEXSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorMAXINDEXSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorMAXINDEXSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorMAXINDEXSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorMAXINDEXSubscription(variables: IndicatorMAXINDEXSubscriptionVariables | VueCompositionApi.Ref<IndicatorMAXINDEXSubscriptionVariables> | ReactiveFunction<IndicatorMAXINDEXSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorMAXINDEXSubscription, IndicatorMAXINDEXSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorMAXINDEXSubscription, IndicatorMAXINDEXSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorMAXINDEXSubscription, IndicatorMAXINDEXSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorMAXINDEXSubscription, IndicatorMAXINDEXSubscriptionVariables>(IndicatorMAXINDEXDocument, variables, options);
}
export type IndicatorMAXINDEXSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorMAXINDEXSubscription, IndicatorMAXINDEXSubscriptionVariables>;
export const IndicatorMEDPRICEDocument = gql`
    subscription IndicatorMEDPRICE($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorMEDPRICE(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorMEDPRICESubscription__
 *
 * To run a query within a Vue component, call `useIndicatorMEDPRICESubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorMEDPRICESubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorMEDPRICESubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorMEDPRICESubscription(variables: IndicatorMEDPRICESubscriptionVariables | VueCompositionApi.Ref<IndicatorMEDPRICESubscriptionVariables> | ReactiveFunction<IndicatorMEDPRICESubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorMEDPRICESubscription, IndicatorMEDPRICESubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorMEDPRICESubscription, IndicatorMEDPRICESubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorMEDPRICESubscription, IndicatorMEDPRICESubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorMEDPRICESubscription, IndicatorMEDPRICESubscriptionVariables>(IndicatorMEDPRICEDocument, variables, options);
}
export type IndicatorMEDPRICESubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorMEDPRICESubscription, IndicatorMEDPRICESubscriptionVariables>;
export const IndicatorMFIDocument = gql`
    subscription IndicatorMFI($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorMFI(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorMFISubscription__
 *
 * To run a query within a Vue component, call `useIndicatorMFISubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorMFISubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorMFISubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorMFISubscription(variables: IndicatorMFISubscriptionVariables | VueCompositionApi.Ref<IndicatorMFISubscriptionVariables> | ReactiveFunction<IndicatorMFISubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorMFISubscription, IndicatorMFISubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorMFISubscription, IndicatorMFISubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorMFISubscription, IndicatorMFISubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorMFISubscription, IndicatorMFISubscriptionVariables>(IndicatorMFIDocument, variables, options);
}
export type IndicatorMFISubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorMFISubscription, IndicatorMFISubscriptionVariables>;
export const IndicatorMIDPOINTDocument = gql`
    subscription IndicatorMIDPOINT($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorMIDPOINT(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorMIDPOINTSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorMIDPOINTSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorMIDPOINTSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorMIDPOINTSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorMIDPOINTSubscription(variables: IndicatorMIDPOINTSubscriptionVariables | VueCompositionApi.Ref<IndicatorMIDPOINTSubscriptionVariables> | ReactiveFunction<IndicatorMIDPOINTSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorMIDPOINTSubscription, IndicatorMIDPOINTSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorMIDPOINTSubscription, IndicatorMIDPOINTSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorMIDPOINTSubscription, IndicatorMIDPOINTSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorMIDPOINTSubscription, IndicatorMIDPOINTSubscriptionVariables>(IndicatorMIDPOINTDocument, variables, options);
}
export type IndicatorMIDPOINTSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorMIDPOINTSubscription, IndicatorMIDPOINTSubscriptionVariables>;
export const IndicatorMIDPRICEDocument = gql`
    subscription IndicatorMIDPRICE($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorMIDPRICE(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorMIDPRICESubscription__
 *
 * To run a query within a Vue component, call `useIndicatorMIDPRICESubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorMIDPRICESubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorMIDPRICESubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorMIDPRICESubscription(variables: IndicatorMIDPRICESubscriptionVariables | VueCompositionApi.Ref<IndicatorMIDPRICESubscriptionVariables> | ReactiveFunction<IndicatorMIDPRICESubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorMIDPRICESubscription, IndicatorMIDPRICESubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorMIDPRICESubscription, IndicatorMIDPRICESubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorMIDPRICESubscription, IndicatorMIDPRICESubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorMIDPRICESubscription, IndicatorMIDPRICESubscriptionVariables>(IndicatorMIDPRICEDocument, variables, options);
}
export type IndicatorMIDPRICESubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorMIDPRICESubscription, IndicatorMIDPRICESubscriptionVariables>;
export const IndicatorMININDEXDocument = gql`
    subscription IndicatorMININDEX($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorMININDEX(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorMININDEXSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorMININDEXSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorMININDEXSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorMININDEXSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorMININDEXSubscription(variables: IndicatorMININDEXSubscriptionVariables | VueCompositionApi.Ref<IndicatorMININDEXSubscriptionVariables> | ReactiveFunction<IndicatorMININDEXSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorMININDEXSubscription, IndicatorMININDEXSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorMININDEXSubscription, IndicatorMININDEXSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorMININDEXSubscription, IndicatorMININDEXSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorMININDEXSubscription, IndicatorMININDEXSubscriptionVariables>(IndicatorMININDEXDocument, variables, options);
}
export type IndicatorMININDEXSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorMININDEXSubscription, IndicatorMININDEXSubscriptionVariables>;
export const IndicatorMINMAXDocument = gql`
    subscription IndicatorMINMAX($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorMINMAX(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorMINMAXSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorMINMAXSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorMINMAXSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorMINMAXSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorMINMAXSubscription(variables: IndicatorMINMAXSubscriptionVariables | VueCompositionApi.Ref<IndicatorMINMAXSubscriptionVariables> | ReactiveFunction<IndicatorMINMAXSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorMINMAXSubscription, IndicatorMINMAXSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorMINMAXSubscription, IndicatorMINMAXSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorMINMAXSubscription, IndicatorMINMAXSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorMINMAXSubscription, IndicatorMINMAXSubscriptionVariables>(IndicatorMINMAXDocument, variables, options);
}
export type IndicatorMINMAXSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorMINMAXSubscription, IndicatorMINMAXSubscriptionVariables>;
export const IndicatorMINMAXINDEXDocument = gql`
    subscription IndicatorMINMAXINDEX($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorMINMAXINDEX(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorMINMAXINDEXSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorMINMAXINDEXSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorMINMAXINDEXSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorMINMAXINDEXSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorMINMAXINDEXSubscription(variables: IndicatorMINMAXINDEXSubscriptionVariables | VueCompositionApi.Ref<IndicatorMINMAXINDEXSubscriptionVariables> | ReactiveFunction<IndicatorMINMAXINDEXSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorMINMAXINDEXSubscription, IndicatorMINMAXINDEXSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorMINMAXINDEXSubscription, IndicatorMINMAXINDEXSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorMINMAXINDEXSubscription, IndicatorMINMAXINDEXSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorMINMAXINDEXSubscription, IndicatorMINMAXINDEXSubscriptionVariables>(IndicatorMINMAXINDEXDocument, variables, options);
}
export type IndicatorMINMAXINDEXSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorMINMAXINDEXSubscription, IndicatorMINMAXINDEXSubscriptionVariables>;
export const IndicatorMINUS_DIDocument = gql`
    subscription IndicatorMINUS_DI($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorMINUS_DI(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorMINUS_DISubscription__
 *
 * To run a query within a Vue component, call `useIndicatorMINUS_DISubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorMINUS_DISubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorMINUS_DISubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorMINUS_DISubscription(variables: IndicatorMINUS_DISubscriptionVariables | VueCompositionApi.Ref<IndicatorMINUS_DISubscriptionVariables> | ReactiveFunction<IndicatorMINUS_DISubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorMINUS_DISubscription, IndicatorMINUS_DISubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorMINUS_DISubscription, IndicatorMINUS_DISubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorMINUS_DISubscription, IndicatorMINUS_DISubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorMINUS_DISubscription, IndicatorMINUS_DISubscriptionVariables>(IndicatorMINUS_DIDocument, variables, options);
}
export type IndicatorMINUS_DISubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorMINUS_DISubscription, IndicatorMINUS_DISubscriptionVariables>;
export const IndicatorMINUS_DMDocument = gql`
    subscription IndicatorMINUS_DM($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorMINUS_DM(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorMINUS_DMSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorMINUS_DMSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorMINUS_DMSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorMINUS_DMSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorMINUS_DMSubscription(variables: IndicatorMINUS_DMSubscriptionVariables | VueCompositionApi.Ref<IndicatorMINUS_DMSubscriptionVariables> | ReactiveFunction<IndicatorMINUS_DMSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorMINUS_DMSubscription, IndicatorMINUS_DMSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorMINUS_DMSubscription, IndicatorMINUS_DMSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorMINUS_DMSubscription, IndicatorMINUS_DMSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorMINUS_DMSubscription, IndicatorMINUS_DMSubscriptionVariables>(IndicatorMINUS_DMDocument, variables, options);
}
export type IndicatorMINUS_DMSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorMINUS_DMSubscription, IndicatorMINUS_DMSubscriptionVariables>;
export const IndicatorMOMDocument = gql`
    subscription IndicatorMOM($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorMOM(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorMOMSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorMOMSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorMOMSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorMOMSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorMOMSubscription(variables: IndicatorMOMSubscriptionVariables | VueCompositionApi.Ref<IndicatorMOMSubscriptionVariables> | ReactiveFunction<IndicatorMOMSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorMOMSubscription, IndicatorMOMSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorMOMSubscription, IndicatorMOMSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorMOMSubscription, IndicatorMOMSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorMOMSubscription, IndicatorMOMSubscriptionVariables>(IndicatorMOMDocument, variables, options);
}
export type IndicatorMOMSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorMOMSubscription, IndicatorMOMSubscriptionVariables>;
export const IndicatorMULTDocument = gql`
    subscription IndicatorMULT($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorMULT(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorMULTSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorMULTSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorMULTSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorMULTSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorMULTSubscription(variables: IndicatorMULTSubscriptionVariables | VueCompositionApi.Ref<IndicatorMULTSubscriptionVariables> | ReactiveFunction<IndicatorMULTSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorMULTSubscription, IndicatorMULTSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorMULTSubscription, IndicatorMULTSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorMULTSubscription, IndicatorMULTSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorMULTSubscription, IndicatorMULTSubscriptionVariables>(IndicatorMULTDocument, variables, options);
}
export type IndicatorMULTSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorMULTSubscription, IndicatorMULTSubscriptionVariables>;
export const IndicatorNATRDocument = gql`
    subscription IndicatorNATR($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorNATR(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorNATRSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorNATRSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorNATRSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorNATRSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorNATRSubscription(variables: IndicatorNATRSubscriptionVariables | VueCompositionApi.Ref<IndicatorNATRSubscriptionVariables> | ReactiveFunction<IndicatorNATRSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorNATRSubscription, IndicatorNATRSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorNATRSubscription, IndicatorNATRSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorNATRSubscription, IndicatorNATRSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorNATRSubscription, IndicatorNATRSubscriptionVariables>(IndicatorNATRDocument, variables, options);
}
export type IndicatorNATRSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorNATRSubscription, IndicatorNATRSubscriptionVariables>;
export const IndicatorOBVDocument = gql`
    subscription IndicatorOBV($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorOBV(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorOBVSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorOBVSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorOBVSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorOBVSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorOBVSubscription(variables: IndicatorOBVSubscriptionVariables | VueCompositionApi.Ref<IndicatorOBVSubscriptionVariables> | ReactiveFunction<IndicatorOBVSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorOBVSubscription, IndicatorOBVSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorOBVSubscription, IndicatorOBVSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorOBVSubscription, IndicatorOBVSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorOBVSubscription, IndicatorOBVSubscriptionVariables>(IndicatorOBVDocument, variables, options);
}
export type IndicatorOBVSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorOBVSubscription, IndicatorOBVSubscriptionVariables>;
export const IndicatorPLUS_DIDocument = gql`
    subscription IndicatorPLUS_DI($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorPLUS_DI(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorPLUS_DISubscription__
 *
 * To run a query within a Vue component, call `useIndicatorPLUS_DISubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorPLUS_DISubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorPLUS_DISubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorPLUS_DISubscription(variables: IndicatorPLUS_DISubscriptionVariables | VueCompositionApi.Ref<IndicatorPLUS_DISubscriptionVariables> | ReactiveFunction<IndicatorPLUS_DISubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorPLUS_DISubscription, IndicatorPLUS_DISubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorPLUS_DISubscription, IndicatorPLUS_DISubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorPLUS_DISubscription, IndicatorPLUS_DISubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorPLUS_DISubscription, IndicatorPLUS_DISubscriptionVariables>(IndicatorPLUS_DIDocument, variables, options);
}
export type IndicatorPLUS_DISubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorPLUS_DISubscription, IndicatorPLUS_DISubscriptionVariables>;
export const IndicatorPLUS_DMDocument = gql`
    subscription IndicatorPLUS_DM($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorPLUS_DM(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorPLUS_DMSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorPLUS_DMSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorPLUS_DMSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorPLUS_DMSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorPLUS_DMSubscription(variables: IndicatorPLUS_DMSubscriptionVariables | VueCompositionApi.Ref<IndicatorPLUS_DMSubscriptionVariables> | ReactiveFunction<IndicatorPLUS_DMSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorPLUS_DMSubscription, IndicatorPLUS_DMSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorPLUS_DMSubscription, IndicatorPLUS_DMSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorPLUS_DMSubscription, IndicatorPLUS_DMSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorPLUS_DMSubscription, IndicatorPLUS_DMSubscriptionVariables>(IndicatorPLUS_DMDocument, variables, options);
}
export type IndicatorPLUS_DMSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorPLUS_DMSubscription, IndicatorPLUS_DMSubscriptionVariables>;
export const IndicatorPPODocument = gql`
    subscription IndicatorPPO($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optFast_Period: Float, $optSlow_Period: Float, $optMA_Type: MATypes, $startIdx: Int, $endIdx: Int) {
  IndicatorPPO(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optFast_Period: $optFast_Period
    optSlow_Period: $optSlow_Period
    optMA_Type: $optMA_Type
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorPPOSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorPPOSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorPPOSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorPPOSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optFast_Period: // value for 'optFast_Period'
 *   optSlow_Period: // value for 'optSlow_Period'
 *   optMA_Type: // value for 'optMA_Type'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorPPOSubscription(variables: IndicatorPPOSubscriptionVariables | VueCompositionApi.Ref<IndicatorPPOSubscriptionVariables> | ReactiveFunction<IndicatorPPOSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorPPOSubscription, IndicatorPPOSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorPPOSubscription, IndicatorPPOSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorPPOSubscription, IndicatorPPOSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorPPOSubscription, IndicatorPPOSubscriptionVariables>(IndicatorPPODocument, variables, options);
}
export type IndicatorPPOSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorPPOSubscription, IndicatorPPOSubscriptionVariables>;
export const IndicatorROCDocument = gql`
    subscription IndicatorROC($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorROC(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorROCSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorROCSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorROCSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorROCSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorROCSubscription(variables: IndicatorROCSubscriptionVariables | VueCompositionApi.Ref<IndicatorROCSubscriptionVariables> | ReactiveFunction<IndicatorROCSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorROCSubscription, IndicatorROCSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorROCSubscription, IndicatorROCSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorROCSubscription, IndicatorROCSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorROCSubscription, IndicatorROCSubscriptionVariables>(IndicatorROCDocument, variables, options);
}
export type IndicatorROCSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorROCSubscription, IndicatorROCSubscriptionVariables>;
export const IndicatorROCPDocument = gql`
    subscription IndicatorROCP($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorROCP(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorROCPSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorROCPSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorROCPSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorROCPSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorROCPSubscription(variables: IndicatorROCPSubscriptionVariables | VueCompositionApi.Ref<IndicatorROCPSubscriptionVariables> | ReactiveFunction<IndicatorROCPSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorROCPSubscription, IndicatorROCPSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorROCPSubscription, IndicatorROCPSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorROCPSubscription, IndicatorROCPSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorROCPSubscription, IndicatorROCPSubscriptionVariables>(IndicatorROCPDocument, variables, options);
}
export type IndicatorROCPSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorROCPSubscription, IndicatorROCPSubscriptionVariables>;
export const IndicatorROCRDocument = gql`
    subscription IndicatorROCR($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorROCR(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorROCRSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorROCRSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorROCRSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorROCRSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorROCRSubscription(variables: IndicatorROCRSubscriptionVariables | VueCompositionApi.Ref<IndicatorROCRSubscriptionVariables> | ReactiveFunction<IndicatorROCRSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorROCRSubscription, IndicatorROCRSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorROCRSubscription, IndicatorROCRSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorROCRSubscription, IndicatorROCRSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorROCRSubscription, IndicatorROCRSubscriptionVariables>(IndicatorROCRDocument, variables, options);
}
export type IndicatorROCRSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorROCRSubscription, IndicatorROCRSubscriptionVariables>;
export const IndicatorROCR100Document = gql`
    subscription IndicatorROCR100($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorROCR100(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorROCR100Subscription__
 *
 * To run a query within a Vue component, call `useIndicatorROCR100Subscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorROCR100Subscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorROCR100Subscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorROCR100Subscription(variables: IndicatorROCR100SubscriptionVariables | VueCompositionApi.Ref<IndicatorROCR100SubscriptionVariables> | ReactiveFunction<IndicatorROCR100SubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorROCR100Subscription, IndicatorROCR100SubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorROCR100Subscription, IndicatorROCR100SubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorROCR100Subscription, IndicatorROCR100SubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorROCR100Subscription, IndicatorROCR100SubscriptionVariables>(IndicatorROCR100Document, variables, options);
}
export type IndicatorROCR100SubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorROCR100Subscription, IndicatorROCR100SubscriptionVariables>;
export const IndicatorSARDocument = gql`
    subscription IndicatorSAR($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optAcceleration_Factor: Float, $optAF_Maximum: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorSAR(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optAcceleration_Factor: $optAcceleration_Factor
    optAF_Maximum: $optAF_Maximum
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorSARSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorSARSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorSARSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorSARSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optAcceleration_Factor: // value for 'optAcceleration_Factor'
 *   optAF_Maximum: // value for 'optAF_Maximum'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorSARSubscription(variables: IndicatorSARSubscriptionVariables | VueCompositionApi.Ref<IndicatorSARSubscriptionVariables> | ReactiveFunction<IndicatorSARSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorSARSubscription, IndicatorSARSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorSARSubscription, IndicatorSARSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorSARSubscription, IndicatorSARSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorSARSubscription, IndicatorSARSubscriptionVariables>(IndicatorSARDocument, variables, options);
}
export type IndicatorSARSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorSARSubscription, IndicatorSARSubscriptionVariables>;
export const IndicatorSAREXTDocument = gql`
    subscription IndicatorSAREXT($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optStart_Value: Float, $optOffset_on_Reverse: Float, $optAF_Init_Long: Float, $optAF_Long: Float, $optAF_Max_Long: Float, $optAF_Init_Short: Float, $optAF_Short: Float, $optAF_Max_Short: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorSAREXT(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optStart_Value: $optStart_Value
    optOffset_on_Reverse: $optOffset_on_Reverse
    optAF_Init_Long: $optAF_Init_Long
    optAF_Long: $optAF_Long
    optAF_Max_Long: $optAF_Max_Long
    optAF_Init_Short: $optAF_Init_Short
    optAF_Short: $optAF_Short
    optAF_Max_Short: $optAF_Max_Short
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorSAREXTSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorSAREXTSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorSAREXTSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorSAREXTSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optStart_Value: // value for 'optStart_Value'
 *   optOffset_on_Reverse: // value for 'optOffset_on_Reverse'
 *   optAF_Init_Long: // value for 'optAF_Init_Long'
 *   optAF_Long: // value for 'optAF_Long'
 *   optAF_Max_Long: // value for 'optAF_Max_Long'
 *   optAF_Init_Short: // value for 'optAF_Init_Short'
 *   optAF_Short: // value for 'optAF_Short'
 *   optAF_Max_Short: // value for 'optAF_Max_Short'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorSAREXTSubscription(variables: IndicatorSAREXTSubscriptionVariables | VueCompositionApi.Ref<IndicatorSAREXTSubscriptionVariables> | ReactiveFunction<IndicatorSAREXTSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorSAREXTSubscription, IndicatorSAREXTSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorSAREXTSubscription, IndicatorSAREXTSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorSAREXTSubscription, IndicatorSAREXTSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorSAREXTSubscription, IndicatorSAREXTSubscriptionVariables>(IndicatorSAREXTDocument, variables, options);
}
export type IndicatorSAREXTSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorSAREXTSubscription, IndicatorSAREXTSubscriptionVariables>;
export const IndicatorSINHDocument = gql`
    subscription IndicatorSINH($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorSINH(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorSINHSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorSINHSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorSINHSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorSINHSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorSINHSubscription(variables: IndicatorSINHSubscriptionVariables | VueCompositionApi.Ref<IndicatorSINHSubscriptionVariables> | ReactiveFunction<IndicatorSINHSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorSINHSubscription, IndicatorSINHSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorSINHSubscription, IndicatorSINHSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorSINHSubscription, IndicatorSINHSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorSINHSubscription, IndicatorSINHSubscriptionVariables>(IndicatorSINHDocument, variables, options);
}
export type IndicatorSINHSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorSINHSubscription, IndicatorSINHSubscriptionVariables>;
export const IndicatorSMADocument = gql`
    subscription IndicatorSMA($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorSMA(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorSMASubscription__
 *
 * To run a query within a Vue component, call `useIndicatorSMASubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorSMASubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorSMASubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorSMASubscription(variables: IndicatorSMASubscriptionVariables | VueCompositionApi.Ref<IndicatorSMASubscriptionVariables> | ReactiveFunction<IndicatorSMASubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorSMASubscription, IndicatorSMASubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorSMASubscription, IndicatorSMASubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorSMASubscription, IndicatorSMASubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorSMASubscription, IndicatorSMASubscriptionVariables>(IndicatorSMADocument, variables, options);
}
export type IndicatorSMASubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorSMASubscription, IndicatorSMASubscriptionVariables>;
export const IndicatorSQRTDocument = gql`
    subscription IndicatorSQRT($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorSQRT(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorSQRTSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorSQRTSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorSQRTSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorSQRTSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorSQRTSubscription(variables: IndicatorSQRTSubscriptionVariables | VueCompositionApi.Ref<IndicatorSQRTSubscriptionVariables> | ReactiveFunction<IndicatorSQRTSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorSQRTSubscription, IndicatorSQRTSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorSQRTSubscription, IndicatorSQRTSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorSQRTSubscription, IndicatorSQRTSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorSQRTSubscription, IndicatorSQRTSubscriptionVariables>(IndicatorSQRTDocument, variables, options);
}
export type IndicatorSQRTSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorSQRTSubscription, IndicatorSQRTSubscriptionVariables>;
export const IndicatorSTDDEVDocument = gql`
    subscription IndicatorSTDDEV($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $optDeviations: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorSTDDEV(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    optDeviations: $optDeviations
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorSTDDEVSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorSTDDEVSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorSTDDEVSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorSTDDEVSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   optDeviations: // value for 'optDeviations'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorSTDDEVSubscription(variables: IndicatorSTDDEVSubscriptionVariables | VueCompositionApi.Ref<IndicatorSTDDEVSubscriptionVariables> | ReactiveFunction<IndicatorSTDDEVSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorSTDDEVSubscription, IndicatorSTDDEVSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorSTDDEVSubscription, IndicatorSTDDEVSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorSTDDEVSubscription, IndicatorSTDDEVSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorSTDDEVSubscription, IndicatorSTDDEVSubscriptionVariables>(IndicatorSTDDEVDocument, variables, options);
}
export type IndicatorSTDDEVSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorSTDDEVSubscription, IndicatorSTDDEVSubscriptionVariables>;
export const IndicatorSTOCHDocument = gql`
    subscription IndicatorSTOCH($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optFastK_Period: Float, $optSlowK_Period: Float, $optSlowK_MA: MATypes, $optSlowD_Period: Float, $optSlowD_MA: MATypes, $startIdx: Int, $endIdx: Int) {
  IndicatorSTOCH(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optFastK_Period: $optFastK_Period
    optSlowK_Period: $optSlowK_Period
    optSlowK_MA: $optSlowK_MA
    optSlowD_Period: $optSlowD_Period
    optSlowD_MA: $optSlowD_MA
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorSTOCHSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorSTOCHSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorSTOCHSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorSTOCHSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optFastK_Period: // value for 'optFastK_Period'
 *   optSlowK_Period: // value for 'optSlowK_Period'
 *   optSlowK_MA: // value for 'optSlowK_MA'
 *   optSlowD_Period: // value for 'optSlowD_Period'
 *   optSlowD_MA: // value for 'optSlowD_MA'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorSTOCHSubscription(variables: IndicatorSTOCHSubscriptionVariables | VueCompositionApi.Ref<IndicatorSTOCHSubscriptionVariables> | ReactiveFunction<IndicatorSTOCHSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorSTOCHSubscription, IndicatorSTOCHSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorSTOCHSubscription, IndicatorSTOCHSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorSTOCHSubscription, IndicatorSTOCHSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorSTOCHSubscription, IndicatorSTOCHSubscriptionVariables>(IndicatorSTOCHDocument, variables, options);
}
export type IndicatorSTOCHSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorSTOCHSubscription, IndicatorSTOCHSubscriptionVariables>;
export const IndicatorSTOCHFDocument = gql`
    subscription IndicatorSTOCHF($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optFastK_Period: Float, $optFastD_Period: Float, $optFastD_MA: MATypes, $startIdx: Int, $endIdx: Int) {
  IndicatorSTOCHF(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optFastK_Period: $optFastK_Period
    optFastD_Period: $optFastD_Period
    optFastD_MA: $optFastD_MA
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorSTOCHFSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorSTOCHFSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorSTOCHFSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorSTOCHFSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optFastK_Period: // value for 'optFastK_Period'
 *   optFastD_Period: // value for 'optFastD_Period'
 *   optFastD_MA: // value for 'optFastD_MA'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorSTOCHFSubscription(variables: IndicatorSTOCHFSubscriptionVariables | VueCompositionApi.Ref<IndicatorSTOCHFSubscriptionVariables> | ReactiveFunction<IndicatorSTOCHFSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorSTOCHFSubscription, IndicatorSTOCHFSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorSTOCHFSubscription, IndicatorSTOCHFSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorSTOCHFSubscription, IndicatorSTOCHFSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorSTOCHFSubscription, IndicatorSTOCHFSubscriptionVariables>(IndicatorSTOCHFDocument, variables, options);
}
export type IndicatorSTOCHFSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorSTOCHFSubscription, IndicatorSTOCHFSubscriptionVariables>;
export const IndicatorSTOCHRSIDocument = gql`
    subscription IndicatorSTOCHRSI($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $optFastK_Period: Float, $optFastD_Period: Float, $optFastD_MA: MATypes, $startIdx: Int, $endIdx: Int) {
  IndicatorSTOCHRSI(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    optFastK_Period: $optFastK_Period
    optFastD_Period: $optFastD_Period
    optFastD_MA: $optFastD_MA
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorSTOCHRSISubscription__
 *
 * To run a query within a Vue component, call `useIndicatorSTOCHRSISubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorSTOCHRSISubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorSTOCHRSISubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   optFastK_Period: // value for 'optFastK_Period'
 *   optFastD_Period: // value for 'optFastD_Period'
 *   optFastD_MA: // value for 'optFastD_MA'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorSTOCHRSISubscription(variables: IndicatorSTOCHRSISubscriptionVariables | VueCompositionApi.Ref<IndicatorSTOCHRSISubscriptionVariables> | ReactiveFunction<IndicatorSTOCHRSISubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorSTOCHRSISubscription, IndicatorSTOCHRSISubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorSTOCHRSISubscription, IndicatorSTOCHRSISubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorSTOCHRSISubscription, IndicatorSTOCHRSISubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorSTOCHRSISubscription, IndicatorSTOCHRSISubscriptionVariables>(IndicatorSTOCHRSIDocument, variables, options);
}
export type IndicatorSTOCHRSISubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorSTOCHRSISubscription, IndicatorSTOCHRSISubscriptionVariables>;
export const IndicatorSUBDocument = gql`
    subscription IndicatorSUB($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorSUB(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorSUBSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorSUBSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorSUBSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorSUBSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorSUBSubscription(variables: IndicatorSUBSubscriptionVariables | VueCompositionApi.Ref<IndicatorSUBSubscriptionVariables> | ReactiveFunction<IndicatorSUBSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorSUBSubscription, IndicatorSUBSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorSUBSubscription, IndicatorSUBSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorSUBSubscription, IndicatorSUBSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorSUBSubscription, IndicatorSUBSubscriptionVariables>(IndicatorSUBDocument, variables, options);
}
export type IndicatorSUBSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorSUBSubscription, IndicatorSUBSubscriptionVariables>;
export const IndicatorSUMDocument = gql`
    subscription IndicatorSUM($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorSUM(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorSUMSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorSUMSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorSUMSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorSUMSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorSUMSubscription(variables: IndicatorSUMSubscriptionVariables | VueCompositionApi.Ref<IndicatorSUMSubscriptionVariables> | ReactiveFunction<IndicatorSUMSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorSUMSubscription, IndicatorSUMSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorSUMSubscription, IndicatorSUMSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorSUMSubscription, IndicatorSUMSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorSUMSubscription, IndicatorSUMSubscriptionVariables>(IndicatorSUMDocument, variables, options);
}
export type IndicatorSUMSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorSUMSubscription, IndicatorSUMSubscriptionVariables>;
export const IndicatorT3Document = gql`
    subscription IndicatorT3($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $optVolume_Factor: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorT3(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    optVolume_Factor: $optVolume_Factor
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorT3Subscription__
 *
 * To run a query within a Vue component, call `useIndicatorT3Subscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorT3Subscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorT3Subscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   optVolume_Factor: // value for 'optVolume_Factor'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorT3Subscription(variables: IndicatorT3SubscriptionVariables | VueCompositionApi.Ref<IndicatorT3SubscriptionVariables> | ReactiveFunction<IndicatorT3SubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorT3Subscription, IndicatorT3SubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorT3Subscription, IndicatorT3SubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorT3Subscription, IndicatorT3SubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorT3Subscription, IndicatorT3SubscriptionVariables>(IndicatorT3Document, variables, options);
}
export type IndicatorT3SubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorT3Subscription, IndicatorT3SubscriptionVariables>;
export const IndicatorTANHDocument = gql`
    subscription IndicatorTANH($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorTANH(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorTANHSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorTANHSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorTANHSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorTANHSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorTANHSubscription(variables: IndicatorTANHSubscriptionVariables | VueCompositionApi.Ref<IndicatorTANHSubscriptionVariables> | ReactiveFunction<IndicatorTANHSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorTANHSubscription, IndicatorTANHSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorTANHSubscription, IndicatorTANHSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorTANHSubscription, IndicatorTANHSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorTANHSubscription, IndicatorTANHSubscriptionVariables>(IndicatorTANHDocument, variables, options);
}
export type IndicatorTANHSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorTANHSubscription, IndicatorTANHSubscriptionVariables>;
export const IndicatorTEMADocument = gql`
    subscription IndicatorTEMA($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorTEMA(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorTEMASubscription__
 *
 * To run a query within a Vue component, call `useIndicatorTEMASubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorTEMASubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorTEMASubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorTEMASubscription(variables: IndicatorTEMASubscriptionVariables | VueCompositionApi.Ref<IndicatorTEMASubscriptionVariables> | ReactiveFunction<IndicatorTEMASubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorTEMASubscription, IndicatorTEMASubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorTEMASubscription, IndicatorTEMASubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorTEMASubscription, IndicatorTEMASubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorTEMASubscription, IndicatorTEMASubscriptionVariables>(IndicatorTEMADocument, variables, options);
}
export type IndicatorTEMASubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorTEMASubscription, IndicatorTEMASubscriptionVariables>;
export const IndicatorTRANGEDocument = gql`
    subscription IndicatorTRANGE($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorTRANGE(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorTRANGESubscription__
 *
 * To run a query within a Vue component, call `useIndicatorTRANGESubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorTRANGESubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorTRANGESubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorTRANGESubscription(variables: IndicatorTRANGESubscriptionVariables | VueCompositionApi.Ref<IndicatorTRANGESubscriptionVariables> | ReactiveFunction<IndicatorTRANGESubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorTRANGESubscription, IndicatorTRANGESubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorTRANGESubscription, IndicatorTRANGESubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorTRANGESubscription, IndicatorTRANGESubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorTRANGESubscription, IndicatorTRANGESubscriptionVariables>(IndicatorTRANGEDocument, variables, options);
}
export type IndicatorTRANGESubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorTRANGESubscription, IndicatorTRANGESubscriptionVariables>;
export const IndicatorTRIMADocument = gql`
    subscription IndicatorTRIMA($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorTRIMA(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorTRIMASubscription__
 *
 * To run a query within a Vue component, call `useIndicatorTRIMASubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorTRIMASubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorTRIMASubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorTRIMASubscription(variables: IndicatorTRIMASubscriptionVariables | VueCompositionApi.Ref<IndicatorTRIMASubscriptionVariables> | ReactiveFunction<IndicatorTRIMASubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorTRIMASubscription, IndicatorTRIMASubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorTRIMASubscription, IndicatorTRIMASubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorTRIMASubscription, IndicatorTRIMASubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorTRIMASubscription, IndicatorTRIMASubscriptionVariables>(IndicatorTRIMADocument, variables, options);
}
export type IndicatorTRIMASubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorTRIMASubscription, IndicatorTRIMASubscriptionVariables>;
export const IndicatorTRIXDocument = gql`
    subscription IndicatorTRIX($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorTRIX(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorTRIXSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorTRIXSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorTRIXSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorTRIXSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorTRIXSubscription(variables: IndicatorTRIXSubscriptionVariables | VueCompositionApi.Ref<IndicatorTRIXSubscriptionVariables> | ReactiveFunction<IndicatorTRIXSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorTRIXSubscription, IndicatorTRIXSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorTRIXSubscription, IndicatorTRIXSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorTRIXSubscription, IndicatorTRIXSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorTRIXSubscription, IndicatorTRIXSubscriptionVariables>(IndicatorTRIXDocument, variables, options);
}
export type IndicatorTRIXSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorTRIXSubscription, IndicatorTRIXSubscriptionVariables>;
export const IndicatorTSFDocument = gql`
    subscription IndicatorTSF($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorTSF(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorTSFSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorTSFSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorTSFSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorTSFSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorTSFSubscription(variables: IndicatorTSFSubscriptionVariables | VueCompositionApi.Ref<IndicatorTSFSubscriptionVariables> | ReactiveFunction<IndicatorTSFSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorTSFSubscription, IndicatorTSFSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorTSFSubscription, IndicatorTSFSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorTSFSubscription, IndicatorTSFSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorTSFSubscription, IndicatorTSFSubscriptionVariables>(IndicatorTSFDocument, variables, options);
}
export type IndicatorTSFSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorTSFSubscription, IndicatorTSFSubscriptionVariables>;
export const IndicatorTYPPRICEDocument = gql`
    subscription IndicatorTYPPRICE($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorTYPPRICE(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorTYPPRICESubscription__
 *
 * To run a query within a Vue component, call `useIndicatorTYPPRICESubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorTYPPRICESubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorTYPPRICESubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorTYPPRICESubscription(variables: IndicatorTYPPRICESubscriptionVariables | VueCompositionApi.Ref<IndicatorTYPPRICESubscriptionVariables> | ReactiveFunction<IndicatorTYPPRICESubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorTYPPRICESubscription, IndicatorTYPPRICESubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorTYPPRICESubscription, IndicatorTYPPRICESubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorTYPPRICESubscription, IndicatorTYPPRICESubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorTYPPRICESubscription, IndicatorTYPPRICESubscriptionVariables>(IndicatorTYPPRICEDocument, variables, options);
}
export type IndicatorTYPPRICESubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorTYPPRICESubscription, IndicatorTYPPRICESubscriptionVariables>;
export const IndicatorULTOSCDocument = gql`
    subscription IndicatorULTOSC($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optFirst_Period: Float, $optSecond_Period: Float, $optThird_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorULTOSC(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optFirst_Period: $optFirst_Period
    optSecond_Period: $optSecond_Period
    optThird_Period: $optThird_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorULTOSCSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorULTOSCSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorULTOSCSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorULTOSCSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optFirst_Period: // value for 'optFirst_Period'
 *   optSecond_Period: // value for 'optSecond_Period'
 *   optThird_Period: // value for 'optThird_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorULTOSCSubscription(variables: IndicatorULTOSCSubscriptionVariables | VueCompositionApi.Ref<IndicatorULTOSCSubscriptionVariables> | ReactiveFunction<IndicatorULTOSCSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorULTOSCSubscription, IndicatorULTOSCSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorULTOSCSubscription, IndicatorULTOSCSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorULTOSCSubscription, IndicatorULTOSCSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorULTOSCSubscription, IndicatorULTOSCSubscriptionVariables>(IndicatorULTOSCDocument, variables, options);
}
export type IndicatorULTOSCSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorULTOSCSubscription, IndicatorULTOSCSubscriptionVariables>;
export const IndicatorVARDocument = gql`
    subscription IndicatorVAR($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $optDeviations: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorVAR(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    optDeviations: $optDeviations
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorVARSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorVARSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorVARSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorVARSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   optDeviations: // value for 'optDeviations'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorVARSubscription(variables: IndicatorVARSubscriptionVariables | VueCompositionApi.Ref<IndicatorVARSubscriptionVariables> | ReactiveFunction<IndicatorVARSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorVARSubscription, IndicatorVARSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorVARSubscription, IndicatorVARSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorVARSubscription, IndicatorVARSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorVARSubscription, IndicatorVARSubscriptionVariables>(IndicatorVARDocument, variables, options);
}
export type IndicatorVARSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorVARSubscription, IndicatorVARSubscriptionVariables>;
export const IndicatorWCLPRICEDocument = gql`
    subscription IndicatorWCLPRICE($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $startIdx: Int, $endIdx: Int) {
  IndicatorWCLPRICE(
    ticker: $ticker
    channel: $channel
    bars: $bars
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorWCLPRICESubscription__
 *
 * To run a query within a Vue component, call `useIndicatorWCLPRICESubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorWCLPRICESubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorWCLPRICESubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorWCLPRICESubscription(variables: IndicatorWCLPRICESubscriptionVariables | VueCompositionApi.Ref<IndicatorWCLPRICESubscriptionVariables> | ReactiveFunction<IndicatorWCLPRICESubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorWCLPRICESubscription, IndicatorWCLPRICESubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorWCLPRICESubscription, IndicatorWCLPRICESubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorWCLPRICESubscription, IndicatorWCLPRICESubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorWCLPRICESubscription, IndicatorWCLPRICESubscriptionVariables>(IndicatorWCLPRICEDocument, variables, options);
}
export type IndicatorWCLPRICESubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorWCLPRICESubscription, IndicatorWCLPRICESubscriptionVariables>;
export const IndicatorWILLRDocument = gql`
    subscription IndicatorWILLR($ticker: String!, $channel: PolygonEventChannel!, $bars: Int!, $optTime_Period: Float, $startIdx: Int, $endIdx: Int) {
  IndicatorWILLR(
    ticker: $ticker
    channel: $channel
    bars: $bars
    optTime_Period: $optTime_Period
    startIdx: $startIdx
    endIdx: $endIdx
  ) {
    i
    s
    e
    o
    c
    h
    l
    v
  }
}
    `;

/**
 * __useIndicatorWILLRSubscription__
 *
 * To run a query within a Vue component, call `useIndicatorWILLRSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorWILLRSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useIndicatorWILLRSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 *   bars: // value for 'bars'
 *   optTime_Period: // value for 'optTime_Period'
 *   startIdx: // value for 'startIdx'
 *   endIdx: // value for 'endIdx'
 * });
 */
export function useIndicatorWILLRSubscription(variables: IndicatorWILLRSubscriptionVariables | VueCompositionApi.Ref<IndicatorWILLRSubscriptionVariables> | ReactiveFunction<IndicatorWILLRSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<IndicatorWILLRSubscription, IndicatorWILLRSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<IndicatorWILLRSubscription, IndicatorWILLRSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<IndicatorWILLRSubscription, IndicatorWILLRSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<IndicatorWILLRSubscription, IndicatorWILLRSubscriptionVariables>(IndicatorWILLRDocument, variables, options);
}
export type IndicatorWILLRSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<IndicatorWILLRSubscription, IndicatorWILLRSubscriptionVariables>;
export const StockAggregationMinuteDocument = gql`
    subscription StockAggregationMinute($ticker: String!) {
  StockAggregationMinute(ticker: $ticker) {
    ev
    sym
    v
    av
    op
    vw
    o
    c
    h
    l
    a
    s
    e
  }
}
    `;

/**
 * __useStockAggregationMinuteSubscription__
 *
 * To run a query within a Vue component, call `useStockAggregationMinuteSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStockAggregationMinuteSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useStockAggregationMinuteSubscription({
 *   ticker: // value for 'ticker'
 * });
 */
export function useStockAggregationMinuteSubscription(variables: StockAggregationMinuteSubscriptionVariables | VueCompositionApi.Ref<StockAggregationMinuteSubscriptionVariables> | ReactiveFunction<StockAggregationMinuteSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<StockAggregationMinuteSubscription, StockAggregationMinuteSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<StockAggregationMinuteSubscription, StockAggregationMinuteSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<StockAggregationMinuteSubscription, StockAggregationMinuteSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<StockAggregationMinuteSubscription, StockAggregationMinuteSubscriptionVariables>(StockAggregationMinuteDocument, variables, options);
}
export type StockAggregationMinuteSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<StockAggregationMinuteSubscription, StockAggregationMinuteSubscriptionVariables>;
export const StockAggregationSecondDocument = gql`
    subscription StockAggregationSecond($ticker: String!) {
  StockAggregationSecond(ticker: $ticker) {
    ev
    sym
    v
    av
    op
    vw
    o
    c
    h
    l
    a
    s
    e
  }
}
    `;

/**
 * __useStockAggregationSecondSubscription__
 *
 * To run a query within a Vue component, call `useStockAggregationSecondSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStockAggregationSecondSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useStockAggregationSecondSubscription({
 *   ticker: // value for 'ticker'
 * });
 */
export function useStockAggregationSecondSubscription(variables: StockAggregationSecondSubscriptionVariables | VueCompositionApi.Ref<StockAggregationSecondSubscriptionVariables> | ReactiveFunction<StockAggregationSecondSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<StockAggregationSecondSubscription, StockAggregationSecondSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<StockAggregationSecondSubscription, StockAggregationSecondSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<StockAggregationSecondSubscription, StockAggregationSecondSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<StockAggregationSecondSubscription, StockAggregationSecondSubscriptionVariables>(StockAggregationSecondDocument, variables, options);
}
export type StockAggregationSecondSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<StockAggregationSecondSubscription, StockAggregationSecondSubscriptionVariables>;
export const StockQuoteDocument = gql`
    subscription StockQuote($ticker: String!) {
  StockQuote(ticker: $ticker) {
    ev
    sym
    bx
    bp
    bs
    ax
    ap
    as
    c
    t
  }
}
    `;

/**
 * __useStockQuoteSubscription__
 *
 * To run a query within a Vue component, call `useStockQuoteSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStockQuoteSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useStockQuoteSubscription({
 *   ticker: // value for 'ticker'
 * });
 */
export function useStockQuoteSubscription(variables: StockQuoteSubscriptionVariables | VueCompositionApi.Ref<StockQuoteSubscriptionVariables> | ReactiveFunction<StockQuoteSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<StockQuoteSubscription, StockQuoteSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<StockQuoteSubscription, StockQuoteSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<StockQuoteSubscription, StockQuoteSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<StockQuoteSubscription, StockQuoteSubscriptionVariables>(StockQuoteDocument, variables, options);
}
export type StockQuoteSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<StockQuoteSubscription, StockQuoteSubscriptionVariables>;
export const StockTradeDocument = gql`
    subscription StockTrade($ticker: String!) {
  StockTrade(ticker: $ticker) {
    ev
    sym
    x
    i
    z
    p
    s
    c
    t
  }
}
    `;

/**
 * __useStockTradeSubscription__
 *
 * To run a query within a Vue component, call `useStockTradeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStockTradeSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useStockTradeSubscription({
 *   ticker: // value for 'ticker'
 * });
 */
export function useStockTradeSubscription(variables: StockTradeSubscriptionVariables | VueCompositionApi.Ref<StockTradeSubscriptionVariables> | ReactiveFunction<StockTradeSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<StockTradeSubscription, StockTradeSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<StockTradeSubscription, StockTradeSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<StockTradeSubscription, StockTradeSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<StockTradeSubscription, StockTradeSubscriptionVariables>(StockTradeDocument, variables, options);
}
export type StockTradeSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<StockTradeSubscription, StockTradeSubscriptionVariables>;
export const StrategyAROONDocument = gql`
    subscription StrategyAROON($ticker: String!, $channel: PolygonEventChannel!) {
  StrategyAROON(ticker: $ticker, channel: $channel)
}
    `;

/**
 * __useStrategyAROONSubscription__
 *
 * To run a query within a Vue component, call `useStrategyAROONSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStrategyAROONSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useStrategyAROONSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 * });
 */
export function useStrategyAROONSubscription(variables: StrategyAROONSubscriptionVariables | VueCompositionApi.Ref<StrategyAROONSubscriptionVariables> | ReactiveFunction<StrategyAROONSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<StrategyAROONSubscription, StrategyAROONSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<StrategyAROONSubscription, StrategyAROONSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<StrategyAROONSubscription, StrategyAROONSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<StrategyAROONSubscription, StrategyAROONSubscriptionVariables>(StrategyAROONDocument, variables, options);
}
export type StrategyAROONSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<StrategyAROONSubscription, StrategyAROONSubscriptionVariables>;
export const StrategyExampleDocument = gql`
    subscription StrategyExample($ticker: String!, $channel: PolygonEventChannel!) {
  StrategyExample(ticker: $ticker, channel: $channel)
}
    `;

/**
 * __useStrategyExampleSubscription__
 *
 * To run a query within a Vue component, call `useStrategyExampleSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStrategyExampleSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useStrategyExampleSubscription({
 *   ticker: // value for 'ticker'
 *   channel: // value for 'channel'
 * });
 */
export function useStrategyExampleSubscription(variables: StrategyExampleSubscriptionVariables | VueCompositionApi.Ref<StrategyExampleSubscriptionVariables> | ReactiveFunction<StrategyExampleSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<StrategyExampleSubscription, StrategyExampleSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<StrategyExampleSubscription, StrategyExampleSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<StrategyExampleSubscription, StrategyExampleSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<StrategyExampleSubscription, StrategyExampleSubscriptionVariables>(StrategyExampleDocument, variables, options);
}
export type StrategyExampleSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<StrategyExampleSubscription, StrategyExampleSubscriptionVariables>;