<template>
  <div>
    <v-data-table
      dense
      single-expand
      show-expand
      :headers="headers"
      :items="snapshot.tickers"
      :items-per-page="15"
      sort-by="todaysChangePerc"
      sort-desc
    ></v-data-table>
  </div>
</template>
<script>
import { useResult } from "@vue/apollo-composable";
import { useCryptoSnapshotQuery } from "@/graphql";
import { defineComponent, ref, watch } from "@vue/composition-api";
export default defineComponent({
  setup() {
    const headers = [
      { text: "Ticker", value: "ticker" },
      { text: "Today's Change", value: "todaysChange" },
      { text: "Today's Change %", value: "todaysChangePerc" },
      { text: "Minute Close", value: "min.c" },
      { text: "Minute High", value: "min.h" },
      { text: "Minute Low", value: "min.l" },
      { text: "Minute Open", value: "min.o" },
      { text: "Minute Volume", value: "min.v" }
    ];

    const tickers = ref({});

    const { result } = useCryptoSnapshotQuery({ pollInterval: 10000 });
    const snapshot = useResult(result, {}, data => data.CryptoSnapshot);

    watch(snapshot, snapshot => {
      snapshot.tickers.forEach(ticker => {
        const symbol = ticker.ticker;
        const closePrices = tickers.value[ticker] || [];
        const currentClosePrice = ticker.min.c;
        const currentClosePriceIsNew =
          closePrices[closePrices.length - 1] !== currentClosePrice;
        if (currentClosePriceIsNew) {
          closePrices.push(currentClosePrice);
          const priorClosePrice = closePrices[closePrices.length - 1];
          const differenceMinute = currentClosePrice - priorClosePrice;
          const differenceMinutePercent =
            ((currentClosePrice - priorClosePrice) / priorClosePrice) * 100;

          if (tickers.value[symbol]) {
            tickers.value[symbol].difference_minute = differenceMinute;
            tickers.value[
              symbol
            ].difference_minute_percent = differenceMinutePercent;
            tickers.value[ticker].prices = closePrices;
          }
        }

        // tickers.value[ticker.ticker] = [...current, ticker.min.c];
      });
    });
    return { tickers, snapshot, headers };
  }
});
</script>
