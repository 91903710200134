import Vue from "vue";
import Vuex from "vuex";
import snack from "./modules/module.snack";
import session from "./modules/module.session";
import theme from "./modules/module.theme";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);
export default new Vuex.Store({
  modules: { snack, session, theme },
  plugins: [createPersistedState()]
});
