<template>
  <div>
    <t-card title="Subscribe" :subtitle="symbol">
      {{ state }}
      <v-container fluid>
        <v-select
          v-model="subscription"
          label="Subscription"
          flat
          solo-inverted
          :items="subscriptions"
          return-object
          item-text="name"
          :hint="subscription.description"
          persistent-hint
        ></v-select>

        <div v-for="(arg, index) in subscriptionArguments" :key="index">
          <v-select
            v-model="subscriptionParams[arg.name]"
            v-if="isEnum(arg)"
            :items="enumValues(arg)"
            :label="arg.name"
            solo
          ></v-select>
          <v-text-field
            v-model="subscriptionParams[arg.name]"
            v-else
            :label="arg.name"
            solo
          ></v-text-field>
        </div>

        <v-btn
          :disabled="!subscription.name"
          @click="subscribe"
          x-large
          width="100%"
          color="primary"
          >Subscribe</v-btn
        >
      </v-container>
    </t-card>
  </div>
</template>
<script>
import { subscriptionStore } from "@/store/experimental/subscription-store.ts";
import { defineComponent, ref, computed } from "@vue/composition-api";
import { useResult } from "@vue/apollo-composable";
import * as gql from "@/graphql";
import { mapGetters, mapMutations } from "vuex";
export default defineComponent({
  setup() {
    const { updateSubscription, state } = subscriptionStore;
    const enablers = ref({});
    const operations = ref({});
    const subscription = ref({});
    const subscriptionParams = ref({});
    const subscriptionArguments = computed(() => subscription.value.args);

    Object.keys(gql)
      .filter(f => f.includes("Subscription") && !f.includes("Intro"))
      .forEach(operation => {
        enablers.value = { ...enablers.value, [operation]: false };
        const { result, onResult } = gql[operation](
          () => ({ ...subscriptionParams.value }),
          () => ({ enabled: enablers.value[operation] })
        );
        onResult(data => updateSubscription(operation, data));
        operations.value = {
          ...operations.value,
          [`${operation}RESULT`]: result,
          [`${operation}ONRESULT`]: onResult
        };
      });

    const { result } = gql.useIntrospectSubscriptionsQuery();
    const subscriptions = useResult(result, [], data =>
      data.__schema.subscriptionType.fields.filter(
        f => f.name.includes("Strategy") || f.name.includes("Indicator")
      )
    );
    return {
      state,
      enablers,
      subscription,
      subscriptions,
      subscriptionParams,
      subscriptionArguments,
      ...operations.value
    };
  },
  props: {
    symbol: String
  },
  computed: {
    ...mapGetters("subscriptions", ["subs"])
  },
  methods: {
    ...mapMutations("subscriptions", ["newSubscription"]),
    subscribe() {
      this.enablers[`use${this.subscription.name}Subscription`] = true;
    },
    isEnum(arg) {
      return arg.type.ofType?.kind === "ENUM";
    },
    enumValues(arg) {
      return arg.type.ofType?.enumValues.map(e => e.name);
    }
  }
});
</script>
