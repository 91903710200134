import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueCompositionAPI from "@vue/composition-api";
import { provide } from "@vue/composition-api";
import {
  ApolloClient,
  InMemoryCache,
  from,
  HttpLink,
  split
} from "@apollo/client";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { ApolloClients } from "@vue/apollo-composable";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import jwtDecode from "jwt-decode";

const httpUrl =
  process.env.NODE_ENV === "production"
    ? "https://tsunami-b.herokuapp.com/graphql"
    : "http://localhost:4000/graphql";

const wsUrl =
  process.env.NODE_ENV === "production"
    ? "wss://tsunami-b.herokuapp.com/graphql"
    : "ws://localhost:4000/graphql";

// CSS
import "./assets/scss/theme.scss";

// Components
import Card from "@/components/general/general.card.vue";

// Apollo
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }) =>
      store.dispatch("snack/trigger", `${message}, Path: ${path}`)
    );
  }

  if (networkError) {
    store.dispatch("snack/trigger", `[Network error]: ${networkError}`);
  }
});

const authLink = setContext((_, { headers }) => {
  const token = store.getters["session/accessToken"];
  return {
    headers: {
      ...headers,
      authorization: token ? `bearer ${token}` : ""
    }
  };
});

const refreshLink = new TokenRefreshLink({
  accessTokenField: "accessToken",
  isTokenValidOrUndefined: () => {
    const token = store.getters["session/accessToken"];
    if (!token) return true;

    try {
      const { exp } = jwtDecode(token);
      return !(Date.now() >= exp * 1000);
    } catch (error) {
      return false;
    }
  },
  fetchAccessToken: () => {
    return fetch("http://localhost:4000/refresh_token", {
      method: "POST",
      credentials: "include"
    });
  },
  handleFetch: accessToken => {
    store.commit("session/setToken", accessToken);
  },
  handleError: err => {
    store.dispatch(
      "snack/trigger",
      "Your refresh token is invalid. Try re-logging."
    );
    store.dispatch("session/logout");
  }
});

const client = new SubscriptionClient(`${wsUrl}`, {
  reconnect: true,
  reconnectionAttempts: 10
});

const httpLink = from([
  errorLink,
  authLink,
  refreshLink,
  new HttpLink({
    uri: `${httpUrl}`,
    credentials: "include"
  })
]);

const wsLink = from([errorLink, new WebSocketLink(client)]);

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const main = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

Vue.use(VueCompositionAPI);
Vue.config.productionTip = false;
Vue.component("t-card", Card);
new Vue({
  setup() {
    provide(ApolloClients, {
      default: main
    });
  },
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
