<template>
  <div>
    <v-snackbar bottom left app rounded v-model="trigger">
      <div>
        <v-row>
          <v-col>{{ message }}</v-col>
        </v-row>
        <!-- <v-btn text color="white" @click.native="trigger = false">Close</v-btn> -->
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    trigger: false
  }),
  computed: {
    ...mapState({
      message: state => state.snack.message
    })
  },
  watch: {
    message() {
      this.trigger = false;
      this.trigger = true;
    }
  }
};
</script>

<style></style>
