<template>
  <v-app>
    <v-main>
      <Navbar :market-status="marketStatus" :authenticated="authenticated" />
      <v-container fluid class="#161e26">
        <Snackbar />
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/general/general.navbar";
import Snackbar from "@/components/general/general.snackbar";
import { defineComponent } from "@vue/composition-api";
import { useMarketQuery } from "@/graphql";
import { useResult } from "@vue/apollo-composable";
import { mapGetters } from "vuex";
export default defineComponent({
  name: "App",
  components: { Navbar, Snackbar },
  setup() {
    const { result } = useMarketQuery({ pollInterval: 60000 });
    const marketStatus = useResult(result, {}, data => data.Market);
    return { marketStatus };
  },
  computed: {
    ...mapGetters("session", ["authenticated"])
  }
});
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500;600;700&display=swap");
#app {
  font-family: "Quicksand", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
</style>
