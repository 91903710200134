<template>
  <div>
    <v-card :flat="flat" :shaped="shaped" :outlined="outlined" :height="height">
      <v-list-item>
        <v-list-item-content>
          <v-toolbar-title class="h6" color="primary">{{
            title
          }}</v-toolbar-title>
          <v-list-item-subtitle>{{ subtitle }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-spacer></v-spacer>
        <v-list-item-avatar v-if="icon">
          <v-btn @click="clickIcon" color="accent" icon :loading="loading">
            <v-icon :color="iconColor">{{ icon }}</v-icon>
          </v-btn>
        </v-list-item-avatar>
      </v-list-item>
      <v-divider></v-divider>

      <slot></slot>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    flat: {
      type: Boolean,
      default: false
    },
    title: String,
    subtitle: String,
    icon: String,
    iconColor: String,
    loading: Boolean,
    outlined: {
      type: Boolean,
      default: false
    },
    shaped: {
      type: Boolean,
      default: false
    },
    height: String
  },
  data: () => ({}),
  methods: {
    clickIcon() {
      this.$emit("clickIcon");
    }
  }
};
</script>
