<template>
  <div>
    <t-card :title="symbol.name" :subtitle="symbol.ticker">
      <v-tabs v-model="tab" grow hide-slider>
        <v-tab class="subtitle-2" href="#detail">Detail</v-tab>
        <v-tab class="subtitle-2" href="#news">News</v-tab>
      </v-tabs>
      <v-container fluid>
        <v-tabs-items v-model="tab">
          <v-tab-item value="detail">
            <pre class="caption">{{ detail }}</pre>
          </v-tab-item>
          <v-tab-item value="news">
            <pre class="caption">{{ news }}</pre></v-tab-item
          >
        </v-tabs-items>
      </v-container>
    </t-card>
  </div>
</template>
<script>
import { defineComponent, ref, watch } from "@vue/composition-api";
import { useTickerDetailQuery, useTickerNewsQuery } from "@/graphql";
import { useResult } from "@vue/apollo-composable";
export default defineComponent({
  props: {
    symbol: Object
  },
  setup(props) {
    const tab = ref("detail");
    const { result: d_result, refetch: d_refetch } = useTickerDetailQuery({
      stocksTicker: props.symbol.ticker
    });
    const { result: n_result, refetch: n_refetch } = useTickerNewsQuery({
      stocksTicker: props.symbol.ticker
    });
    const detail = useResult(d_result, [], data => data.TickerDetail);
    const news = useResult(n_result, [], data => data.TickerNews);
    watch(props, () => {
      d_refetch({
        stocksTicker: props.symbol.ticker
      });
      n_refetch({
        stocksTicker: props.symbol.ticker
      });
    });
    return { detail, news, tab };
  }
});
</script>
