<template>
  <div>
    <v-app-bar app dense color="transparent">
      <v-avatar>
        <v-icon large color="accent">mdi-all-inclusive</v-icon>
      </v-avatar>
      <v-toolbar-title>tsu</v-toolbar-title>
      <v-divider vertical inset class="mx-1"></v-divider>
      <v-toolbar-title class="overline">financial</v-toolbar-title>
      <div class="mx-9">
        <v-btn
          :to="route.to"
          text
          plain
          :disabled="route.disabled"
          class="mx-1 subtitle-2"
          v-for="(route, index) in routes"
          :key="index"
          >{{ route.text }}</v-btn
        >
      </div>

      <v-spacer></v-spacer>
      <v-responsive max-width="260">
        <v-text-field
          dense
          flat
          hide-details
          rounded
          solo-inverted
        ></v-text-field>
      </v-responsive>
      <v-btn class="mx-1" @click="changeTheme" icon text :color="color">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
      <v-btn @click="logout()" v-show="authenticated" icon text color="third">
        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>
    </v-app-bar>
    <v-system-bar app color="secondary">
      <v-row align="center" justify="center" v-if="marketStatus.market">
        <span class="white--text">market: {{ marketStatus.market }}</span>
        <v-divider class="mx-6" vertical color="white"></v-divider>
        <span class="white--text">nyse: {{ marketStatus.exchanges.nyse }}</span>
        <v-divider class="mx-6" vertical color="white"></v-divider>
        <span class="white--text"
          >nasdaq: {{ marketStatus.exchanges.nasdaq }}</span
        >
        <v-divider class="mx-6" vertical color="white"></v-divider>
        <span class="white--text">otc: {{ marketStatus.exchanges.otc }}</span>
        <v-divider class="mx-6" vertical color="white"></v-divider>
        <span class="white--text">forex: {{ marketStatus.currencies.fx }}</span>
        <v-divider class="mx-6" vertical color="white"></v-divider>
        <span class="white--text"
          >crypto: {{ marketStatus.currencies.crypto }}</span
        >
      </v-row>
    </v-system-bar>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  props: {
    marketStatus: Object,
    authenticated: {
      type: Boolean,
      default: false
    }
  },

  created() {
    this.$vuetify.theme.dark = this.darkMode;
  },
  data: () => ({
    items: [
      { title: "Home", icon: "mdi-view-dashboard" },
      { title: "About", icon: "mdi-forum" }
    ],
    routes: [
      { text: "Symbols", to: "/", disabled: false },
      { text: "Subscriptions", to: "/subscriptions", disabled: true },
      { text: "Graph", to: "/graph", disabled: true },
      { text: "Backtest", to: "/backtest", disabled: true }
    ]
  }),
  computed: {
    ...mapState({
      darkMode: state => state.theme.darkMode
    }),
    markets() {
      return {
        market: this.marketStatus.market,
        nyse: this.marketStatus.exchanges.nyse,
        nasdaq: this.marketStatus.exchanges.nasdaq,
        otc: this.marketStatus.exchanges.otc,
        forex: this.marketStatus.currencies.fx,
        crypto: this.marketStatus.currencies.crypto
      };
    },
    icon() {
      return this.$vuetify.theme.dark
        ? "mdi-moon-waning-crescent"
        : "mdi-weather-sunny";
    },
    color() {
      return this.$vuetify.theme.dark ? "white" : "yellow darken-2";
    }
  },
  methods: {
    ...mapActions("session", ["logout"]),
    ...mapMutations("theme", ["setDarkMode"]),
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.setDarkMode();
    }
  }
});
</script>
