const state = {
  darkMode: true
};
const mutations = {
  setDarkMode(state: any) {
    state.darkMode = !state.darkMode;
  }
};

export default { namespaced: true, state, mutations };
