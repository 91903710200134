const state = { message: null };
const mutations = {
  setMessage(state: any, message: string) {
    state.message = "";
    state.message = message;
  }
};
const actions = {
  async trigger({ commit }: any, message: string) {
    console.log("action");
    await commit("setMessage", "");
    commit("setMessage", message);
  }
};
export default { namespaced: true, state, mutations, actions };
