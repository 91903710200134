<template>
  <div>
    <t-card title="Snapshot">
      <v-tabs v-model="tab" grow hide-slider>
        <v-tab href="#stocks" class="subtitle-2">Stocks</v-tab>
        <v-tab href="#forex" class="subtitle-2">Forex</v-tab>
        <v-tab href="#crypto" class="subtitle-2">Crypto</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="stocks">
          <snapshot-stocks />
        </v-tab-item>
        <v-tab-item value="forex">
          <snapshot-forex />
        </v-tab-item>
        <v-tab-item value="crypto">
          <snapshot-crypto />
        </v-tab-item>
      </v-tabs-items>
    </t-card>
  </div>
</template>
<script>
import { defineComponent, ref } from "@vue/composition-api";
import SnapshotCrypto from "./snapshot-crypto.vue";
import SnapshotForex from "./snapshot-forex.vue";
import SnapshotStocks from "./snapshot-stocks.vue";
export default defineComponent({
  components: { SnapshotForex, SnapshotCrypto, SnapshotStocks },
  setup() {
    const tab = ref("stock");
    return { tab };
  }
});
</script>
