import Vue from "vue";
import VueRouter from "vue-router";
// import store from "@/store";
import Home from "../views/view.home.vue";
import Auth from "@/views/view.auth.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "Home", component: Home },
  { path: "/auth/:token?", name: "Auth", component: Auth }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// router.beforeEach((to: any, from, next) => {
//   const isAuthenticated = store.getters["session/authenticated"];
//   if (to.name !== "Auth" && !isAuthenticated) {
//     next({ name: "Auth" });
//   } else if (isAuthenticated && to.name === "Auth") {
//     next({ name: "Home" });
//   } else {
//     next();
//   }
// });

export default router;
