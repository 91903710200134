import router from "@/router/index";
const state = { accessToken: null };
const getters = {
  authenticated(state: any) {
    return !!state.accessToken;
  },
  accessToken(state: any) {
    return state.accessToken;
  }
};
const mutations = {
  setToken(state: any, token: string) {
    state.accessToken = token;
  }
};
const actions = {
  logout({ commit }: any) {
    commit("setToken", null);
    router.push({
      name: "Auth"
    });
  }
};
export default { namespaced: true, state, getters, mutations, actions };
