<template>
  <div>
    <symbol-snapshot class="mb-2"></symbol-snapshot>
    <symbol-search />
  </div>
</template>
<script>
import SymbolSearch from "@/components/symbol-search.vue";
import SymbolSnapshot from "@/components/symbol-snapshot.vue";

export default {
  components: {
    SymbolSearch,
    SymbolSnapshot
  },
  data: () => ({})
};
</script>
