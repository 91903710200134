import { reactive, readonly, set } from "@vue/composition-api";

const state: any = reactive({
  subscriptions: {}
});

const updateSubscription = (name: string, value: any) => {
  set(state.subscriptions, name, value);
};

export const subscriptionStore = readonly({
  state,
  updateSubscription
});
